import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import { ChevronLeftIcon } from 'merchant-portal-components';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import OrderDetails from './components/OrderDetails';
import { formatTransactionDetails } from './utils/formatTransactionDetails';
import styled from 'styled-components';

const defaultTypesOfRewardOptions = [
  {
    label: 'Fixed Dollar Credits',
    value: 'global-credits',
  },
];
const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 10px 10px 20px 10px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;

const isRapidDeploymentClient = (appIdentifier) =>
  [
    'dennys',
    'tropical_treats',
    'butchies',
    'opus_restaurant',
    'moxies',
    'shark',
    'rock_creek',
    'chop',
  ].includes(appIdentifier);

class OrderDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: '',
      isOrderCancelBoxChecked: false,
      formattedOrderHistoryDetails: null,
    };
  }

  componentDidMount() {
    track.page('customer-service-order-history-detail');
    const orderId = this.props.match.params.orderId;
    this.setState({ orderId: orderId });
    this.props.getOrderDetailsByOrderId(orderId);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.orderDetails.orderDetails !=
      prevProps.orderDetails.orderDetails
    ) {
      if (this.props.orderDetails.orderDetails)
        this.setState({
          formattedOrderHistoryDetails: formatTransactionDetails(
            this.props.orderDetails.orderDetails
          ),
        });
    }
  }

  handleVisibilityCancelOrderModal = () => {
    const { isCancelOrderModalVisible } = this.props.orderDetails;
    if (isCancelOrderModalVisible) {
      this.props.cancelOrderModalVisibility(false);
    } else {
      this.props.cancelOrderModalVisibility(true);
    }
  };

  handleIsOrderCancelBoxChecked = () => {
    if (this.state.isOrderCancelBoxChecked) {
      this.setState({ isOrderCancelBoxChecked: false });
    } else {
      this.setState({ isOrderCancelBoxChecked: true });
    }
  };

  handleOrderCancel = () => {
    const { isOrderCancelBoxChecked, orderId } = this.state;

    if (isOrderCancelBoxChecked) {
      this.props.cancelOrderByOrderId(orderId);
    } else {
      // I, understand box is not checked
    }
  };

  handleVisibilitySendLoyaltyModal = () => {
    const { isSendLoyaltyModalVisible } = this.props.orderDetails;
    if (isSendLoyaltyModalVisible) {
      this.props.sendLoyaltyModalVisibility(false);
    } else {
      this.props.sendLoyaltyModalVisibility(true);
    }
  };

  handleSendLoyaltySubmit = (values) => {
    this.validateSendLoyaltyValues(values)
      .then((validatedValues) => {
        this.props.sendLoyaltyPointsByCustomerId(
          this.state.formattedOrderHistoryDetails.customerId,
          validatedValues.type,
          validatedValues.body
        );
      })
      .catch((message) => {
        this.props.errorAlert(message);
      });
  };

  validateSendLoyaltyValues = (values) => {
    return new Promise((resolve, reject) => {
      if (!values) {
        reject('Please fill out the form before submitting');
      }
      if (!values.typeOfReward) {
        reject('Please select the Type of Reward');
      }
      if (!values.amountOfReward) {
        reject('Please input the Amount of Reward');
      }
      if (!values.rewardNotes) {
        reject('Please type in the Reward Notes');
      }
      if (
        values.typeOfReward == 'global-credits' &&
        (values.amountOfReward < 1 || values.amountOfReward > 200)
      ) {
        reject('amount must be between 1 and 200');
      }
      let type = values.typeOfReward;
      let body = {
        amount: parseFloat(values.amountOfReward),
        comment: values.rewardNotes,
      };
      resolve({ type, body });
    });
  };

  getTypesOfRewardOptions = () => {
    const { appMetaData, appIdentifier } = this.props.accountReducer;
    let typesOfRewardOptions = defaultTypesOfRewardOptions.slice(0);
    if (
      appMetaData.configuration.program_type &&
      appMetaData.configuration.program_type == 'points' &&
      !isRapidDeploymentClient(appIdentifier)
    ) {
      typesOfRewardOptions.push({
        label: 'Fixed Loyalty Points',
        value: 'points',
      });
    }
    return typesOfRewardOptions;
  };

  componentWillUnmount() {
    this.props.resetOrderDetailsReducerState();
  }

  render() {
    const pageName = 'Order Details';
    const { history } = this.props;

    const {
      requestingCancelOrderDetails,
      isCancelOrderModalVisible,
      isSendLoyaltyModalVisible,
      requestingOrderDetails,
      requestingSendLoyaltyPointsByCustomerId,
    } = this.props.orderDetails;
    const {
      isOrderCancelBoxChecked,
      formattedOrderHistoryDetails,
      sendLoyaltyNote,
    } = this.state;

    return (
      <div>
        <BreadCrumbWrapper>
          <BreadCrumb style={{ margin: ' 0px' }} pageName={pageName} />
        </BreadCrumbWrapper>
        <OrderDetails
          requestingOrderDetails={requestingOrderDetails}
          orderDetails={formattedOrderHistoryDetails}
          history={history}
          isCancelOrderModalVisible={isCancelOrderModalVisible}
          handleOrderCancel={this.handleOrderCancel}
          handleVisibilityCancelOrderModal={
            this.handleVisibilityCancelOrderModal
          }
          handleIsOrderCancelBoxChecked={this.handleIsOrderCancelBoxChecked}
          requestingCancelOrderDetails={requestingCancelOrderDetails}
          isOrderCancelBoxChecked={isOrderCancelBoxChecked}
          isSendLoyaltyModalVisible={isSendLoyaltyModalVisible}
          handleVisibilitySendLoyaltyModal={
            this.handleVisibilitySendLoyaltyModal
          }
          getTypesOfRewardOptions={this.getTypesOfRewardOptions}
          handleSendLoyaltySubmit={this.handleSendLoyaltySubmit}
          sendLoyaltyNote={sendLoyaltyNote}
          orderId={this.props.match.params.orderId}
          requestingSendLoyaltyPointsByCustomerId={
            requestingSendLoyaltyPointsByCustomerId
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    accountReducer: state.accountReducer,
    orderDetails: state.orderDetails,
  };
}

export default connect(mapStateToProps, actions)(OrderDetailsContainer);
