import {
  GETTING_COMBOS,
  GET_COMBOS,
  LOAD_EDIT_COMBO_PAGE,
  LOAD_COMBOS_LIST_PAGE,
  PATCH_ACTIVE_COMBO,
  GET_COMBO_BUSINESSES,
  GET_COMBO_ITEMS_PRODUCTS,
  GET_COMBO_AVAILABILITIES,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_VARIANTS,
  HANDLE_BASIC_INFORMATION_SAVE_REQUEST,
  GET_COMBOS_OPTIONS_OVERRIDES,
  PATCH_COMBO_BASIC_INFORMATION,
  INITIATE_CREATE_COMBO,
  HANDLE_COMBO_ITEMS_REQUEST,
  PATCH_COMBO_ITEMS,
  HANDLE_AVAILABILITIES_SAVE_REQUEST,
  PATCH_COMBO_AVAILABILITIES,
  GET_ALL_PRODUCTS,
  GET_ALL_COMBOS_SHARED_OPTIONS,
  RESET_COMBOS_REDUCER,
  PATCH_COMBO_BASIC_INFORMATION_ERROR,
  GET_COMBOS_OPTIONS_OVERRIDES_REQUESTING,
  PATCH_COMBO_ITEMS_SUCCESS,
  RESET_COMBO_ITEM_SUBMIT,
  SAVING_COMBO_POSITIONS,
  SAVING_COMBO_ITEMS_POSITIONS,
  UPDATE_SORTED_COMBOS_LIST,
  GET_COMBO_OVERRIDES,
  SUBMIT_COMBO_OVERRIDES_REQUEST,
  SUBMIT_COMBO_OVERRIDES,
} from './combosTypes';

const initialState = {
  currentPage: 'list',
  meta: { page_number: 1, page_size: 25 },
  isRequestingGetCombos: false,
  combosList: [],
  allInitialCombos: [],
  isRequestingGetCombosBusinesses: true,
  comboBusinesses: [],
  comboItems: [],
  isRequestingGetComboItems: true,
  isRequestingGetCombosAvailabilities: true,
  comboAvailabilities: [],
  isRequestingGetProductscategories: true,
  productsCategories: [],
  isRequestingGetProductsVariants: true,
  productsVariants: [],
  isRequestingGetCombosOptionsOverrides: false,
  combosOptionsOverrides: [],
  submitBasicInformationIsRequesting: false,
  submitAvailabilitiesIsRequesting: false,
  submitComboItemsIsRequesting: false,
  isRequestingGetAllProducts: true,
  editingCombo: null,
  editingOverrides: [],
  isRequestingGetSharedOptionsCombos: true,
  sharedOptionsCombos: [],
  isComboItemsSubmitSuccess: false,
  requestParams: {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: false,
  },
  isSavingComboPositions: false,
  isSavingComboItemsPositions: false,
  comboOverrides: null,
  isSubmitting: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETTING_COMBOS:
      return {
        ...state,
        isRequestingGetCombos: true,
      };
    case GET_COMBOS:
      return {
        ...state,
        isRequestingGetCombos: false,
        combosList: action.combos,
        allInitialCombos: action.combos,
        meta: action.meta,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.hasNextPage,
        },
      };
    case GET_COMBO_OVERRIDES:
      return {
        ...state,
        comboOverrides: action.payload,
      };
    case LOAD_EDIT_COMBO_PAGE:
      return {
        ...state,
        currentPage: 'edit',
        editingCombo: action.combo,
        editingOverrides: action.overrides,
      };
    case LOAD_COMBOS_LIST_PAGE:
      return {
        ...state,
        currentPage: 'list',
        editingCombo: null,
      };
    case PATCH_ACTIVE_COMBO:
      return {
        ...state,
        combosList: state.combosList.map((combo) => {
          if (combo.id === action.payload.id) {
            return { ...combo, is_active: action.payload.isActive };
          }

          return combo;
        }),
      };
    case GET_COMBO_BUSINESSES:
      return {
        ...state,
        comboBusinesses: action.payload,
        isRequestingGetCombosBusinesses: false,
      };
    case 'GET_COMBO_BUSINESSES_OVERRIDES':
      return {
        ...state,
      };
    case GET_COMBO_ITEMS_PRODUCTS:
      return {
        ...state,
        comboItems: action.payload,
        isRequestingGetComboItems: false,
      };
    case GET_COMBO_AVAILABILITIES:
      return {
        ...state,
        comboAvailabilities: action.payload,
        isRequestingGetCombosAvailabilities: false,
      };
    case GET_PRODUCTS_CATEGORIES:
      return {
        ...state,
        isRequestingGetProductscategories: false,
        productsCategories: action.payload,
      };
    case GET_PRODUCTS_VARIANTS:
      return {
        ...state,
        isRequestingGetProductsVariants: false,
        productsVariants: action.payload,
      };
    case INITIATE_CREATE_COMBO:
      return {
        ...state,
        currentPage: 'create',
      };
    case GET_COMBOS_OPTIONS_OVERRIDES:
      return {
        ...state,
        isRequestingGetCombosOptionsOverrides: false,
        combosOptionsOverrides: action.list,
        // comboItems: state.comboItems.map(comboItem => {
        //   if (comboItem.id === action.comboItemId) {
        //     return { ...comboItem, combosOptionsOverride: action.list };
        //   }
        //   return comboItem;
        // })
      };
    case GET_COMBOS_OPTIONS_OVERRIDES_REQUESTING:
      return {
        ...state,
        isRequestingGetCombosOptionsOverrides: true,
      };
    case HANDLE_BASIC_INFORMATION_SAVE_REQUEST:
      return {
        ...state,
        submitBasicInformationIsRequesting: true,
      };
    case PATCH_COMBO_BASIC_INFORMATION:
      return {
        ...state,
        submitBasicInformationIsRequesting: false,
        editingCombo: action.payload,
        currentPage: 'edit',
      };
    case PATCH_COMBO_BASIC_INFORMATION_ERROR:
      return {
        ...state,
        submitBasicInformationIsRequesting: false,
      };
    case HANDLE_AVAILABILITIES_SAVE_REQUEST:
      return {
        ...state,
        submitAvailabilitiesIsRequesting: true,
      };
    case PATCH_COMBO_AVAILABILITIES:
      return {
        ...state,
        submitAvailabilitiesIsRequesting: false,
      };
    case HANDLE_COMBO_ITEMS_REQUEST:
      return {
        ...state,
        submitComboItemsIsRequesting: true,
      };
    case PATCH_COMBO_ITEMS:
      return {
        ...state,
        submitComboItemsIsRequesting: false,
        //comboItems: action.payload
      };
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        isRequestingGetAllProducts: false,
        allProducts: action.payload,
      };
    case GET_ALL_COMBOS_SHARED_OPTIONS:
      return {
        ...state,
        isRequestingGetSharedOptionsCombos: false,
        sharedOptionsCombos: action.list,
      };
    case RESET_COMBOS_REDUCER:
      return {
        ...initialState,
        combosList: state.combosList,
      };
    case PATCH_COMBO_ITEMS_SUCCESS:
      return {
        ...state,
        isComboItemsSubmitSuccess: true,
      };
    case RESET_COMBO_ITEM_SUBMIT:
      return {
        ...state,
        isComboItemsSubmitSuccess: false,
      };
    case 'UPDATE_COMBOS_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case UPDATE_SORTED_COMBOS_LIST:
      return {
        ...state,
        combosList: action.combosList,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder,
        },
      };
    case SAVING_COMBO_POSITIONS:
      return {
        ...state,
        isSavingComboPositions: action.payload,
      };
    case SAVING_COMBO_ITEMS_POSITIONS:
      return {
        ...state,
        isSavingComboItemsPositions: action.isSavingComboItemsPositions,
      };
    case SUBMIT_COMBO_OVERRIDES_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case SUBMIT_COMBO_OVERRIDES:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
}
