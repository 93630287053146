import {
  GET_CATEGORIES,
  PATCH_ACTIVE_CATEGORY,
  LOAD_EDIT_CATEGORY_PAGE,
  LOAD_CREATE_CATEGORY_PAGE,
  LOAD_LIST_CATEGORY_PAGE,
  REDIRECT_LIST_PAGE_ON_SAVE,
  HANDLE_CATEGORY_SAVE_REQUEST,
  HANDLE_CATEGORY_SAVE_REQUEST_FAILURE,
  RESET_CATEGORY_REDUX_STATE,
  SORT_CATEGORY_LIST,
  SORT_PRODUCT_LIST,
  REQUEST_SORT_CATEGORY_LIST,
  SUBCATEGORY_HOURS_VALIDATION_ERROR,
  SET_PRODUCT_CATEGORY_ASSIGNMENT_MODAL_IS_VISIBLE,
  UPDATE_SORTED_CATEGORIES_LIST,
} from './categoryTypes';

const initialState = {
  currentPage: 'list',
  isRequestingGetCategories: true,
  allCategories: [],
  meta: { page_number: 1, page_size: 25 },
  editingCategory: null,
  productsAssociatedToCategory: [],
  createPatchCategoryIsRequesting: false,
  isSortingRequested: false,
  subCategoryHoursError: [],
  productCategoryAssignmentModalIsVisible: false,
  requestParams: {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_CURRENT_CATEGORY_PAGE':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        isRequestingGetCategories: false,
        allCategories: action.payload.map((category) => ({
          ...category,
          subLength: category.sub_categories.length,
        })),
        allInitialCategories: action.payload,
        meta: action.meta,
        requestParams: {
          ...state.requestParams,
          has_next_page: action.hasNextPage,
        },
      };
    case PATCH_ACTIVE_CATEGORY:
      return {
        ...state,
        allCategories: state.allCategories.map((category) => {
          if (category.id === action.payload.id) {
            return { ...category, is_active: action.payload.isActive };
          }
          return category;
        }),
      };
    case LOAD_EDIT_CATEGORY_PAGE:
      return {
        ...state,
        currentPage: 'edit',
        editingCategory: action.payload,
        productsAssociatedToCategory: action.productsAssociatedToCategory,
      };
    case SORT_PRODUCT_LIST:
      return {
        ...state,
        editingCategory: action.editingCategory,
        productsAssociatedToCategory: action.productsAssociatedToCategory,
      };
    case LOAD_CREATE_CATEGORY_PAGE:
      return {
        ...state,
        currentPage: 'create',
      };
    case LOAD_LIST_CATEGORY_PAGE:
      return {
        ...state,
        currentPage: 'list',
        editingCategory: null,
      };
    case REDIRECT_LIST_PAGE_ON_SAVE:
      return {
        ...state,
        createPatchCategoryIsRequesting: false,
        productCategoryAssignmentModalIsVisible: false,
        currentPage: 'list',
      };
    case HANDLE_CATEGORY_SAVE_REQUEST:
      return {
        ...state,
        createPatchCategoryIsRequesting: true,
      };
    case HANDLE_CATEGORY_SAVE_REQUEST_FAILURE:
      return {
        ...state,
        createPatchCategoryIsRequesting: false,
      };
    case RESET_CATEGORY_REDUX_STATE:
      return initialState;
    case REQUEST_SORT_CATEGORY_LIST:
      return {
        ...state,
        isSortingRequested: true,
      };
    case SORT_CATEGORY_LIST:
      return {
        ...state,
        allCategories: action.payload,
        allInitialCategories: action.payload,
        isSortingRequested: false,
      };
    case SUBCATEGORY_HOURS_VALIDATION_ERROR:
      return {
        ...state,
        subCategoryHoursError: action.payload,
      };
    case SET_PRODUCT_CATEGORY_ASSIGNMENT_MODAL_IS_VISIBLE:
      return {
        ...state,
        productCategoryAssignmentModalIsVisible:
          action.productCategoryAssignmentModalIsVisible,
      };
    case 'UPDATE_CATEGORIES_REQUEST_PARAMS':
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case UPDATE_SORTED_CATEGORIES_LIST:
      return {
        ...state,
        allCategories: action.allCategories,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder,
        },
      };
    default:
      return state;
  }
}
