import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import { Tooltip, Switch } from '@material-ui/core';

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  border-radius: 12px;
`;
const MessageWrapper = styled.div`
  width: 100% auto;
  padding: 50px;
  text-align: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
`;
const renderTableHeader = (requestParams) => {
  return (
    <TrHead>
      <Th
        align="left"
        value="name"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
        style={{ paddingLeft: '37px' }}
      >
        Name
      </Th>
      <Th align="left">
        Label
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="This is an internal label to help you identify the type of variant.This will not appear to your customers"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th
        align="right"
        value="is_active"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
        style={{ paddingRight: '45px' }}
      >
        Active
      </Th>
    </TrHead>
  );
};
const renderTableRows = (
  allVariants,
  toggleIsActive,
  variantIsSaving,
  setEditVariant
) => {
  return allVariants.map((variant, index) => (
    <Tr
      key={index}
      style={{ height: '78px', color: '#191818', cursor: 'pointer' }}
      onClick={() => !variantIsSaving && setEditVariant(variant)}
    >
      <Td padding={'0px 0px 0px 37px'}>{variant.name}</Td>
      <Td>{variant.label}</Td>
      <Td padding={'0px 37px 0px 0px'} style={{ textAlign: 'right' }}>
        <Switch
          checked={variant.is_active}
          onClick={(e) => e.stopPropagation()}
          onChange={() => toggleIsActive(variant)}
          disabled={variantIsSaving}
          value="checkedB"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </Td>
    </Tr>
  ));
};
const renderList = (
  allVariants,
  toggleIsActive,
  variantIsSaving,
  setEditVariant,
  requestParams,
  searchString
) => {
  return (
    <TableWrapper>
      <Table style={{ border: 'none' }}>
        <TBody>
          {renderTableHeader(requestParams)}
          {allVariants.length &&
            requestParams &&
            renderTableRows(
              allVariants,
              toggleIsActive,
              variantIsSaving,
              setEditVariant,
              requestParams,
              searchString
            )}
        </TBody>
      </Table>
    </TableWrapper>
  );
};

const VariantList = ({
  allVariants,
  requestParams,
  isGetVariantRequest,
  toggleIsActive,
  setEditVariant,
  variantIsSaving,
  business_id,
  searchString,
  getVariants,
}) => {
  return !isGetVariantRequest ? (
    <Fragment>
      {allVariants.length > 0 ? (
        <Fragment>
          {renderList(
            allVariants,
            toggleIsActive,
            variantIsSaving,
            setEditVariant,
            requestParams,
            searchString,
            business_id
          )}
          <PaginationWrapper>
            <PaginationControl
              response={{
                ...allVariants,
                page: requestParams.page_number,
                page_size: requestParams.page_size,
                count: allVariants,
              }}
              requestParams={requestParams}
              updateAction={getVariants}
              type="variants"
            />
          </PaginationWrapper>
        </Fragment>
      ) : (
        <MessageWrapper>No variants</MessageWrapper>
      )}
    </Fragment>
  ) : (
    <LoadingBlueLarge />
  );
};

export default VariantList;
