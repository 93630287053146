const renderQueryString = (params) => {
  let queryString = '?';

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        queryString = `${queryString}${key}[]=${item}&`;
      });
    } else if (key !== 'export') {
      queryString = `${queryString}${key}=${value}&`;
    }
  });

  return queryString.slice(0, -1);
};

export default renderQueryString;
