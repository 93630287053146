import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import {
  RenderTextField,
  required,
} from '../../../../components/FormElements/FormikElements';
import { Icon } from 'merchant-portal-components';

const LineDivider = styled.hr`
  margin-top: 10px;
  margin-bottom: 10px;
  border-color: rgba(0, 0, 0, 0.14);
`;

const FieldWrappedDiv = styled.div`
  display: ${(props) => (props.isEditable ? 'grid' : 'block')};
  grid-template-columns: ${(props) =>
    props.isEditable ? 'auto auto' : 'none'};
  align-items: center;
  margin-top: 20px;
`;

const SortableItem = SortableElement(
  ({ nutritionFact, variantIndex, index, arrayHelpers, isEditable }) => {
    return (
      <FieldWrappedDiv isEditable={isEditable}>
        {isEditable && (
          <Icon className="far fa-equals" style={{ cursor: 'move' }} />
        )}
        <Field
          name={`nutritionFacts[${variantIndex}].display_name`}
          component={RenderTextField}
          //setFieldValue={arrayHelpers.form.setFieldValue}
          value={nutritionFact.display_name}
          disabled={isEditable ? true : false}
          error={
            arrayHelpers.form.touched.nutritionFacts &&
            arrayHelpers.form.touched.nutritionFacts[variantIndex] &&
            arrayHelpers.form.touched.nutritionFacts[variantIndex]
              .display_name &&
            arrayHelpers.form.values.nutritionFacts[variantIndex]
              .display_name === undefined
              ? 'Required'
              : null
          }
          validate={required}
        />
      </FieldWrappedDiv>
    );
  }
);

const SortableList = SortableContainer(
  ({ nutritionFacts, arrayHelpers, isEditable }) => {
    return (
      <div>
        {nutritionFacts.map((nutritionFact, index) => (
          <SortableItem
            key={`nutritionFact-${index}`}
            index={index}
            variantIndex={index}
            nutritionFact={nutritionFact}
            arrayHelpers={arrayHelpers}
            isEditable={isEditable}
            disabled={!isEditable}
          />
        ))}
      </div>
    );
  }
);

const RenderNutritionFacts = ({
  arrayHelpers,
  nutritionFacts,
  sortNutritionFacts,
  isEditable,
}) => {
  return (
    <Fragment>
      <SortableList
        nutritionFacts={nutritionFacts}
        arrayHelpers={arrayHelpers}
        onSortEnd={({ oldIndex, newIndex }) => {
          const newList = arrayMove(nutritionFacts, oldIndex, newIndex);
          arrayHelpers.form.setFieldValue(`nutritionFacts`, newList);
          // sortNutritionFacts(newList);
        }}
        isEditable={isEditable}
      />
    </Fragment>
  );
};

export default RenderNutritionFacts;
