import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ComboAvailability from '../components/ComboAvailability';

import { ALL_ORDER_TYPE_ID } from '../../../../Products/productsTypes';
import {
  getComboAvailabilities,
  handleComboAvailabilitiesSubmit,
} from '../../../combosAction';
import { LoadingBlueLarge } from 'merchant-portal-components';

const ComboAvailabilityContainer = ({
  initValues,
  getComboAvailabilities,
  comboAvailabilities,
  isRequestingGetCombosAvailabilities,
  handleComboAvailabilitiesSubmit,
  submitAvailabilitiesIsRequesting,
  orderTypesList,
}) => {
  useEffect(() => {
    {
      initValues.id && getComboAvailabilities(initValues);
    }
  }, [initValues.id]);

  const show =
    !initValues.id || (initValues.id && !isRequestingGetCombosAvailabilities);
  return (
    <div>
      {show ? (
        <ComboAvailability
          initValues={initValues}
          getComboAvailabilities={getComboAvailabilities}
          comboAvailabilities={comboAvailabilities}
          handleComboAvailabilitiesSubmit={handleComboAvailabilitiesSubmit}
          submitAvailabilitiesIsRequesting={submitAvailabilitiesIsRequesting}
          disabled={!initValues.id}
          orderTypesList={orderTypesList.map((orderType) => ({
            value: orderType.id,
            label: orderType.type,
          }))}
        />
      ) : (
        <LoadingBlueLarge />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  comboAvailabilities: state.combosReducer.comboAvailabilities,
  isRequestingGetCombosAvailabilities:
    state.combosReducer.isRequestingGetCombosAvailabilities,
  submitAvailabilitiesIsRequesting:
    state.combosReducer.submitAvailabilitiesIsRequesting,
  orderTypesList: state.fullReducer.orderTypesList,
});

export default connect(mapStateToProps, {
  getComboAvailabilities,
  handleComboAvailabilitiesSubmit,
})(ComboAvailabilityContainer);
