import React from 'react';
import styled from 'styled-components';
import { StyledP } from 'merchant-portal-components';

const Wrapper = styled.div`
  width: 70%;
`;
const Container = styled.div`
  padding: ${(props) => props.padding || 'inherit'};
`;

const ViewPastOrdersNote = (props) => {
  return (
    <Wrapper>
      <Container padding="0 0 10px 0">
        <StyledP semiBold size="18px">
          View Past Orders
        </StyledP>
      </Container>
      <StyledP>
        Browse the list of Orders or use the Advanced Filter to find a specific
        Order. Export the list to CSV or click on View Details to see more
        information about a specific Order.
      </StyledP>
    </Wrapper>
  );
};

export default ViewPastOrdersNote;
