import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import alert from './modules/Alert/reducer';
import accountReducer from './modules/Account/accountReducer';
import settlements from './modules/Settlements/reducer';
import batchDetail from './modules/Settlements/BatchDetail/reducer';
import profile from './modules/Profile/reducer';
import segments from './modules/Segmentation/segmentationReducer';
import usersDashboard from './modules/Dashboard/Users/reducer';
import fullReducer from './modules/Full/fullReducer';
import transactionsDashboard from './modules/Dashboard/Transactions/reducer';
import topLocationsDashboard from './modules/Dashboard/TopLocations/reducer';
// import offers from './modules/HOCs/Offers/reducer';
import combos from './modules/HOCs/Combos/reducer';
import appFeed from './modules/Appfeed/appFeedReducer';
import productsReducer from './modules/MenuManagement/Products/productsReducer';
import optionReducer from './modules/MenuManagement/OptionManager/optionReducer';
import { UNAUTH_USER } from './modules/Account/types';
import mobileMessagesReducer from './modules/Engagement/MobileMessages/OneTimeMessages/mobileMessagesReducer';
import coupons from './modules/Engagement/CouponPromo/reducer';
import tagsReducer from './modules/Tags/tagsReducer';
import customerSearchManager from './modules/CustomerSearchManager/reducer';
import orderHistory from './modules/OrderManager/OrderHistoryList/reducer';
import orderDetails from './modules/OrderManager/OrderDetails/reducer';
import businessSearchManager from './modules/BusinessSearchManager/reducer';
import businessLocationsList from './modules/BusinessLocationDetails/LocationListManager/reducer';
import googleMapsWithOverlayZones from './modules/GoogleMapsWithOverlayZones/reducer';
import businessLocationDeliveryZones from './modules/BusinessLocationDetails/LocationDetailsManager/BusinessWizard/DeliveryZoneInformation/reducer';
import businessLocationDetails from './modules/BusinessLocationDetails/LocationDetailsManager/reducer';
import customerListReducer from './modules/CustomerManager/CustomerList/customerListReducer';
import customerReducer from './modules/CustomerManager/CustomerDetails/customerReducer';
import offersReducer from './modules/Engagement/Offers/offersReducer';
import appDetailReducer from './modules/AppDetail/AppDetailReducer';
import mediaReducer from './modules/Media/mediaReducer';
import feedbackReducer from './modules/FeedbackManager/FeedbackList/feedbackReducer';
import rolesReducer from './modules/RolesManager/rolesReducer';
// import upsellReducer from './modules/MenuManagement/Upsell/upsellReducer';
import priceReducer from './modules/MenuManagement/PriceManager/priceReducer';
import offers from './modules/HOCs/Offers/reducer';
import appFeedLayoutReducer from './modules/AppFeedLayout/appFeedLayoutReducer';
import combosReducer from './modules/MenuManagement/CombosManager/combosReducer';
import variantsReducer from './modules/MenuManagement/Variants/variantsReducer';
import categoryReducer from './modules/MenuManagement/Category/categoryReducer';
import posReducer from './modules/MenuManagement/POSMapping/posReducer';
import nutritionReducer from './modules/MenuManagement/NutritionTemplate/nutritionReducer';
import appIntegrationReducer from './modules/AppIntegration/AppIntegrationReducer';
import automatedMessagesReducer from './modules/Engagement/MobileMessages/AutomatedMessages/automatedMessagesReducer';
import passwordVerifierReducer from './modules/PasswordVerifier/passwordVerifierReducer';
import ReportTableReducer from './modules/ReportTable/ReportTableReducer';

let objReducers = { form }; // default reducers to be first entry

// add new reducers to this object
const reducers = {
  alert,
  accountReducer,
  settlements,
  batchDetail,
  orderHistory,
  profile,
  segments,
  usersDashboard,
  transactionsDashboard,
  topLocationsDashboard,
  productsReducer,
  optionReducer,
  mobileMessagesReducer,
  appFeed,
  variantsReducer,
  coupons,
  orderDetails,
  tagsReducer,
  customerSearchManager,
  offersReducer,
  combos,
  businessSearchManager,
  businessLocationsList,
  googleMapsWithOverlayZones,
  businessLocationDeliveryZones,
  businessLocationDetails,
  // upsellReducer,
  customerListReducer,
  customerReducer,
  // offerForm,
  mediaReducer,
  feedbackReducer,
  rolesReducer,
  offers,
  priceReducer,
  appFeedLayoutReducer,
  combosReducer,
  nutritionReducer,
  categoryReducer,
  posReducer,
  appDetailReducer,
  appIntegrationReducer,
  fullReducer,
  automatedMessagesReducer,
  ReportTableReducer,
  passwordVerifierReducer,
};

// sort by keys
// then add to object reducers
Object.keys(reducers)
  .sort()
  .forEach((reducer) => {
    objReducers = { ...objReducers, [reducer]: reducers[reducer] };
  });

const appReducer = combineReducers(objReducers);

const rootReducer = (state, action) => {
  if (action.type === UNAUTH_USER) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
