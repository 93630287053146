import React, { useEffect } from 'react';
import ReportTable from '../ReportTable/ReportTable';
import moment from 'moment';
import { BatchDetailPage } from 'merchant-portal-components';

function LoyaltyReport(props) {
  useEffect(() => {
    track.page('product-report');
  }, []);

  const columnNames = [
    'Product',
    'No. of Times Ordered',
    'Product Sales',
    'No. of Times Ordered Outside Combo',
    // 'AVG Price',
  ];

  const initialParams = {
    page_number: 1,
    page_size: 10,
    export: false,
    start_date: `${moment().subtract(1, 'days').format('YYYY-MM-DD')}`,
    end_date: `${moment().format('YYYY-MM-DD')}`,
    sort_by: 'product_name',
    sort_direction: 'asc',
  };

  const notSortColumns = [
    'num_times_ordered_outside_combo',
    'num_times_ordered',
  ];

  const columnSort = [
    'product_name',
    'num_times_ordered',
    'product_price',
    'num_times_ordered_outside_combo',
    // 'avg_price',
  ];

  const ignoreColumns = ['avg_price'];

  return (
    <BatchDetailPage>
      <ReportTable
        url="/report/product"
        columnNames={columnNames}
        title="Product Performance Report"
        csvRequestPath="report/product"
        filters={['date', 'location']}
        initialParams={initialParams}
        emptyMessage={
          'There Are No Products To Show Between The Currently Selected Dates'
        }
        notSortColumns={notSortColumns}
        columnSort={columnSort}
        ignoreColumns={ignoreColumns}
      />
    </BatchDetailPage>
  );
}

export default LoyaltyReport;
