import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadCombosListPage } from '../combosAction';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { ChevronLeftIcon } from 'merchant-portal-components';
import CombosDetailIndex from './CombosDetailIndex';

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
`;

const BackDiv = styled.div`
  cursor: pointer;
  margin-top: 32px;
`;

const CombosDetailContainer = ({
  editingCombo,
  loadCombosListPage,
  isEdit,
  selectedMenuManagementChildLocation,
  history,
}) => {
  useEffect(() => {
    if (!selectedMenuManagementChildLocation.id) {
      history.push('/combos');
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <Wrapper>
      <BackDiv onClick={() => loadCombosListPage()}>
        <ChevronLeftIcon style={{ marginRight: '11px' }} />
        Back to Combos and Specials
      </BackDiv>
      <BreadCrumb
        pageName={isEdit ? `Edit ${editingCombo.name}` : 'Create A Combo'}
      ></BreadCrumb>
      <CombosDetailIndex
        initValues={
          isEdit
            ? editingCombo
            : {
                comboName: '',
                comboDescription: '',
                comboFixedPrice: '',
                comboImgUrl: '',
                taxCategoryId: '',
                order_types: [],
              }
        }
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  editingCombo: state.combosReducer.editingCombo,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});

export default connect(mapStateToProps, {
  loadCombosListPage,
})(CombosDetailContainer);
