import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as actions from '../productsActions';
import { connect } from 'react-redux';
import ProductWizardIndex from './components/ProductWizardIndex';

const Wrapper = styled.div`
  width: 800px;
  margin: 0 auto;
`;

const ProductWizardContainer = (props) => {
  const [productPreviewIsVisible, setProductPreviewIsVisible] = useState(false);

  useEffect(() => {
    if (!props.selectedMenuManagementChildLocation.id) {
      props.history.push('/products');
      return;
    }
    window.scrollTo(0, 0);
    return () => props.resetProductReducerState();
  }, []);
  useEffect(() => {
    if (productPreviewIsVisible) {
      props.setProductPreviewFlatProduct();
    } else {
      props.setProductPreviewFlatProduct(null);
    }
  }, [productPreviewIsVisible]);

  return (
    <Wrapper>
      <ProductWizardIndex
        resetProductReducerState={props.resetProductReducerState}
        getProductCategoriesIsRequesting={
          props.getProductCategoriesIsRequesting
        }
        getProductVariantsIsRequesting={props.getProductVariantsIsRequesting}
        getProductOptionsIsRequesting={props.getProductOptionsIsRequesting}
        getProductBusinessesIsRequesting={
          props.getProductBusinessesIsRequesting
        }
        productPreviewIsVisible={productPreviewIsVisible}
        setProductPreviewIsVisible={setProductPreviewIsVisible}
        productCustomizerReducer={props.productCustomizerReducer}
        appConfig={props.appConfig}
        isRequestingProductPosMappings={props.isRequestingProductPosMappings}
        orderType={props.orderType}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  getProductCategoriesIsRequesting:
    state.productsReducer.getProductCategoriesIsRequesting,
  getProductVariantsIsRequesting:
    state.productsReducer.getProductVariantsIsRequesting,
  getProductOptionsIsRequesting:
    state.productsReducer.getProductOptionsIsRequesting,
  getProductBusinessesIsRequesting:
    state.productsReducer.getProductBusinessesIsRequesting,
  productCustomizerReducer: state.productsReducer.productCustomizerReducer,
  appConfig: state.accountReducer.appMetaData.configuration,
  isRequestingProductPosMappings:
    state.posReducer.isRequestingProductPosMappings,
  isEditing: state.productsReducer.isEditing,
  orderType: state.productsReducer.productInformationInitialValues.orderType,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});
export default connect(mapStateToProps, actions)(ProductWizardContainer);
