import React from 'react';
import styled from 'styled-components';
// import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import { Icon } from 'merchant-portal-components';
import { getPagesWithPermissions } from '../configs/pagesConfig';
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gird-gap: 0;
  margin: 0;
  padding: 7px 30px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;
const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gird-gap: 0;
  margin: 0;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #191818;
  border-top: 1px solid #e1e1e1;
`;
const CheckMarkIcon = styled(Icon)`
  cursor: default;
  font-size: 16px;
  color: ${(props) => props.theme.cerulean};
`;

const XMarkIcon = styled(Icon)`
  cursor: default;
  font-size: 19px;
  font-weight: 400;
  color: ${(props) => props.theme.gray};
`;

const PageName = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.black};
`;

const CheckMark = (props) => {
  return <CheckMarkIcon className="fas fa-check" />;
};

const XMark = (props) => {
  return <XMarkIcon className="fal fa-times" />;
};

const RolesTable = (props) => {
  const data = getPagesWithPermissions();

  return (
    <div>
      <div style={{ borderLeft: 'none', borderRight: 'none' }}>
        <TableHeader>
          {data.headers.map((item, index) => (
            <div
              key={index}
              style={{
                textAlign: 'center',
              }}
            >
              {item
                .toLowerCase()
                .replace(/_/g, ' ')
                .replace(/role/g, '')
                .split(' ')
                .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
                .join(' ')}
            </div>
          ))}
        </TableHeader>
        <div>
          {data.rows.map((item, index) => (
            <TableRow key={index}>
              <div key={index}>{item.name}</div>
              {item.authorization.map((value, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {value ? <CheckMark /> : <XMark />}
                </div>
              ))}
            </TableRow>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RolesTable;
