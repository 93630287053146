import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Form, getFormValues } from 'redux-form';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import { ContainerDiv, StyledP } from '../../../common/commonElements';
import { Icon } from 'merchant-portal-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import SpecialHoursFormNotes from './SpecialHoursFormNotes';
import {
  makeSpecialHoursForForm,
  getInvalidBounds,
  getGoodDays,
  getInvalidDays,
} from '../../../utils/specialHoursForm';
import StoreHoursAndOrderAheadHours from './StoreHoursAndOrderAheadHours';
import { ModalBody as ModalBase } from '../../../../../../components/FormElements/FormElements.js';
import { Dialog } from '@material-ui/core';

const ModalBody = styled(ModalBase)`
  height: 90%;
  overflow-y: scroll;
  width: 100%;
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: bold;
`;

const TitleAndCloseButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const DateRangeContainer = styled.div`
  height: 375px;
`;

const HorizontalLine = styled.hr`
  border-top: 1px solid #ccc;
  width: 100%;
`;

const EnterSpecialHoursNotes = styled.div`
  padding: 20px 0;
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 20px;
`;

const DateRangePickerWrapper = styled.div`
  z-index: 1300;
  .PresetDateRangePicker_button {
    border: 2px solid #296bde;
    color: #296bde;
  }
  .PresetDateRangePicker_button__selected {
    background: #296bde;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid #296bde;
  }
  .daypickerkeyboardshortcuts_show__bottomright:hover {
    border-right: 33px solid #296bde;
  }
  .CalendarDay__selected_span {
    background: #628fdc;
    border: 1px double #4c7ed7;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #4c7ed7;
    border: 1px double #4c7ed7;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #296bde;
    border: 1px double #296bde;
    color: ${(props) => props.theme.white};
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #799fe1;
    border: 1px double #628fdc;
    color: ${(props) => props.theme.white};
  }
  .CalendarDay__hovered_span:active {
    background: #296bde;
    border: 1px double #296bde;
    color: ${(props) => props.theme.white};
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a;
  }
`;

const dayToIndex = {
  monday: 0,
  tuesday: 1,
  wednesday: 2,
  thursday: 3,
  friday: 4,
  saturday: 5,
  sunday: 6,
};

const SPECIAL_HOURS = 'specialHours';

class SpecialHoursForm extends Component {
  state = {
    isLearnMoreVisible: true,
    startDate: null,
    endDate: null,
    focusedInput: null,
    leftInvalid: null,
    rightInvalid: null,
    invalidDays: [],
  };

  componentDidMount() {
    const { specialHoursToEdit, specialHours } = this.props;
    let goodDays = [null, null];
    if (specialHoursToEdit) {
      const { startDate, endDate } = specialHoursToEdit.startEndDate;
      this.setState({
        startDate: moment(startDate, 'YYYY-MM-DD'),
        endDate: moment(endDate, 'YYYY-MM-DD'),
      });
      goodDays = getGoodDays(startDate, endDate);
    }

    let invalidDays = getInvalidDays(specialHours, goodDays);

    this.setState({ invalidDays: invalidDays });
  }

  componentDidUpdate(prevProps, prevState) {
    const { formValues } = this.props;
    const { startDate, endDate } = this.state;

    if (formValues && startDate && endDate) {
      if (this.state.endDate != prevState.endDate) {
        let from = moment(startDate, 'YYYY-MM-DD');
        let to = moment(endDate, 'YYYY-MM-DD');
        for (let i = 0; i < 7; i++) {
          this.props.change(`${SPECIAL_HOURS}[${i}].isSpecial`, false);
        }

        for (let i = 1; i <= 7; i++) {
          if (from.diff(to) <= 0) {
            this.props.change(
              `${SPECIAL_HOURS}[${
                dayToIndex[from.format('dddd').toLowerCase()]
              }].isSpecial`,
              true
            );
          }
          from = from.add(1, 'day');
        }
      }
    }
  }

  updateInvalidDates = (startDate, endDate) => {
    const { invalidDays } = this.state;

    this.setState({ leftInvalid: null, rightInvalid: null });

    const { leftInvalid, rightInvalid } = getInvalidBounds(
      invalidDays,
      startDate,
      endDate
    );

    if (leftInvalid) {
      this.setState({ leftInvalid });
    }
    if (rightInvalid) {
      this.setState({ rightInvalid });
    }
  };

  isDayBlocked = (day) =>
    this.state.invalidDays.filter((invalidDay) => invalidDay.isSame(day, 'day'))
      .length > 0;

  handleIsLearnMoreVisible = (isLearnMoreVisible) => {
    this.setState({ isLearnMoreVisible });
  };

  handleHourIsOpenChange = (index) => {
    this.props.change(`${SPECIAL_HOURS}[${index}].orderAheadIsOpen`, false);
  };

  handleCancelButtonClick = () => {
    this.props.handleCloseSpecialHoursForm();
  };

  handleFormValuesSubmit = (formValues) => {
    let postSpecialHours = [];
    let patchSpecialHours = [];
    let deleteSpecialHours = [];

    let { startDate, endDate } = this.state;

    startDate = startDate.format('YYYY-MM-DD');
    endDate = endDate.format('YYYY-MM-DD');

    formValues.specialHours.map((specialHour) => {
      if (!specialHour.isSpecial && specialHour.id) {
        deleteSpecialHours.push(specialHour);
      } else {
        if (specialHour.id) {
          patchSpecialHours.push({
            ...specialHour,
            startDate,
            endDate,
          });
        } else if (specialHour.isSpecial) {
          postSpecialHours.push({
            ...specialHour,
            startDate,
            endDate,
          });
        }
      }
    });

    this.props.handleSpecialHoursFormSubmit(
      this.props.businessLocationId,
      postSpecialHours,
      patchSpecialHours,
      deleteSpecialHours
    );
  };

  render() {
    const { isLearnMoreVisible, startDate, endDate } = this.state;
    const {
      handleCloseSpecialHoursForm,
      handleSubmit,
      formValues,
      locationName,
    } = this.props;
    return (
      <Dialog open maxWidth="lg">
        <ModalBody padding="40px 60px">
          <Form onSubmit={handleSubmit(this.handleFormValuesSubmit)}>
            <TitleAndCloseButtonContainer>
              <Title>Special Hours for a Specific Location</Title>
              <Icon
                className="fal fa-times"
                onClick={() => handleCloseSpecialHoursForm()}
              />
            </TitleAndCloseButtonContainer>
            <SpecialHoursFormNotes
              handleIsLearnMoreVisible={this.handleIsLearnMoreVisible}
              isLearnMoreVisible={isLearnMoreVisible}
            />
            <ContainerDiv padding="0 0 10px 0">
              <ContainerDiv>
                <StyledP style={{ fontSize: '15px' }}>
                  Location Selected
                </StyledP>
              </ContainerDiv>
              <ContainerDiv margin="0 0 0 10px">
                <StyledP semiBold>{locationName}</StyledP>
              </ContainerDiv>
            </ContainerDiv>
            <DateRangeContainer>
              <StyledP semiBold size="15px">
                Choose a Date Range to input Special Hours*
              </StyledP>
              <DateRangePickerWrapper>
                <DateRangePicker
                  startDateId="startDate"
                  endDateId="endDate"
                  minimumNights={0}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  isDayBlocked={this.isDayBlocked}
                  onDatesChange={({ startDate, endDate }) => {
                    this.updateInvalidDates(startDate, endDate);
                    this.setState({ startDate, endDate });
                  }}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={(focusedInput) => {
                    if (this.state.focusedInput == null) {
                      this.setState({
                        leftInvalid: null,
                        rightInvalid: null,
                      });
                    }
                    if (focusedInput == 'startDate') {
                      this.setState({
                        endDate: null,
                        leftInvalid: null,
                        rightInvalid: null,
                      });
                    } else if (focusedInput == 'endDate') {
                      this.setState({ endDate: null });
                      this.updateInvalidDates(this.state.startDate, null);
                    }
                    this.setState({ focusedInput });
                  }}
                  isOutsideRange={(day) => {
                    return (
                      day.diff(this.state.leftInvalid) < 0 ||
                      day.diff(this.state.rightInvalid) > 0
                    );
                  }}
                />
              </DateRangePickerWrapper>
            </DateRangeContainer>

            {startDate && endDate && (
              <Fragment>
                <ContainerDiv margin="32px 0">
                  <HorizontalLine />
                </ContainerDiv>
                <EnterSpecialHoursNotes>
                  <StyledP semiBold>
                    Enter the Special Hours for the following Days
                  </StyledP>
                  <StyledP>
                    Fields display regular hours. Click on a field to edit or
                    click the checkbox to show the location as closed for the
                    day.
                  </StyledP>
                </EnterSpecialHoursNotes>
                <StoreHoursAndOrderAheadHours
                  storeHours={formValues.specialHours}
                  handleHourIsOpenChange={this.handleHourIsOpenChange}
                  formFieldName={SPECIAL_HOURS}
                />
              </Fragment>
            )}
            <ButtonGrid>
              <ContainerDiv>
                <Button
                  type="button"
                  secondary
                  onClick={() => this.handleCancelButtonClick()}
                >
                  Cancel
                </Button>
              </ContainerDiv>
              <ContainerDiv textAlign="right">
                <Button
                  type="submit"
                  disabled={
                    this.props.isFormSubmitting || !(startDate && endDate)
                  }
                >
                  {this.props.isFormSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </ContainerDiv>
            </ButtonGrid>
          </Form>
        </ModalBody>
      </Dialog>
    );
  }
}

SpecialHoursForm = reduxForm({
  form: 'SpecialHoursForm',
  enableReinitialize: true,
  persistentSubmitErrors: true,
  destroyOnUnmount: true,
})(SpecialHoursForm);

SpecialHoursForm = connect((state) => {
  const { specialHoursToEdit } = state.businessLocationDetails;

  let specialHours = makeSpecialHoursForForm(specialHoursToEdit);

  return {
    initialValues: {
      specialHours: specialHours,
    },
    formValues: getFormValues('SpecialHoursForm')(state),
  };
})(SpecialHoursForm);

export default SpecialHoursForm;
