import React from 'react';
import {
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';

import { EditIcon, ReadIcon } from '../../../../../../components/Icon';
import PaginationControl from '../../../../../../components/Pagination/PaginationControl';
import styled from 'styled-components';
import { sortByTypes, STRING, DATE } from '../../../../../../utils/sort/sort';

import moment from 'moment';

const PaginationWrapper = styled.div`
  // padding-top: 135px;
  // padding-bottom: 33px;
`;

const renderHeaders = (requestParams, handleSort) => {
  return (
    <TrHead style={{ background: 'none' }}>
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'name',
            sortMethod: sortByTypes,
            sortType: STRING,
          });
        }}
        value="name"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
      >
        Name
      </Th>
      <Th align="left">Type</Th>
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'status',
            sortMethod: sortByTypes,
            sortType: STRING,
          });
        }}
        value="status"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
      >
        Status
      </Th>
      <Th
        align="left"
        onClick={() => {
          handleSort({
            value: 'updated_at',
            sortMethod: sortByTypes,
            sortType: DATE,
          });
        }}
        value="updated_at"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
      >
        Last Modified
      </Th>
      <Th align="left"> Total Subscriptions</Th>
      <Th align="left"> Total Msgs Delivered</Th>
      <Th align="left"> Total Msgs Failed</Th>
      <Th align="left">Edit</Th>
    </TrHead>
  );
};

const renderRows = (props) => {
  return props.messagesActiveList.length > 0 ? (
    props.messagesActiveList.map((mobileMessage, index) => {
      return (
        <Tr key={index} style={{ height: '75px' }}>
          <Td align="left">{mobileMessage.name}</Td>
          <Td align="left">{mobileMessage.type}</Td>
          <Td align="left">{mobileMessage.status}</Td>
          <Td align="left">
            {moment(mobileMessage.updated_at).format('YYYY-MM-DD HH:mm:ss')}
          </Td>
          <Td align="left">
            {mobileMessage.stats.total_subscriptions
              ? mobileMessage.stats.total_subscriptions
              : '0'}
          </Td>
          <Td align="left">
            {mobileMessage.stats.total_messages_delivered
              ? mobileMessage.stats.total_messages_delivered
              : '0'}
          </Td>
          <Td align="left">
            {mobileMessage.stats.total_messages_failed
              ? mobileMessage.stats.total_messages_failed
              : '0'}
          </Td>
          <Td align="left">
            {moment(mobileMessage.send_at).isAfter(
              moment().add(5, 'minutes')
            ) ? (
              <EditIcon
                onClick={() =>
                  props.initiateEditMobileMessage({ mobileMessage })
                }
              />
            ) : (
              <ReadIcon
                onClick={() => props.readOnlyMobileMessage({ mobileMessage })}
              />
            )}
          </Td>
        </Tr>
      );
    })
  ) : (
    <Tr>
      <Td colSpan="6" padding="50px 0" align="center">
        {' '}
        No matching data{' '}
      </Td>
    </Tr>
  );
};

const MobileMessagesList = (props) => {
  return (
    <div style={{ borderRadius: '12px' }}>
      <Table noBorder>
        <thead>{renderHeaders(props.requestParams, props.handleSort)}</thead>
        <TBody striped>{props.messagesActiveList && renderRows(props)}</TBody>
      </Table>
      {props.messagesActiveList && (
        <PaginationWrapper>
          <PaginationControl
            response={{
              ...props.messagesActiveList,
              page: props.requestParams.page_number,
              page_size: props.requestParams.page_size,
              count: props.mobileMessagesReducer.response.data.length,
            }}
            requestParams={props.requestParams}
            type="mobile"
          />
        </PaginationWrapper>
      )}
    </div>
  );
};

export default MobileMessagesList;
