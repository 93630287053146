import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TableWrapper,
} from 'merchant-portal-components';
import Moment from 'moment';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { toPriceFormat } from '../../../../utils/toPriceFormat';

const ViewDetails = styled.div`
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: #909cae;
  }
`;

const NoOrdersFound = styled.div`
  text-align: center;
  position: relative;
  transform: translateY(50%);
  height: 250px;
  font-size: 38px;
  color: #d8d8d8;
`;

const LinkDiv = styled.a`
  color: inherit;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 5px 20px;

  :hover {
    color: inherit;
    text-decoration: none;
  }

  :focus {
    text-decoration: none;
  }
`;

const renderHeaders = () => {
  return (
    <TrHead data-test="component-TrHead">
      <Th align="left">Date</Th>
      <Th align="left">Order#</Th>
      <Th align="left">POS#</Th>
      <Th align="left">Location</Th>
      <Th align="left">Customer</Th>
      <Th align="left">Total</Th>
      <Th align="left">Status</Th>
      {/* <Th align="left">Detail</Th> */}
    </TrHead>
  );
};

const renderRows = (orders, handleActionButtonClick) =>
  orders.map((order, index) => (
    <Tr
      // the way to directly open the detail page on new tab on right click
      // onClick={() => {
      //   window.open(`/order-history/${order.order_id}`, '_blank');
      //   //handleActionButtonClick(order.order_id);
      // }}
      // onContextMenu={e => {
      //   e.preventDefault();
      //   window.open(`/order-history/${order.order_id}`, '_blank');
      // }}
      data-href={`/order-history/${order.order_id}`}
      key={index}
      style={{ cursor: 'pointer' }}
      data-test="component-Tr"
    >
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv href={`/order-history/${order.order_id}`}>
          {Moment(order.order_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
        </LinkDiv>
      </Td>
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv href={`/order-history/${order.order_id}`}>
          {order.order_id}
        </LinkDiv>
      </Td>
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv href={`/order-history/${order.order_id}`}>
          {order.pos_order_id}
        </LinkDiv>
      </Td>
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv href={`/order-history/${order.order_id}`}>
          {order.business_name}
        </LinkDiv>
      </Td>
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv
          href={`/order-history/${order.order_id}`}
        >{`${order.customer.first_name} ${order.customer.last_name}`}</LinkDiv>
      </Td>
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv href={`/order-history/${order.order_id}`}>
          {toPriceFormat(order.total)}
        </LinkDiv>
      </Td>
      <Td align="left" style={{ padding: '0' }}>
        <LinkDiv href={`/order-history/${order.order_id}`}>
          {order.status}
        </LinkDiv>
      </Td>
      {/* <Td align="center">
        <ViewDetails onClick={() => handleActionButtonClick(order.order_id)}>
          View
        </ViewDetails>
      </Td> */}
    </Tr>
  ));

export const OrderHistoryTable = (props) => {
  const {
    orders,
    ordersOfMerchantIsRequesting,
    handleActionButtonClick,
    orderHistoryTableRef,
  } = props;
  return (
    <div ref={orderHistoryTableRef}>
      <TableWrapper style={{ boxShadow: 'none' }}>
        <Table data-test="component-Table" noBorder>
          <TBody striped>
            {renderHeaders()}
            {!ordersOfMerchantIsRequesting && orders.length
              ? renderRows(orders, handleActionButtonClick)
              : null}
          </TBody>
        </Table>
      </TableWrapper>

      {ordersOfMerchantIsRequesting ? (
        <LoadingBlueLarge data-test="component-LoadingBlueLarge" />
      ) : !orders.length ? (
        <NoOrdersFound data-test="component-NoOrdersFound">
          No Orders Found
        </NoOrdersFound>
      ) : null}
    </div>
  );
};

export default OrderHistoryTable;
