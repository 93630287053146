import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ChooseOptionVariants from './ChooseOptionVariants';
import NoOptionVariantsSelected from './NoOptionVariantsSelected';
import OptionPricesForm from './OptionPricesForm';
import { Box } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { flatten } from 'flat';
import OptionOverridesContainer from './OptionOverridesContainer';
const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-top: 25px;
  border-radius: 20px;
`;

const AngeledTabList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
const AngeledTab = styled.li`
  display: inline;
  text-align: 'left';
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 8px 30px 8px 10px;
  color: ${(props) => (props.isSelected ? '#1e3697' : '#848484')};
  background-color: #fcfcfc;
  cursor: pointer;
  clip-path: polygon(0 0, 85% 0, 100% 100%, 0% 100%);
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.09);
`;
const Price = ({
  selectOption,
  selectVariants,
  selectedOption,
  options,
  variants,
  optionPrices,
  selectedVariants,
  items,
  handleSave,
  isRequesting,
  itemOverrides,
  isRequestingItemOverrides,
  formattedBusinesses,
  isOptionOverridesModal,
  setIsOptionOverridesModal,
  page,
  setPage,
  accociatedProductList,
  getAssociatedProductForOption,
  initiateEditProductWizard,
  selectedLocations,
  updateSelectedLocations,
}) => {
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);
  const [tabValue, setTabValue] = useState('1');
  const handleTabChange = (e) => {
    setTabValue(e.target.value.toString());
  };
  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <AngeledTabList>
            <AngeledTab
              value="1"
              isSelected={tabValue === '1' ? true : false}
              onClick={handleTabChange}
            >
              {'Option Pricing'}
            </AngeledTab>
            {/* <AngeledTab
              value="2"
              isSelected={tabValue === '2' ? true : false}
              onClick={handleTabChange}
            >
              {'Overrides'}
            </AngeledTab>  */}
          </AngeledTabList>
          <TabPanel
            value="1"
            style={{
              backgroundColor: '#fff',
              borderStartEndRadius: '20px',
              borderEndEndRadius: '20px',
              borderEndStartRadius: '20px',
              paddingTop: '50px',
              paddingBottom: '50px',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
            }}
          >
            <ChooseOptionVariants
              selectOption={selectOption}
              selectVariants={selectVariants}
              options={options}
              variants={variants}
              optionPrices={optionPrices}
              isRequestingItemOverrides={isRequestingItemOverrides}
              isOptionDisabled={isOptionDisabled}
              accociatedProductList={accociatedProductList}
              selectedOptionId={selectedOption?.id}
              getAssociatedProductForOption={getAssociatedProductForOption}
              initiateEditProductWizard={initiateEditProductWizard}
              updateSelectedLocations={updateSelectedLocations}
            />
            <Wrapper>
        {items?.length > 0 && (
          <OptionPricesForm
            includedPick={optionPrices.included_pick}
            items={items}
            handleSave={handleSave}
            isRequesting={isRequesting}
            selectOption={selectOption}
            itemOverrides={itemOverrides}
            selectedOption={selectedOption}
            optionPrices={optionPrices}
            isRequestingItemOverrides={isRequestingItemOverrides}
            selectedVariants={selectedVariants}
            formattedBusinesses={formattedBusinesses}
            isOptionOverridesModal={isOptionOverridesModal}
            setIsOptionOverridesModal={setIsOptionOverridesModal}
            page={page}
            setPage={setPage}
            isOptionDisabled={isOptionDisabled}
            setIsOptionDisabled={setIsOptionDisabled}
            selectedLocations={selectedLocations}
            updateSelectedLocations={updateSelectedLocations}
          />
        )}
      </Wrapper>
          </TabPanel>
          {/* <TabPanel
            value="2"
            style={{
              backgroundColor: '#fff',
              borderStartEndRadius: '20px',
              borderEndEndRadius: '20px',
              borderEndStartRadius: '20px',
              paddingTop: '50px',
              paddingBottom: '50px',
              boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.09)',
            }}
          >
            <OptionOverridesContainer> </OptionOverridesContainer>
          </TabPanel> */}
        </TabContext>
      </Box>
    </div>
  );
};

export default Price;
