import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TableWrapper,
  Table,
  TBody,
  EmptyStateMessage,
} from 'merchant-portal-components';
import {
  getAPIData,
  updateParametersAndCallAPI,
  resetReportTableReducer,
} from './ReportTableActions';
import exportCSVWrapper from '../HOCs/exportCSVWrapper';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { BatchDetailPage } from 'merchant-portal-components';
import { connect } from 'react-redux';
import ReportTableHeader from './components/ReportTableHeader';
import ReportTableBody from './components/ReportTableBody';
import PropTypes from 'prop-types';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import Pagination from '../../components/Pagination/Pagination';
import { ParamsWrapper } from 'merchant-portal-components';
import { Button, ButtonRow, LoadingButton } from 'merchant-portal-components';

import ReportDateFilter from './components/filters/ReportDateFilter';
import ReportLocationFilter from './components/filters/ReportLocationFilter';

const FilteringWrapper = styled.div`
  // display: flex;
  // align-items: baseline;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
  padding-top: ${(props) => (props.addPadding ? '11px' : '0px')};
`;

function ReportTable(props) {
  const {
    url,
    checkBox,
    filters,
    columnNames,
    ignoreColumns,
    csvRequestPath,
    initialParams,
    emptyMessage,
    nestedTable,
    nestedFields,
    boldFields,
    useOldSort,
    notSortColumns,
    columnSort,
    // isFirstRender,
    // setIsFirstRender,
  } = props;

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      props.resetReportTableReducer();
      setIsFirstRender(false);
    } else if (url) {
      props.getAPIData(props, props.title, url, initialParams, columnNames);
    }
    return () => {
      props.resetReportTableReducer();
    };
  }, [url, isFirstRender]);

  const updateDataOnParamChange = (params) => {
    props.updateParametersAndCallAPI(
      props,
      props.title,
      url,
      params,
      columnNames
    );
  };

  return (
    <BatchDetailPage>
      <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
        <BreadCrumb pageName={props.title} />
        <ButtonRow justifyContent="flex-end">
          {props.requestingCSV ? (
            <LoadingButton secondary disabled>
              Export
            </LoadingButton>
          ) : (
            <Button
              disabled={csvRequestPath === 'none'}
              onClick={() =>
                props.exportCSV({
                  requestPath: csvRequestPath,
                  fileName: `${csvRequestPath}`,
                  requestParams: props.tableReport.initialParams,
                })
              }
            >
              Export
            </Button>
          )}
        </ButtonRow>
      </div>
      <TableWrapper>
        <FilteringWrapper addPadding={!filters.includes('location')}>
          <ParamsWrapper>
            {filters.includes('date') ? (
              <ReportDateFilter
                updateRequestParams={updateDataOnParamChange}
                requestParams={props.tableReport.initialParams}
              />
            ) : null}
          </ParamsWrapper>
          {filters.includes('location') ? (
            <ReportLocationFilter
              requestParams={props.tableReport.initialParams}
              businesses={props.businesses}
              updateRequestParams={updateDataOnParamChange}
            />
          ) : null}
        </FilteringWrapper>
        <div style={{ overflowX: 'auto' }}>
          <Table noBorder>
            <thead>
              <ReportTableHeader
                columns={props.tableReport.columns}
                columnNames={columnNames ? columnNames : []}
                ignoreColumns={ignoreColumns ? ignoreColumns : []}
                checkBox={checkBox ? checkBox : false}
                requestParams={props.tableReport.initialParams}
                updateRequestParams={updateDataOnParamChange}
                useOldSort={useOldSort ? useOldSort : false}
                notSortColumns={notSortColumns ? notSortColumns : []}
              />
            </thead>
            <TBody striped>
              {!isFirstRender && !props.isRequestingData && (
                <ReportTableBody
                  rows={props.tableReport.rows}
                  columnSort={columnSort}
                  checkBox={checkBox}
                  ignoreFields={ignoreColumns ? ignoreColumns : []}
                  checkBox={checkBox ? checkBox : false}
                  nestedTable={nestedTable ? nestedTable : false}
                  nestedFields={nestedFields ? nestedFields : null}
                  boldFields={boldFields ? boldFields : []}
                />
              )}
            </TBody>
          </Table>
        </div>
        {!props.isRequestingData &&
          props.tableReport.rows.length > 0 &&
          props.tableReport.meta.count > 0 && (
            <Pagination
              response={props.tableReport.meta}
              requestParams={props.tableReport.initialParams}
              updateRequestParams={updateDataOnParamChange}
            />
          )}
      </TableWrapper>
      {props.isRequestingData && <LoadingBlueLarge />}
      {!props.isRequestingData && props.tableReport.rows.length === 0 && (
        <EmptyStateMessage>{emptyMessage}</EmptyStateMessage>
      )}
    </BatchDetailPage>
  );
}

ReportTable.propTypes = {
  url: PropTypes.string.isRequired,
  csvRequestPath: PropTypes.string.isRequired,
  initialParams: PropTypes.object.isRequired,
  columnsOrder: PropTypes.array,
  ignoreColumns: PropTypes.array,
  checkBox: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isRequestingData: state.ReportTableReducer.isRequestingData,
  tableReport: state.ReportTableReducer,
  businesses: state.businessLocationsList.businessLocationsOfMerchant,
});

export default connect(mapStateToProps, {
  getAPIData,
  updateParametersAndCallAPI,
  resetReportTableReducer,
})(exportCSVWrapper(ReportTable));
