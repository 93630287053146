import {
  ROLE_FSO_SUPER_ADMIN,
  ROLE_FSO_ADMIN,
  ROLE_FSO_OPERATOR,
} from "./rolesRepository";

export default {
  toppers: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR],
  sodexo: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR],
  default: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR],
};
