import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  SHOW_PRODUCT_INDEX,
  TOGGLE_PRODUCT_REQUEST,
  TOGGLE_PRODUCT_SUCCESS,
  TOGGLE_PRODUCT_FAILURE,
  // SHOW_CREATE_PRODUCT_FORM,
  // CREATE_PRODUCT_REQUEST,
  // CREATE_PRODUCT_RESPONSE,
  // SHOW_EDIT_PRODUCT_FORM,
  // DELETE_PRODUCT_REQUEST,
  // DELETE_PRODUCT_RESPONSE,
  PATCH_PRODUCT_AVAILABILITIES,
  PATCH_PRODUCT_REQUEST,
  PATCH_PRODUCT_SUCCESS,
  PATCH_PRODUCT_FAILURE,
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_FAILURE,
  INITIATE_CREATE_PRODUCT,
  GET_PRODUCT_INFORMATION,
  RESET_PRODUCT_REDUCER_STATE,
  CREATE_PRODUCT_SUCCESS,
  LINK_PRODUCT_WITH_CATEGORIES_SUCCESS,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_VARIANTS_SUCCESS,
  UPDATE_PRODUCT_VARIANT_INITIAL_VALUES,
  RESET_PRODUCTION_VARIANT_INITIAL_VALUES,
  PUT_PRODUCT_VARIANTS_SUCCESS,
  GET_PRODUCT_OPTIONS_SUCCESS,
  PUT_PRODUCT_OPTIONS_SUCCESS,
  UPDATE_PRODUCT_TOGGLES,
  GET_PRODUCT_TAGS_SUCCESS,
  PUT_PRODUCT_TAGS_SUCCESS,
  SET_PRODUCT_PREVIEW_FLAT_PRODUCT,
  CHANGE_VARIANT,
  INCREMENT_ITEM,
  DECREMENT_ITEM,
  INCREMENT_SUB_ITEM,
  DECREMENT_SUB_ITEM,
  VALIDATE_OPTION,
  UPDATE_SORTED_PRODUCTS_LIST,
  GET_VARIANTS_REQUEST,
  SCAN_AND_GO,
  ALL_ORDER_TYPE_ID,
  GET_PRODUCT_AVAILABILITIES,
} from './productsTypes';
import { api } from '../../../api';
import { createAlert } from '../../Alert/actions';
import { normalize, schema } from 'normalizr';
import moment from 'moment';
import {
  getProductsMappingFields,
  getProductPosMappingValues,
} from '../POSMapping/posAction';
// import { cosh } from "core-js/core/number";
const sub_item = new schema.Entity('sub_items');

const item = new schema.Entity('items', {
  sub_items: [sub_item],
});

const sub_option = new schema.Entity('sub_options', {
  items: [item],
});

const option = new schema.Entity('options', {
  sub_options: [sub_option],
  items: [item],
});

const normalizeOptions = (originalOptions) =>
  normalize(originalOptions, [option]);

export function getProducts(
  requestParams = {
    page_number: 1,
    page_size: '25',
    sorting_option: 'title-asc',
    search_string: '',
    has_next_page: true,
  }
) {
  return function (dispatch, getState) {
    const storedSelectedChildLocationId = Number(
      localStorage.getItem('selectedChildLocationId')
    );
    const selectedChildLocationId =
      getState().businessLocationDetails.selectedMenuManagementChildLocation
        .id || storedSelectedChildLocationId;
    dispatch({ type: GET_PRODUCTS_REQUEST });
    return api
      .get(
        `/menu/products?page_size=${requestParams.page_size}&page_number=${requestParams.page_number}&search_string=${requestParams.search_string}&business_id=${selectedChildLocationId}`
      )
      .then(
        (response) => {
          const hasNextPage = response.data.meta.has_next_page;
          dispatch({
            type: GET_PRODUCTS_SUCCESS,
            allProducts: response.data.data,
            hasNextPage: hasNextPage,
          });
        },
        (error) => {
          dispatch({ type: GET_PRODUCTS_FAILURE, error });
        }
      );
  };
}
export function toggleProduct(id, is_active) {
  return function (dispatch) {
    dispatch({ type: TOGGLE_PRODUCT_REQUEST, id });
    api.patch(`/menu/products/${id}`, { is_active }).then(
      (response) => {
        dispatch({ type: TOGGLE_PRODUCT_SUCCESS, id });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Product was successfully updated ',
          })
        );
      },
      (error) => {
        dispatch({
          type: TOGGLE_PRODUCT_FAILURE,
          id,
          error,
        });
      }
    );
  };
}

export function initiateCreateProduct() {
  return function (dispatch) {
    dispatch({
      type: INITIATE_CREATE_PRODUCT,
    });
  };
}
export function getProductAvailabilities(productId) {
  return function (dispatch) {
    return api
      .get(`/menu/products/${productId}?include=availabilities`)
      .then((response) => {
        dispatch({
          type: GET_PRODUCT_AVAILABILITIES,
          payload: response.data.data.availabilities,
        });
      });
  };
}
export function getProductInformation(productId) {
  return function (dispatch, getState) {
    Promise.all([
      api.get(`/menu/products/${productId}`),
      api.get(`/menu/products/${productId}/order-types`),
    ]).then((response) => {
      if (
        !getState().accountReducer.appMetaData.configuration
          .pos_integration_enabled
      ) {
        api.get(`/menu/products/${productId}/taxes`).then((taxResponse) => {
          let orderType = response[1].data.data.map(
            (orderType) => orderType.id
          );
          if (orderType.length < 1) {
            orderType = [
              -1,
              ...getState().fullReducer.orderTypesList.map(
                (fullOrderType) => fullOrderType.id
              ),
            ];
          }
          dispatch({
            type: GET_PRODUCT_INFORMATION,
            editingProductId: productId,
            productToEdit: {
              is_points_enabled: response[0].data.data.is_points_enabled,
              name: response[0].data.data.name,
              user_description: response[0].data.data.user_description,
              image_url: response[0].data.data.image_url,
              start_time: response[0].data.data.start_time,
              end_time: response[0].data.data.end_time,
              taxCategoryId: taxResponse.data.data.tax_category_id,
              orderType,
              is_combo_only: response[0].data.data.is_combo_only,
              is_alcoholic: response[0].data.data.is_alcoholic,
            },
            productInformation: response[0].data.data,
            productToggles: {
              is_featured: response[0].data.data.is_featured,
              is_available: response[0].data.data.is_available,
              is_active: response[0].data.data.is_active,
            },
          });
        });
      } else {
        let orderType = response[1].data.data.map((orderType) => orderType.id);
        if (orderType.length < 1) {
          orderType = [
            -1,
            ...getState().fullReducer.orderTypesList.map(
              (fullOrderType) => fullOrderType.id
            ),
          ];
        }
        dispatch({
          type: GET_PRODUCT_INFORMATION,
          editingProductId: productId,
          productToEdit: {
            is_points_enabled: response[0].data.data.is_points_enabled,
            name: response[0].data.data.name,
            user_description: response[0].data.data.user_description,
            image_url: response[0].data.data.image_url,
            start_time: response[0].data.data.start_time,
            end_time: response[0].data.data.end_time,
            taxCategoryId: null,
            orderType,
            is_combo_only: response[0].data.data.is_combo_only,
            is_alcoholic: response[0].data.data.is_alcoholic,
          },
          productInformation: response[0].data.data,
          productToggles: {
            is_featured: response[0].data.data.is_featured,
            is_available: response[0].data.data.is_available,
            is_active: response[0].data.data.is_active,
          },
        });
      }
    });
  };
}
function getProductCategories(productId) {
  return function (dispatch, getState) {
    const localStorageChildBusinessId = parseInt(
      localStorage.getItem('selectedChildLocationId')
    );
    const selectedChildLocationId =
      getState().businessLocationDetails.selectedMenuManagementChildLocation
        .id || localStorageChildBusinessId;
    return api
      .get(`/menu/products/${productId}/categories`)
      .then((response) => {
        api
          .get(
            `/menu/categories?page_number=1&page_size=10000&business_id=${selectedChildLocationId}`
          )
          .then((globalCategories) => {
            const categories = globalCategories.data.data;
            let categoriesInitialValues = [];
            response.data.data.forEach((responseCategory) => {
              categories.forEach((category) => {
                if (category.id == responseCategory.category_id) {
                  categoriesInitialValues.push({
                    id: category.id,
                    position: responseCategory.position,
                    subCategoriesId: null,
                  });
                } else if (category.sub_categories) {
                  category.sub_categories.forEach((subCategory) => {
                    if (subCategory.id == responseCategory.category_id) {
                      let index = -1;
                      categoriesInitialValues.find((categoryToEdit, i) => {
                        if (categoryToEdit.id == category.id) index = i;
                      });
                      if (index > -1) {
                        categoriesInitialValues[index].subCategoriesId = [
                          ...categoriesInitialValues[index].subCategoriesId,
                          subCategory.id,
                        ];
                      } else {
                        categoriesInitialValues.push({
                          id: category.id,
                          position: responseCategory.position,
                          subCategoriesId: [subCategory.id],
                        });
                      }
                    }
                  });
                }
              });
            });
            dispatch({
              type: GET_PRODUCT_CATEGORIES_SUCCESS,
              categoriesInitialValues,
              categories,
            });
          });
      });
  };
}
const getEndDayOfWeek = (product) => {
  const startTime = moment(product.start_time);
  const endTime = moment(product.end_time);
  let endDayOfWeek = product.start_day_of_week;
  if (startTime.isAfter(endTime)) {
    endDayOfWeek = endDayOfWeek + 1 > 7 ? 1 : endDayOfWeek + 1;
  }
  return endDayOfWeek;
};

export function getProductVariants(productId) {
  return function (dispatch, getState) {
    const localStorageChildBusinessId = parseInt(
      localStorage.getItem('selectedChildLocationId')
    );
    const selectedChildLocationId =
      getState().businessLocationDetails.selectedMenuManagementChildLocation
        .id || localStorageChildBusinessId;
    api
      .get(
        `/menu/variants?page_number=1&page_size=10000&business_id=${selectedChildLocationId}`
      )
      .then((globalVariantsResponse) => {
        dispatch({ type: GET_VARIANTS_REQUEST });
        dispatch({
          type: 'GET_VARIANTS_SUCCESS',
          allVariants: globalVariantsResponse.data.data,
        });
        api
          .get(`/menu/products/${productId}?include=prices,upcs`)
          .then((productVariantsResponse) => {
            let productVariantsInitialValues = [];
            const upcs = productVariantsResponse.data.data.upcs;
            const prices = productVariantsResponse.data.data.prices;
            if (upcs.length > 0) {
              prices.forEach((price) => {
                const filteredUPC = upcs.find(
                  (upc) => upc.variant_id === price.product_variant_id
                );
                if (filteredUPC) {
                  productVariantsInitialValues.push({
                    ...price,
                    barcode: filteredUPC.upc,
                  });
                } else {
                  productVariantsInitialValues.push({
                    ...price,
                    barcode: '',
                  });
                }
              });
            } else
              prices.forEach((price) => {
                productVariantsInitialValues.push({
                  ...price,
                  barcode: '',
                });
              });
            {
            }
            dispatch({
              type: GET_PRODUCT_VARIANTS_SUCCESS,
              productVariantsInitialValues: productVariantsInitialValues,
            });
          });
      });
  };
}

export function getProductOptions(productId) {
  return function (dispatch, getState) {
    const localStorageChildBusinessId = parseInt(
      localStorage.getItem('selectedChildLocationId')
    );
    const selectedChildLocationId =
      getState().businessLocationDetails.selectedMenuManagementChildLocation
        .id || localStorageChildBusinessId;
    api
      .get(
        `/menu/options?include=items&page_number=1&page_size=100000&&business_id=${selectedChildLocationId}`
      )
      .then((globalOptionsResponse) => {
        dispatch({
          type: 'GET_OPTIONS_SUCCESS_NORMALIZED',
          response: normalizeOptions(globalOptionsResponse.data.data),
        });
        api.get(`menu/products/${productId}/options`).then((response) => {
          response.data.data.sort(
            (product1, product2) => product1.position - product2.position
          );
          dispatch({
            type: GET_PRODUCT_OPTIONS_SUCCESS,
            productOptionsInitialValues: response.data.data
              .map((productOption) => ({
                product_option_id: productOption.product_option_id,
                position: productOption.position,
              }))
              .sort((a, b) =>
                a.position < b.position ? -1 : a.position > b.position ? 1 : 0
              ),
            allOptions: globalOptionsResponse.data.data.map((option) => ({
              id: option.id,
              name: option.name
                .toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' '),
            })),
          });
        });
      });
  };
}

const initiatePOSData = (productId) => {
  return (dispatch, getState) => {
    const appConfig = getState().accountReducer.appMetaData.configuration;
    const posMappingFields = getState().posReducer.productsMappingFields;
    if (appConfig.pos_integration_enabled) {
      if (posMappingFields && posMappingFields.length > 0) {
        dispatch(getProductPosMappingValues(productId, posMappingFields));
      } else {
        dispatch(getProductsMappingFields(productId));
      }
    }
  };
};
export function initiateEditProductWizard(productToEdit) {
  return function (dispatch) {
    dispatch(getProductAvailabilities(productToEdit.id));
    dispatch(getProductInformation(productToEdit.id));
    dispatch(getProductCategories(productToEdit.id));
    dispatch(getProductVariants(productToEdit.id));
    dispatch(getProductOptions(productToEdit.id));
    // dispatch(getProductTags(productToEdit.id));
    // dispatch(initiatePOSData(productToEdit.id));
  };
}

export function setProductWizardIsVisible(productWizardIsVisible) {
  return {
    type: SET_PRODUCT_WIZARD_IS_VISIBLE,
    productWizardIsVisible,
  };
}

export function createProduct(productInformation) {
  return function (dispatch, getState) {
    const selectedChildLocationId =
      getState().businessLocationDetails.selectedMenuManagementChildLocation.id;
    dispatch({ type: CREATE_PRODUCT_REQUEST });
    let body = {
      ...productInformation,
      business_id: selectedChildLocationId,
      is_group_based: false, // hard coded
      is_featured: false, // false when initially created
      is_available: false, // false when initially created
      is_active: false, // false when initially created
    };
    delete body.taxCategoryId;
    delete body.orderType;
    api
      .post(`/menu/products`, body)
      .then((response) => {
        let orderType = productInformation.orderType;
        if (orderType.includes(ALL_ORDER_TYPE_ID)) {
          orderType = [];
        }

        api.put(`/menu/products/${response.data.data.id}/order-types`, {
          order_types: orderType,
        });
        if (
          !getState().accountReducer.appMetaData.configuration
            .pos_integration_enabled
        ) {
          api.put(`/menu/products/${response.data.data.id}/taxes`, {
            tax_category_id: productInformation.taxCategoryId,
          });
        }
        dispatch(
          createAlert({
            type: 'success',
            message: 'Product information created successfully',
          })
        );
        dispatch(getProductCategories(response.data.data.id));
        dispatch(getProductVariants(response.data.data.id));
        dispatch(getProductOptions(response.data.data.id));
        dispatch({
          type: CREATE_PRODUCT_SUCCESS,
          productId: response.data.data.id,
        });
      })
      .catch((error) => {
        if (error.response.status === 500) {
          dispatch({
            type: CREATE_PRODUCT_FAILURE,
          });
          dispatch(
            createAlert({
              type: 'error',
              message: 'Product name is already taken',
            })
          );
        }
      });
  };
}

export function patchProduct(
  productInformationWithFormattedTimes,
  productAvailabilities,
  productId
) {
  let body = {
    name: productInformationWithFormattedTimes.name,
    start_time: productInformationWithFormattedTimes.start_time,
    end_time: productInformationWithFormattedTimes.end_time,
    is_active: productInformationWithFormattedTimes.is_active,
    is_alcoholic: productInformationWithFormattedTimes.is_alcoholic,
    is_points_enabled: productInformationWithFormattedTimes.is_points_enabled,
    is_combo_only: productInformationWithFormattedTimes.is_combo_only,
    image_url: productInformationWithFormattedTimes.image_url,
    user_description: productInformationWithFormattedTimes.user_description,
    availabilities: productAvailabilities.map((products) => ({
      start_time:
        products.start_time &&
        products.start_time != NaN &&
        products.end_time != 'NaN' &&
        products.end_time != 'Invalid date'
          ? moment(products.start_time).format('HH:mm:ss')
          : '00:00:00',
      end_time:
        products.end_time &&
        products.end_time != NaN &&
        products.end_time != 'NaN' &&
        products.end_time != 'Invalid date'
          ? moment(products.end_time).format('HH:mm:ss')
          : '00:00:00',
      start_day_of_week: products.start_day_of_week,
      end_day_of_week: getEndDayOfWeek(products),
    })),
  };

  delete body.taxCategoryId;
  delete body.orderType;
  delete body.availabilities;
  delete body.productIsProductAvailableAllTime;
  delete body.id;

  const productAvailableOption = {
    name: productInformationWithFormattedTimes.name,
    start_time: productInformationWithFormattedTimes.start_time,
    end_time: productInformationWithFormattedTimes.end_time,
    is_active: productInformationWithFormattedTimes.is_active,
    is_alcoholic: productInformationWithFormattedTimes.is_alcoholic,
    is_points_enabled: productInformationWithFormattedTimes.is_points_enabled,
    is_combo_only: productInformationWithFormattedTimes.is_combo_only,
    user_description: productInformationWithFormattedTimes.user_description,
    availabilities: productAvailabilities.map((products) => ({
      start_time:
        products.start_time &&
        products.start_time != NaN &&
        products.end_time != 'NaN' &&
        products.end_time != 'Invalid date'
          ? moment(products.start_time).format('HH:mm:ss')
          : '00:00:00',
      end_time:
        products.end_time &&
        products.end_time != NaN &&
        products.end_time != 'NaN' &&
        products.end_time != 'Invalid date'
          ? moment(products.end_time).format('HH:mm:ss')
          : '00:00:00',
      start_day_of_week: products.start_day_of_week,
      end_day_of_week: getEndDayOfWeek(products),
    })),
  };
  const trimProductAvailable = [];
  productAvailableOption.availabilities.forEach((products) => {
    if (products.start_time && products.end_time !== '00:00:00') {
      trimProductAvailable.push(products);
    } else {
      return;
    }
  });

  productAvailableOption.availabilities = trimProductAvailable;
  return function (dispatch, getState) {
    let orderType = productInformationWithFormattedTimes.orderType;
    if (orderType.includes(ALL_ORDER_TYPE_ID)) {
      orderType = [];
    }
    dispatch({ type: PATCH_PRODUCT_REQUEST });
    Promise.all([
      api.patch(`/menu/products/${productId}`, body),
      api.put(`/menu/products/${productId}/order-types`, {
        order_types: orderType,
      }),
      api.patch(`/menu/products/${productId}`, productAvailableOption),
      //api.patch(`/menu/products/${productId}`, body)
    ])
      .then((responses) => {
        if (
          !getState().accountReducer.appMetaData.configuration
            .pos_integration_enabled
        ) {
          api.put(`/menu/products/${productId}/taxes`, {
            tax_category_id: productInformationWithFormattedTimes.taxCategoryId,
          });
        }
        dispatch(
          createAlert({
            type: 'success',
            message: 'Product information updated successfully',
          })
        );
        dispatch({
          type: PATCH_PRODUCT_SUCCESS,
          productInformationInitialValues: {
            availabilities: productAvailableOption.availabilities,
            is_points_enabled:
              productInformationWithFormattedTimes.is_points_enabled,
            name: productInformationWithFormattedTimes.name,
            user_description:
              productInformationWithFormattedTimes.user_description,
            image_url: productInformationWithFormattedTimes.image_url,
            start_time: productInformationWithFormattedTimes.start_time,
            end_time: productInformationWithFormattedTimes.end_time,
            orderType: productInformationWithFormattedTimes.orderType,
            taxCategoryId: productInformationWithFormattedTimes.taxCategoryId,
            is_combo_only: productInformationWithFormattedTimes.is_combo_only,
            is_alcoholic: productInformationWithFormattedTimes.is_alcoholic,
          },
        });
        dispatch({
          type: PATCH_PRODUCT_AVAILABILITIES,
          productAvailabilities: productAvailableOption.availabilities,
        });
      })
      .catch((error) => {
        dispatch({
          type: PATCH_PRODUCT_FAILURE,
        });
        dispatch(
          createAlert({
            type: 'error',
            message: `unexpected error. error code ${error.response.status}`,
          })
        );
        if (error.response.status === 500) {
          dispatch(
            createAlert({
              type: 'error',
              message: 'Product name is already taken',
            })
          );
        }
      });
  };
}

export function patchProductIsFeatured(is_featured) {
  return function (dispatch, getState) {
    const { productId } = getState().productsReducer;
    api
      .patch(`/menu/products/${productId}`, { is_featured })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_TOGGLES,
          productToggles: { is_featured },
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch(
            createAlert({
              type: 'error',
              message: 'Cannot make product featured without POS Mapping',
            })
          );
        }
      });
  };
}

export function patchProductIsAvailable(is_available) {
  return function (dispatch, getState) {
    const { productId } = getState().productsReducer;
    api
      .patch(`/menu/products/${productId}`, { is_available })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_TOGGLES,
          productToggles: { is_available },
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch(
            createAlert({
              type: 'error',
              message: 'Cannot make product available without POS Mapping',
            })
          );
        }
      });
  };
}

export function patchProductIsActive(is_active) {
  return function (dispatch, getState) {
    const { productId } = getState().productsReducer;
    api
      .patch(`/menu/products/${productId}`, { is_active })
      .then((response) => {
        dispatch({
          type: UPDATE_PRODUCT_TOGGLES,
          productToggles: { is_active },
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          dispatch(
            createAlert({
              type: 'error',
              message: 'Cannot make product active without POS Mapping',
            })
          );
        }
      });
  };
}

export function putProductCategories(formCategories) {
  return function (dispatch, getState) {
    const { productId } = getState().productsReducer;
    let categories = [];
    let i = 0;

    formCategories.forEach((category) => {
      if (category.subCategoriesId) {
        category.subCategoriesId.forEach((subCategoryId) => {
          categories = [
            ...categories,
            {
              category_id: subCategoryId,
              position: category.position ? category.position : 125,
            },
          ];
        });
      } else {
        categories = [
          ...categories,
          {
            category_id: category.id,
            position: category.position ? category.position : 125,
          },
        ];
      }
    });

    api
      .put(`/menu/products/${productId}/categories`, { categories })
      .then((response) => {
        dispatch({
          type: LINK_PRODUCT_WITH_CATEGORIES_SUCCESS,
          categoriesInitialValues: formCategories,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Product categories updated successfully',
          })
        );
      });
  };
}

export function updateProductVariantsInitialValues(
  productVariantsInitialValues
) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_PRODUCT_VARIANT_INITIAL_VALUES,
      productVariantsInitialValues,
    });
  };
}

export function putProductVariants(productVariants) {
  return function (dispatch, getState) {
    const appConfig = getState().accountReducer.appMetaData.configuration;
    let productId = getState().productsReducer.productId;
    let prices = productVariants.map((productVariant, index) => ({
      product_variant_id: productVariant.product_variant_id,
      price:
        typeof productVariant.price === 'number'
          ? productVariant.price
          : parseFloat(productVariant.price.replace('$', '')),
      is_active: productVariant.is_active,
      is_default: productVariant.is_default,
      position: index + 1,
    }));
    let upcs = productVariants
      .map((variant) => ({
        product_variant_id: variant.product_variant_id,
        upc: variant.barcode,
      }))
      .filter((upc) => upc.upc);

    let apis = [api.put(`/menu/products/${productId}/prices`, { prices })];
    if (
      getState().productsReducer.productInformationInitialValues.orderType.includes(
        SCAN_AND_GO
      )
    ) {
      apis = [...apis, api.put(`/menu/products/${productId}/upc`, { upcs })];
    }
    Promise.all(apis).then((responses) => {
      dispatch(getProductVariants(productId));
      dispatch(
        createAlert({
          type: 'success',
          message: 'Product variants updated successfully',
        })
      );
      if (appConfig.pos_integration_enabled) {
        dispatch(
          getProductPosMappingValues(
            productId,
            getState().posReducer.productsMappingFields
          )
        );
      }
    });
  };
}

export function putProductOptions(productOptions) {
  return function (dispatch, getState) {
    const { productId } = getState().productsReducer;
    productOptions = productOptions.map((option) => ({
      product_option_id: option.product_option_id,
      position: option.position,
    }));
    api
      .put(`/menu/products/${productId}/options`, { options: productOptions })
      .then((response) => {
        dispatch({
          type: PUT_PRODUCT_OPTIONS_SUCCESS,
          productOptionsInitialValues: productOptions,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Product options updated successfully',
          })
        );
      });
  };
}

export function getProductTags(productId) {
  return function (dispatch) {
    api.get(`/menu/products/${productId}/tags`).then((response) => {
      dispatch({
        type: GET_PRODUCT_TAGS_SUCCESS,
        productTagsInitialValues: response.data.data.map((tag) => tag.tag_id),
      });
    });
  };
}

export function putProductTags(productTags) {
  return function (dispatch, getState) {
    const { productId } = getState().productsReducer;
    api
      .put(`/menu/products/${productId}/tags`, { tags: productTags })
      .then((response) => {
        dispatch({
          type: PUT_PRODUCT_TAGS_SUCCESS,
          productTagsInitialValues: productTags,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Product tags updated successfully',
          })
        );
      });
  };
}

export function setProductPreviewFlatProduct(value) {
  return function (dispatch, getState) {
    if (value === null) {
      dispatch({
        type: SET_PRODUCT_PREVIEW_FLAT_PRODUCT,
        productCustomizerReducer: null,
      });
    } else {
      const {
        productInformation,
        productInformationInitialValues,
        productToggles,
        categoriesInitialValues,
        productVariantsInitialValues,
        productOptionsInitialValues,
        allOptions,
        categories,
      } = getState().productsReducer;
      const { allVariants } = getState().variantsReducer;
      const { items, options, sub_items, sub_options } =
        getState().optionReducer.entities;
      let flatProduct = {};

      Object.keys(items).forEach((key) => {
        items[key] = { ...items[key], item_id: items[key].id };
        if (items[key].sub_items && !items[key].sub_items.length) {
          delete items[key].sub_items;
        }
      });

      Object.keys(sub_items).forEach((key) => {
        if (sub_items[key].sub_items && !sub_items[key].sub_items.length) {
          sub_items[key] = { ...sub_items[key], item_id: sub_items[key].id };
          delete sub_items[key].sub_items;
        }
      });

      let {
        id,
        relationships,
        is_available,
        is_active,
        taxjar_code,
        ...productInfo
      } = productInformation;

      flatProduct = {
        ...flatProduct,
        ...productInfo,
        ...productInformationInitialValues,
        ...productToggles,
        prices: productVariantsInitialValues.map((variant) => ({
          variant_id: variant.product_variant_id,
          price: variant.price,
          is_default: variant.is_default,
          position: variant.position,
          name: allVariants.find(
            (globalVariant) => globalVariant.id === variant.product_variant_id
          ).name,
        })),
        price: productVariantsInitialValues.find(
          (variant) => variant.is_default
        ).price,
        variant_id: productVariantsInitialValues.find(
          (variant) => variant.is_default
        ).product_variant_id,
        variant_name: allVariants.find(
          (globalVariant) =>
            globalVariant.id ===
            productVariantsInitialValues.find((variant) => variant.is_default)
              .product_variant_id
        ).name,
        product_id: productInformation.id,
        quantity: 1,
        is_product_available: productToggles.is_available,
        options: productOptionsInitialValues.map(
          (option) => option.product_option_id
        ),
        itemsById: items,
        optionsById: options,
        subOptionsById: sub_options,
        subItemsById: sub_items,
        calories: productVariantsInitialValues.map((variant) => ({
          variant_id: variant.product_variant_id,
          min_calories: 240,
          max_calories: 240,
          calories_unit: '',
        })),
      };

      dispatch({
        type: SET_PRODUCT_PREVIEW_FLAT_PRODUCT,
        productCustomizerReducer: {
          flatProduct,
          isEditing: false,
          requestGetProductToConfigure: false,
          selectedVariantId: flatProduct.variant_id,
          singlePrice: flatProduct.price,
          totalPrice: flatProduct.price * flatProduct.quantity,
        },
      });
    }
  };
}

export function changeVariant({ productId, variantId, businessId }) {
  return function (dispatch, getState) {
    let { productCustomizerReducer } = getState().productsReducer;
    productCustomizerReducer = {
      ...productCustomizerReducer,
      selectedVariantId: variantId,
      flatProduct: {
        ...productCustomizerReducer.flatProduct,
        prices: productCustomizerReducer.flatProduct.prices.map((variant) =>
          variant.variant_id === variantId
            ? { ...variant, is_default: true }
            : { ...variant, is_default: false }
        ),
      },
      singlePrice: productCustomizerReducer.flatProduct.prices.find(
        (variant) => variant.variant_id === variantId
      ).price,
      totalPrice:
        productCustomizerReducer.flatProduct.prices.find(
          (variant) => variant.variant_id === variantId
        ).price * productCustomizerReducer.flatProduct.quantity,
    };
    dispatch({ type: CHANGE_VARIANT, productCustomizerReducer });
  };
}

export function incrementItem(item) {
  return {
    type: INCREMENT_ITEM,
    item,
  };
}

export function decrementItem(item) {
  return {
    type: DECREMENT_ITEM,
    item,
  };
}

export function incrementSubItem(subItem) {
  return {
    type: INCREMENT_SUB_ITEM,
    subItem,
  };
}

export function decrementSubItem(subItem, quantity) {
  return {
    type: DECREMENT_SUB_ITEM,
    subItem,
    quantity,
  };
}

const updateOptionError = (option, flatProduct) => {
  const { subOptionsById, itemsById, subItemsById } = flatProduct;
  let selectedItemQuantity = 0;

  const iterateItems = (items) => {
    items.forEach((itemId) => {
      const item = itemsById[itemId];
      if (item.sub_items && item.sub_items.length) {
        item.sub_items.forEach((subItemId) => {
          const subItem = subItemsById[subItemId];
          selectedItemQuantity += subItem.quantity;
        });
      } else {
        selectedItemQuantity += item.quantity;
      }
    });
  };

  if (option.sub_options && option.sub_options.length) {
    option.sub_options.forEach((subOptionId) => {
      const subOption = subOptionsById[subOptionId];
      iterateItems(subOption.items);
    });
  } else {
    iterateItems(option.items);
  }
  return { ...option, error: selectedItemQuantity < option.minimum_pick };
};

export function validateOption(option, flatProduct) {
  return function (dispatch) {
    dispatch({
      type: VALIDATE_OPTION,
      validatedOption: updateOptionError(option, flatProduct),
    });
  };
}

export function resetProductReducerState() {
  return {
    type: RESET_PRODUCT_REDUCER_STATE,
  };
}

export function updateProductsRequestParams(requestParams) {
  return {
    type: 'UPDATE_PRODUCTS_REQUEST_PARAMS',
    payload: requestParams,
  };
}

export function updateSortedProductsList(productsList, sortOrder) {
  return {
    type: UPDATE_SORTED_PRODUCTS_LIST,
    allProducts: productsList,
    sortOrder: sortOrder,
  };
}
