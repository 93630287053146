import React from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { LoadingBlueLarge } from 'merchant-portal-components';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';

const Wrapper = styled.div``;

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const TopRowTableCard = styled.div`
  margin: 15px 15px 0px;
`;

const ProductImage = styled.img`
  width: 100px;
  max-height: 64px;
  min-height: 64px;
  margin: 5px 0px;
  vertical-align: middle;
  object-fit: contain;
  background-color: transparent;
`;

const ProductList = ({
  products,
  initiateEditProductWizard,
  toggleProductAvailability,
  requestParams,
  allProductsLength,
  getProducts,
}) => {
  const renderRows = () => {
    return products.map((product, index) => {
      return (
        <Tr
          style={{
            cursor: 'pointer',
            height: '78px',
          }}
          onClick={() => initiateEditProductWizard(product)}
          key={product.id}
        >
          <Td padding={'0px 0px 0px 55px'} align="left">
            <ProductImage
              src={product.image_url}
              alt={product.name}
              height="78px"
            />
          </Td>
          <Td padding={'5px 0px 5px 20px'} align="left" bold width="70%">
            {product.name}
          </Td>
          <Td
            align="right"
            padding={'5px 50px 5px 0px'}
            onClick={(event) => {
              event.stopPropagation();
            }}
            width="20%"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Switch
                checked={product.is_active}
                onClick={(e) => e.stopPropagation()}
                onChange={() => toggleProductAvailability(product)}
                value="checkedB"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </Td>
        </Tr>
      );
    });
  };

  const renderHeaders = () => {
    return (
      <TrHead>
        <Th align="left" width="50" />
        <Th
          align="left"
          // onClick={() => {
          //   handleSort({
          //     value: 'name',
          //     sortMethod: sortByTypes,
          //     sortType: STRING,
          //   });
          // }}
          value="name"
          sortingOption={requestParams.sorting_option}
          isSortable={false}
        >
          Product Name
        </Th>
        <Th
          align="right"
          value="is_active"
          sortingOption={requestParams.sorting_option}
          isSortable={false}
          padding={'5px 55px 5px 0px'}
          style={{ padding: '0 55px' }}
        >
          Active
        </Th>
      </TrHead>
    );
  };

  return (
    <Wrapper>
      <TableWithButtonRow>
        <TableWrapper>
          <Table noBorder>
            <thead>{renderHeaders()}</thead>
            {/* <div
              style={{
                width: '100%',
                backgroundColor: 'yellow',
              }}
            > */}
            {products && (
              <TBody striped padding="0 37px">
                {renderRows()}
              </TBody>
            )}
            {/* </div> */}
          </Table>
        </TableWrapper>
        {!products && <LoadingBlueLarge />}
      </TableWithButtonRow>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...products,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: allProductsLength,
          }}
          requestParams={requestParams}
          updateAction={getProducts}
          type="products"
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default ProductList;
