import React, { Fragment } from 'react';
import styled from 'styled-components';
import ComboInformationFormContainer from './ComboInformationForm/containers/ComboInformationFormContainer';
import ComboItemsContainer from './ComboItems/containers/ComboItemsContainer';
import ComboAvailabilityContainer from './ComboAvailability/containers/ComboAvailabilityContainer';

const ContentBody = styled.div`
  margin: auto;
  display: grid;
  grid-gap: 24px;
`;

const CombosDetailIndex = ({ initValues }) => {
  return (
    <Fragment>
      <ContentBody>
        <ComboInformationFormContainer initValues={initValues} />
        <ComboItemsContainer initValues={initValues} />
        <ComboAvailabilityContainer initValues={initValues} />
      </ContentBody>
    </Fragment>
  );
};
export default CombosDetailIndex;
