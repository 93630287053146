import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import NameAndNotesForm from './components/NameAndNotesForm';
import BusinessAssigned from './components/BusinessAssigned';
import AppFeedContainer from '../../Appfeed/AppFeedContainer';
import StickyButtons from '../../../components/StickyButtons';
import { ChevronLeftIcon } from 'merchant-portal-components';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  width: 1000px;
  margin: 0 auto;
`;
const Card = styled.div`
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 32px;
  background: white;
  display: grid;
  grid-gap: 24px;
`;

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 150px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 20px;
  &: hover {
    color: #3051d4;
  }
`;

const AppFeedLayoutForm = ({
  createAppFeedLayout,
  setPageType,
  formInitialData,
  id,
  updateAppFeed,
  listAppFeed,
}) => {
  const [reduxReset, setReduxReset] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BackButton onClick={() => setPageType()}>
        <ChevronLeftIcon />
        <p>Back To App Feed</p>
      </BackButton>
      <Wrapper>
        <BreadCrumb
          pageName={
            formInitialData ? 'Edit App Feed Layout' : 'Create App Feed Layout'
          }
        />
        <Formik
          initialValues={
            !formInitialData
              ? { is_default: false, template_name: '', notes: '' }
              : {
                  is_default: formInitialData.data.is_default,
                  template_name: formInitialData.data.template_name,
                  notes: formInitialData.data.notes,
                }
          }
          enableReinitialize={true}
        >
          {({
            dirty,
            values,
            setValues,
            errors,
            setFieldValue,
            handleReset,
          }) => (
            <Fragment>
              <NameAndNotesForm />
              <AppFeedContainer
                formInitialData={formInitialData}
                itemId={id}
                reduxReset={reduxReset}
                setReduxReset={(value) => setReduxReset(value)}
                valueChanged={(v) => setValueChanged(v)}
              />
              {formInitialData ? (
                <BusinessAssigned
                  listAppFeed={listAppFeed}
                  id={id}
                ></BusinessAssigned>
              ) : null}

              <StickyButtons
                isVisible={
                  formInitialData
                    ? dirty || valueChanged
                    : dirty || (dirty && valueChanged)
                }
                handleDiscard={() => {
                  handleReset();
                  setReduxReset(true);
                  setValueChanged(false);
                }}
                handleSave={() =>
                  formInitialData == null
                    ? createAppFeedLayout(values)
                    : updateAppFeed(values, id)
                }
                title="App Feed Layout Management"
                saveIsDisabled={Object.keys(errors).length}
              />
            </Fragment>
          )}
        </Formik>
      </Wrapper>
    </div>
  );
};

export default AppFeedLayoutForm;
