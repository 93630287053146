import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon } from 'merchant-portal-components';
import BusinessInformationFormContainer from '../BusinessInformationForm/BusinessInformationFormContainer';
import AddressAndDetailsContainer from '../AddressAndDetails/AddressAndDetailsContainer';
import HoursContainer from '../Hours/HoursContainer';
import DeliveryZonesContainer from '../DeliveryZoneInformation/DeliveryZonesContainer';
import PaymentIntegrationContainer from '../PaymentIntegration/PaymentIntegrationContainer';
import { LoadingBlueLarge } from 'merchant-portal-components';

const ContentBody = styled.div`
  margin: auto;
  display: grid;
  grid-gap: 24px;
`;

const BreadCrumbWrapper = styled.div`
  color: ${(props) => props.theme.darkBlue};
  margin: 30px;
  margin-left: 0;
  @media print {
    display: none;
  }
`;

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 200px;
  grid-gap: 10px;
  align-items: center;

  &: hover {
    color: #3051d4;
  }
`;

const PageHeader = styled.h2`
  font-weight: 700;
  font-size: 36px;
`;

const BusinessWizardIndex = ({
  isCreatingLocation,
  resetBusinessLocationDetailsReducerState,
  resetDeliveryZoneReducerState,
  shouldComponentBeLoading,
  is_delivery_enabled,
}) => {
  return (
    <Fragment>
      <BreadCrumbWrapper>
        <BackButton
          onClick={() => {
            resetBusinessLocationDetailsReducerState();
            resetDeliveryZoneReducerState();
          }}
        >
          <ChevronLeftIcon />
          <div>Back to Location List</div>
        </BackButton>
        <PageHeader>
          {isCreatingLocation ? `Add Location` : `Edit Location`}
        </PageHeader>
      </BreadCrumbWrapper>
      {!shouldComponentBeLoading ? (
        <ContentBody>
          <BusinessInformationFormContainer />
          <AddressAndDetailsContainer />
          <HoursContainer />
          {is_delivery_enabled && (
            <DeliveryZonesContainer isCreatingLocation={isCreatingLocation} />
          )}
          <PaymentIntegrationContainer />
        </ContentBody>
      ) : (
        <LoadingBlueLarge />
      )}
    </Fragment>
  );
};
export default BusinessWizardIndex;
