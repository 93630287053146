import React, { Fragment } from "react";
import styled from "styled-components";
import { Formik, Field } from "formik";
import {
  RenderTextField,
  maxLengthValidationForNameField,
  bulkValidator,
  validateTwoChar,
} from "../../../../../components/FormElements/FormikElements";
import { SmallToggleSwitch } from "../../../../../components/ToggleSwitch/ToggleSwitch";
import StickyButtons from "../../../../../components/StickyButtons";

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 83px 0 83px 64px;
  background: white;
  display: grid;
  grid-gap: 48px;
`;

const VariantDetails = ({
  variantToEdit,
  createVariant,
  editVariant,
  variantIsSaving,
  business_id,
}) => {
  return (
    <Formik
      initialValues={
        !variantToEdit
          ? {
              name: "",
              label: "",
              is_active: true,
              business_id: business_id,
            }
          : {
              id: variantToEdit.id,
              name: variantToEdit.name,
              label: variantToEdit.label || "",
              is_active: variantToEdit.is_active,
            }
      }
      enableReinitialize={true}
    >
      {(props) => (
        <Fragment>
          <Card>
            <div style={{ width: "400px" }}>
              <Field
                name="name"
                component={RenderTextField}
                label="Variant Name*"
                validate={bulkValidator(
                  validateTwoChar,
                  maxLengthValidationForNameField
                )}
              />
            </div>

            <div style={{ width: "400px", marginBottom: "40px" }}>
              <Field
                name="label"
                component={RenderTextField}
                label="Internal Label*"
                validate={bulkValidator(
                  validateTwoChar,
                  maxLengthValidationForNameField
                )}
              />
            </div>
            <SmallToggleSwitch
              label="Active"
              name="is_active"
              checked={props.values.is_active}
              onChange={(e) =>
                props.setFieldValue("is_active", e.target.checked)
              }
            />
          </Card>
          <StickyButtons
            isVisible={props.dirty}
            handleDiscard={() => {
              props.handleReset();
            }}
            handleSave={() => {
              variantToEdit
                ? editVariant(
                    props.values,
                    !(
                      variantToEdit.label === props.values.label &&
                      variantToEdit.name === props.values.name
                    )
                  )
                : createVariant(props.values);
            }}
            saveIsDisabled={!props.isValid || variantIsSaving}
            discardIsDisabled={variantIsSaving}
            title="Variant Management"
          />
        </Fragment>
      )}
    </Formik>
  );
};

export default VariantDetails;
