import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './categoryActions';
import CategoryListContainer from './CategoryList/CategoryListContainer';
import CategoryDetailContainer from './CategoryDetail/CategoryDetailContainer';

const CategoryContainer = ({
  currentPage,
  getCategories,
  requestParams,
  loadCreateCategoryPage,
  updateCategoriesRequestParams,
  history,
}) => {
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    return () => {
      updateCategoriesRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getCategories(newRequestParams);
      }
      updateCategoriesRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, currentPage]);
  if (currentPage === 'list') {
    return (
      <CategoryListContainer
        history={history}
        handleCreateButtonClick={() => loadCreateCategoryPage()}
        searchString={searchString}
        setSearchString={setSearchString}
      />
    );
  } else if (currentPage === 'create') {
    return <CategoryDetailContainer history={history} />;
  } else if (currentPage === 'edit') {
    return <CategoryDetailContainer isEdit={true} history={history} />;
  }
};

const mapStateToProps = (state) => ({
  currentPage: state.categoryReducer.currentPage,
  requestParams: state.categoryReducer.requestParams,
});

export default connect(mapStateToProps, actions)(CategoryContainer);
