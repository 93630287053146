import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Collapse } from '@material-ui/core';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import { Button, ButtonRow } from 'merchant-portal-components';
import ConfirmCancelModal from '../../../../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';
import StoreHoursAndOrderAheadHours from './StoreHoursAndOrderAheadHours';
import SpecialHours from '../components/SpecialHours';

const Card = styled.div`
  background-color: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  color: ${(props) => (props.disabled ? '#191818' : 'inherit')};
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;
  padding-right: 20px;
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: 20px 30px 20px 40px;
  margin: 10px;
`;

const Title = styled.div`
  opacity: ${(props) => (props.disabled ? '0.5' : 'none')};
  font-weight: 600;
  font-size: 21px;
`;

const FormWrapper = styled.div`
  padding: 0px 0px 20px 48px;
  display: grid;
  grid-gap: 20px;
`;

const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
  align-items: center;
`;

const HoursForm = ({
  formValues,
  timezone,
  handleHourIsOpenChange,
  handleCloseSpecialHoursForm,
  handleSpecialHoursDeleteIconClick,
  handleSpecialHoursCancelClick,
  handleOpenSpecialHoursForm,
  handleSpecialHoursModalConfirmClick,
  handleSpecialHoursFormSubmit,
}) => {
  return (
    <Fragment>
      <FormWrapper>
        <StoreHoursAndOrderAheadHours
          storeHours={formValues.regularHours}
          handleHourIsOpenChange={handleHourIsOpenChange}
          formFieldName="regularHours"
        />
        {formValues && (
          <SpecialHours
            formValues={formValues}
            timezone={timezone}
            handleOpenSpecialHoursForm={handleOpenSpecialHoursForm}
            handleSpecialHoursDeleteIconClick={
              handleSpecialHoursDeleteIconClick
            }
            isDeleteSpecialHoursConfirmModalOpen={
              formValues.isDeleteSpecialHoursConfirmModalOpen
            }
            handleSpecialHoursCancelClick={handleSpecialHoursCancelClick}
            handleSpecialHoursModalConfirmClick={
              handleSpecialHoursModalConfirmClick
            }
            isDeleteSpecialHoursRequesting={
              formValues.isDeleteSpecialHoursRequesting
            }
            isCreateAndEditSpecialHoursModalOpen={
              formValues.isCreateAndEditSpecialHoursModalOpen
            }
            handleCloseSpecialHoursForm={handleCloseSpecialHoursForm}
            specialHoursToEdit={formValues.specialHoursToEdit}
            handleSpecialHoursFormSubmit={handleSpecialHoursFormSubmit}
            businessLocationId={formValues.businessId}
            isFormSubmitting={formValues.isFormSubmitting}
            locationName={
              formValues.addressInformationInitialValues.display_name
            }
          />
        )}
      </FormWrapper>
    </Fragment>
  );
};

export default HoursForm;
