import React, { Fragment, lazy } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../variantsActions';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { Button } from 'merchant-portal-components';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Icon } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import VariantList from './components/VariantList';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  display: grid;
  grid-gap: 20px;
  padding: 37px 0;
`;
const MessageWrapper = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const TextSearch = styled.div``;
const BackText = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;
const LocationText = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 0 37px 37px 37px;
`;

let VariantListPage = ({
  allVariants,
  requestParams,
  isGetVariantRequest,
  changeVariantCurrentPage,
  toggleIsActive,
  isVariantAvailable,
  setEditVariant,
  variantIsSaving,
  getVariants,
  selectedMenuManagementChildLocation,
  history,
  searchString,
  setSearchString,
}) => {
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };

  const handleClearSearch = () => {
    setSearchString('');
  };
  return (
    <Fragment>
      <BackText onClick={() => history.push('/menu-management')}>
        <i className="fa fa-chevron-left" />
        <div> Back to Menu Management</div>
      </BackText>

      {Object.keys(selectedMenuManagementChildLocation).length !== 0 && (
        <LocationText>
          <i className="fal fa-store-alt" />
          <div> {selectedMenuManagementChildLocation.display_name}</div>
        </LocationText>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '11fr 3fr',
          alignItems: 'center',
        }}
      >
        <BreadCrumb />
      </div>
      <Wrapper>
        <TopDiv>
          <TextSearch>
            <TextField
              type="text"
              style={{ width: '100%' }}
              variant="outlined"
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="fal fa-search" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      className="fas fa-times-circle"
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="Search variants"
            />
          </TextSearch>
          <Button
            style={{ width: '100%' }}
            onClick={() => changeVariantCurrentPage('create')}
          >
            Create Variant
          </Button>
        </TopDiv>
        {!isGetVariantRequest && allVariants.length > 0 ? (
          <VariantList
            variantIsSaving={variantIsSaving}
            allVariants={allVariants}
            requestParams={requestParams}
            isGetVariantRequest={isGetVariantRequest}
            toggleIsActive={toggleIsActive}
            setEditVariant={setEditVariant}
            isVariantAvailable={isVariantAvailable}
            searchString={searchString}
            getVariants={getVariants}
          />
        ) : allVariants.length === 0 && !isGetVariantRequest ? (
          <MessageWrapper>No variants found.</MessageWrapper>
        ) : (
          <LoadingBlueLarge />
        )}
      </Wrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  allVariants: state.variantsReducer.allVariants,
  requestParams: state.variantsReducer.requestParams,
  isGetVariantRequest: state.variantsReducer.isGetVariantRequest,
  isVariantAvailable: state.variantsReducer.isVariantAvailable,
  variantIsSaving: state.variantsReducer.variantIsSaving,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});
export default connect(mapStateToProps, actions)(VariantListPage);
