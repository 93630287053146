import styled from 'styled-components';
import { ButtonRow } from 'merchant-portal-components';

export const Wrapper = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding-top: 24px;
  padding-left: 48px;
`;

export const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

export const IsActiveToggleWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const AvailableToggleSwitchWrapper = styled.div`
  margin-top: 27px;
  margin-bottom: 30px;
`;

export const LocationsAvailableWrapper = styled.div`
  margin: 24px 0;
`;

export const ExpireWrapper = styled(LocationsAvailableWrapper)`
  & p {
    padding-top: 10px;
    letter-spacing: 0.01px;
    color: #191818;
  }
`;

export const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const SingleDatePickerWrapper = styled.div``;

export const BrowseLocationsButton = styled.button`
  background-color: none;
  border: none;
  color: #2440ae;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
`;

export const AvailabilityButton = styled.button`
  background-color: #efefef;
  border-radius: 8px;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
`;

export const Title = styled.div`
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.03px;
  // color: #191818;
`;

export const ButtonWrapper = styled(ButtonRow)`
  display: flex;
  justify-content: flex-end;
  padding: 10px 48px 48px 48px;
`;

export const AvailableLocationsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  align-items: center;
`;
