import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import Card from '../../../../../components/Card/Card';
import {
  RenderTextField,
  required,
  RenderSelect,
  RenderToggleSwitch,
  RenderSingleDatePicker,
} from '../../../../../components/FormElements/FormikElements';
import { HrLine, StyledLabel } from 'merchant-portal-components';
import moment from 'moment';

const FieldWrapper = styled.div`
  grid-column: span ${(props) => props.col};
`;

const RewardOptionsForm = ({
  values,
  programType,
  setFieldValue,
  allBusinesses,
  setIsEditingCustomers,
}) => {
  return (
    <Fragment>
      <FieldWrapper col={4}>
        <StyledLabel>Reward Options</StyledLabel>
      </FieldWrapper>
      <FieldWrapper col={1}>
        <Field
          name="coupon_details.template_values.type"
          component={RenderSelect}
          style={{ position: 'relative', top: '-4px' }}
          options={
            programType === 'points'
              ? [
                  { value: 'money', label: 'Fixed Dollar Credit' },
                  {
                    value: 'points',
                    label: 'Fixed Loyalty Credit (points)',
                  },
                ]
              : [{ value: 'money', label: 'Fixed Dollar Credit' }]
          }
          placeholder="Type Of Reward *"
          validate={required}
          onChange={() => {
            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      <FieldWrapper col={1}>
        <Field
          name="coupon_details.template_values.total_amount"
          label="Reward Amount *"
          component={RenderTextField}
          type="number"
          min="0"
          validate={required}
          onChange={() => {
            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      <FieldWrapper style={{ gridColumn: '1/ span 1', alignSelf: 'center' }}>
        <Field
          checked={values.unlimitedRedemption}
          label="Unlimited Redemption"
          component={RenderToggleSwitch}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue(
                'coupon_details.template_values.limit_redemption',
                null
              );
              setFieldValue('unlimitedRedemption', true);
              setIsEditingCustomers(true);
            } else {
              setFieldValue(
                'coupon_details.template_values.limit_redemption',
                ''
              );
              setFieldValue('unlimitedRedemption', false);
              setIsEditingCustomers(true);
            }
          }}
        />
      </FieldWrapper>
      {!values.unlimitedRedemption && (
        <FieldWrapper col={2}>
          <Field
            name="coupon_details.template_values.limit_redemption"
            label="Limit Number"
            component={RenderTextField}
            type="number"
            min="0"
            onChange={() => {
              setIsEditingCustomers(true);
            }}
            validate={() =>
              !values.unlimitedRedemption &&
              typeof values.coupon_details.template_values.limit_redemption ==
                'string'
                ? 'Required'
                : null
            }
          />
        </FieldWrapper>
      )}
      <FieldWrapper style={{ gridColumn: '1/ span 1', alignSelf: 'center' }}>
        <Field
          checked={values.businesses.length > 0 ? false : true}
          label="All Locations"
          component={RenderToggleSwitch}
          onChange={(e) => {
            e.target.checked
              ? setFieldValue('businesses', [])
              : setFieldValue('businesses', 'Select Business');
            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      {values.businesses.length > 0 && (
        <FieldWrapper col={2}>
          <Field
            isMulti
            name="businesses"
            placeholder="Select Business(es)"
            component={RenderSelect}
            getOptionValue={(business) => business.id}
            getOptionLabel={(business) => business.display_name}
            valueKey="id"
            options={allBusinesses ? allBusinesses : []}
            onChange={() => {
              setIsEditingCustomers(true);
            }}
          />
        </FieldWrapper>
      )}
      <FieldWrapper style={{ gridColumn: '1/ span 1', alignSelf: 'center' }}>
        <Field
          checked={values.coupon_details.template_name == 'unlimited-time'}
          label="Unlimited Time"
          component={RenderToggleSwitch}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue('coupon_details.template_name', 'unlimited-time');
              setFieldValue('coupon_details.template_values.start_date', null);
              setFieldValue('coupon_details.template_values.end_date', null);
            } else {
              setFieldValue('coupon_details.template_name', 'limited-time');

              setFieldValue(
                'coupon_details.template_values.start_date',
                moment().format('YYYY-MM-DD')
              );
              setFieldValue(
                'coupon_details.template_values.end_date',
                moment().add(1, 'days').format('YYYY-MM-DD')
              );
            }

            setIsEditingCustomers(true);
          }}
        />
      </FieldWrapper>
      {values.coupon_details.template_name != 'unlimited-time' && (
        <Fragment>
          <FieldWrapper col={1}>
            <Field
              label="Start Date *"
              name="coupon_details.template_values.start_date"
              component={RenderSingleDatePicker}
              onChange={() => {
                // setIsEditingCustomers(true);
              }}
            />
          </FieldWrapper>
          <FieldWrapper col={1}>
            <Field
              label="End Date *"
              name="coupon_details.template_values.end_date"
              component={RenderSingleDatePicker}
              onChange={() => {
                // setIsEditingCustomers(true);
              }}
            />
          </FieldWrapper>
        </Fragment>
      )}
      <FieldWrapper col={4}>
        <HrLine />
      </FieldWrapper>
    </Fragment>
  );
};
export default RewardOptionsForm;
