import React from 'react';

import { TrHead, Th, SortIcon, StickyTh } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';

function ReportTableHeader(props) {
  const {
    columns,
    checkBox,
    ignoreColumns,
    requestParams,
    columnNames,
    useOldSort,
    notSortColumns,
  } = props;

  const renderSortIcon = (option) => {
    const sortingOptionAsArray = `${requestParams.sort_by}-${requestParams.sort_direction}`.split(
      '-'
    );
    const iconNames = { desc: 'down', asc: 'up' };
    if (sortingOptionAsArray[0] === option) {
      return (
        <Tooltip title="Sort" className={option}>
          <SortIcon
            className={`fa fa-sort-${iconNames[sortingOptionAsArray[1]]}`}
            aria-hidden="true"
            onClick={() => sortBy(option)}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Sort" className={option}>
        <SortIcon
          className="fa fa-sort"
          aria-hidden="true"
          onClick={() => sortBy(option)}
        />
      </Tooltip>
    );
  };

  const sortBy = (option) => {
    let payload;
    const sortDirection =
      requestParams.sort_direction === 'asc' ? 'desc' : 'asc';

    if (useOldSort) {
      payload = {
        ...requestParams,
        sorting_option: `${option}-${sortDirection}`,
      };
    } else {
      payload = {
        ...requestParams,
        sort_by: option,
        sort_direction: sortDirection,
      };
    }

    props.updateRequestParams({ ...payload });
  };

  const Columns = () => {
    const tableHeader = [];
    let i = -1;

    if (columns.length > 0) {
      tableHeader.push(
        columns.map((column, index) =>
          index === 0 ? (
            !ignoreColumns.includes(column) ? (
              <StickyTh
                align="left"
                onClick={() => sortBy(column)}
                style={{ minWidth: '138px', borderLeft: 'none' }}
              >
                {columnNames.length > 0 ? columnNames[++i] : column}
                {renderSortIcon(column)}
              </StickyTh>
            ) : null
          ) : !ignoreColumns.includes(column) ? (
            <Th
              align="center"
              textTransform="capitalize"
              //onClick={() => sortBy(column)}
            >
              {columnNames.length > 0 ? columnNames[++i] : column}
              {notSortColumns
                ? !notSortColumns.includes(column)
                  ? renderSortIcon(column)
                  : null
                : renderSortIcon(column)}
            </Th>
          ) : null
        )
      );
    } else if (columnNames.length > 0) {
      tableHeader.push(
        columns.map((column, index) =>
          index === 0 ? (
            !ignoreColumns.includes(column) ? (
              <StickyTh
                align="left"
                onClick={() => sortBy(column)}
                style={{ minWidth: '138px', borderLeft: 'none' }}
              >
                {columnNames[index]}
              </StickyTh>
            ) : null
          ) : !ignoreColumns.includes(column) ? (
            <Th align="center" textTransform="capitalize">
              {columnNames[index]}
            </Th>
          ) : null
        )
      );
    }
    return tableHeader;
  };

  return (
    <TrHead style={{ background: 'none' }}>
      {checkBox ? (
        <Th align="left">
          <Tooltip title="Select All">
            <Checkbox color="primary" />
          </Tooltip>
        </Th>
      ) : null}
      <Columns />
      <Th />
    </TrHead>
  );
}

export default ReportTableHeader;
