import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getCategories,
  toggleCategory,
  initiateEditCategory,
  sortCategories,
  updateCategoriesRequestParams,
  updateSortedCategoriesList,
} from '../categoryActions';
import CategoryList from './components/CategoryList';
import { LoadingBlueLarge, Icon, Button } from 'merchant-portal-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import CategoriesSortModal from './CategorySort/CategoriesSortModal';
import _ from 'lodash';
import { TextField, InputAdornment } from '@material-ui/core';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 37px 0;
`;

const MessageWrapper = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const TextSearch = styled.div``;
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  margin: 0 37px 37px 37px;
`;
const BackText = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;
const LocationText = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;
let CategoryListContainer = ({
  categories,
  getCategories,
  toggleCategory,
  isRequestingGetCategories,
  handleCreateButtonClick,
  initiateEditCategory,
  sortCategories,
  isSortingRequested,
  requestParams,
  searchString,
  setSearchString,
  selectedMenuManagementChildLocation,
  history,
}) => {
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);

  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };

  const handleClearSearch = () => {
    setSearchString('');
  };
  return (
    <Fragment>
      <BackText onClick={() => history.push('/menu-management')}>
        <i className="fa fa-chevron-left" />
        <div> Back to Menu Management</div>
      </BackText>
      {Object.keys(selectedMenuManagementChildLocation).length !== 0 && (
        <LocationText>
          <i className="fal fa-store-alt" />
          <div> {selectedMenuManagementChildLocation.display_name}</div>
        </LocationText>
      )}
      <BreadCrumb />
      <Wrapper>
        <TopDiv>
          <TextSearch>
            <TextField
              type="text"
              style={{ width: '100%' }}
              variant="outlined"
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="fal fa-search" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      className="fas fa-times-circle"
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="Search categories"
            />
          </TextSearch>
          <Button
            onClick={() => handleCreateButtonClick()}
            style={{ width: '100%' }}
          >
            Create a Category
          </Button>
        </TopDiv>
        {!isRequestingGetCategories && categories.length > 0 ? (
          <CategoryList
            categories={categories}
            toggleCategory={toggleCategory}
            initiateEditCategory={initiateEditCategory}
            requestParams={requestParams}
            setIsSortModalOpen={setIsSortModalOpen}
            getCategories={getCategories}
          />
        ) : categories.length === 0 && !isRequestingGetCategories ? (
          <MessageWrapper>No categories found.</MessageWrapper>
        ) : (
          <LoadingBlueLarge />
        )}
        {isSortModalOpen && (
          <CategoriesSortModal
            categories={categories}
            setIsSortModalOpen={setIsSortModalOpen}
            sortCategories={sortCategories}
            isSortingRequested={isSortingRequested}
            formTypeName="categories"
            formHeadName="category"
          />
        )}
      </Wrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  categories: state.categoryReducer.allCategories,
  isRequestingGetCategories: state.categoryReducer.isRequestingGetCategories,
  isSortingRequested: state.categoryReducer.isSortingRequested,
  requestParams: state.categoryReducer.requestParams,
  allInitialCategories: state.categoryReducer.allInitialCategories,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});

export default connect(mapStateToProps, {
  getCategories,
  toggleCategory,
  initiateEditCategory,
  sortCategories,
  updateCategoriesRequestParams,
  updateSortedCategoriesList,
})(CategoryListContainer);
