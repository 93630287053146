import React from 'react';
import styled from 'styled-components';
import AccountInformationContainer from '../containers/AccountInformationContainer';
import CustomerLoyaltyRewardsContainer from '../containers/CustomerLoyaltyRewardsContainer';
import CustomerCreditsContainer from '../containers/CustomerCreditsContainer';
import { Button } from 'merchant-portal-components';
import { ComponentCard } from 'merchant-portal-components';

const ButtonWrapper = styled.div`
  margin: 50px 37px 0 37px;
`;

let CustomerDetails = (props) => {
  const { customerId, handleBackButtonClick, loyaltyIsVisible } = props;

  return (
    <ComponentCard>
      <AccountInformationContainer customerId={customerId} />
      {loyaltyIsVisible ? (
        <CustomerLoyaltyRewardsContainer customerId={customerId} />
      ) : null}
      <CustomerCreditsContainer customerId={customerId} />
      <ButtonWrapper>
        <Button secondary onClick={handleBackButtonClick}>
          Back
        </Button>
      </ButtonWrapper>
    </ComponentCard>
  );
};

export default CustomerDetails;
