import React, { Fragment } from 'react';
import styled from 'styled-components';
import BasicTransactionInfo from './BasicOrderInfo';
import ProductsOrdered from './ProductsOrdered';
import TransactionHistoryTable from './TransactionHistoryTable';
import MerchantActions from './MerchantActions';
import CancelTransactionModal from './modals/CancelTransactionModal';
import SendLoyaltyModal from './modals/SendLoyaltyModal';
import { LoadingBlueLarge } from 'merchant-portal-components';
import NoDataFound from '../../../../components/NoDataFound/NoDataFound';
import {
  ContainerDiv,
  HorizontalLine,
  WrapperLoyaltyPoints,
  GridWrapper,
  StyledP,
} from '../utils/commonStyledComponents';
import { TableWrapper } from 'merchant-portal-components';

export const OrderDetails = (props) => {
  const {
    requestingOrderDetails,
    orderDetails,
    isCancelOrderModalVisible,
    handleVisibilityCancelOrderModal,
    handleOrderCancel,
    handleIsOrderCancelBoxChecked,
    isOrderCancelBoxChecked,
    isSendLoyaltyModalVisible,
    handleVisibilitySendLoyaltyModal,
    handleSendLoyaltySubmit,
    requestingCancelOrderDetails,
    getTypesOfRewardOptions,
    orderId,
    requestingSendLoyaltyPointsByCustomerId,
  } = props;

  if (requestingOrderDetails) {
    return (
      <div>
        <LoadingBlueLarge data-test="component-LoadingBlueLarge" />
      </div>
    );
  } else if (!orderDetails) {
    return (
      <div>
        <NoDataFound
          data-test="component-NoDataFound"
          rootItem={{
            pathname: '/order-history',
            name: 'Order History',
          }}
        />
      </div>
    );
  }
  return (
    <TableWrapper data-test="component-OrderDetails">
      <ContainerDiv padding="30px 30px 10px 30px">
        <BasicTransactionInfo
          data-test="component-BasicTransactionInfo"
          customerName={orderDetails.customerName}
          customerEmail={orderDetails.customerEmail}
          orderDate={orderDetails.orderDate}
          orderTime={orderDetails.orderTime}
          orderType={orderDetails.orderType}
          orderingFrom={orderDetails.orderingFrom}
          businessAddress={orderDetails.businessAddress}
          businessName={orderDetails.businessName}
          orderId={orderId}
        />

        <HorizontalLine />

        {orderDetails.products && (
          <Fragment>
            <ProductsOrdered
              data-test="component-ProductsOrdered"
              products={orderDetails.products}
            />
            <ContainerDiv width="100%" margin="30px 0 0 0">
              <HorizontalLine />
            </ContainerDiv>
          </Fragment>
        )}

        <TransactionHistoryTable
          data-test="component-TransactionHistory"
          transactions={orderDetails.transactions}
          subtotal={orderDetails.subtotal}
          discount={orderDetails.discount}
          loyalty={orderDetails.loyalty}
          tip={orderDetails.tip}
          tax={orderDetails.tax}
          creditsUsed={orderDetails.creditsUsed}
          total={orderDetails.total}
          additionalFees={orderDetails.additionalFees}
        />

        {getTypesOfRewardOptions().find(
          (reward) => reward.value === 'points'
        ) && (
          <ContainerDiv width="100%" margin="30px 0 0 0">
            <WrapperLoyaltyPoints>
              <GridWrapper
                templateColumns="1fr auto"
                amountOfTransactionDetail
                collapseGap
                gap="0px"
                padding="0 10px"
              >
                <StyledP collapseRight>Earned Loyalty Points :</StyledP>
                <StyledP data-test="earnedPoinds" semiBold collapseLeft>
                  {orderDetails.loyalty.amount_earned} pts
                </StyledP>
              </GridWrapper>
            </WrapperLoyaltyPoints>
            <WrapperLoyaltyPoints>
              <GridWrapper
                templateColumns="1fr auto"
                amountOfTransactionDetail
                collapseGap
                gap="0px"
                padding="0 10px"
              >
                <StyledP collapseRight>Redeemed Loyalty Points :</StyledP>
                <StyledP data-test="redeemedPoints" semiBold collapseLeft>
                  {orderDetails.loyalty.amount_redeemed} pts
                </StyledP>
              </GridWrapper>
            </WrapperLoyaltyPoints>
          </ContainerDiv>
        )}

        <ContainerDiv width="100%" margin="30px 0 0 0">
          <HorizontalLine />
        </ContainerDiv>
        <MerchantActions
          data-test="component-MerchantActions"
          history={props.history}
          handleVisibilityCancelOrderModal={handleVisibilityCancelOrderModal}
          handleVisibilitySendLoyaltyModal={handleVisibilitySendLoyaltyModal}
          refundIsDisabled={orderDetails.refundIsDisabled}
          refundedIsHidden={
            orderDetails &&
            orderDetails.transactions.length &&
            (orderDetails.transactions[0].type == 'refund' ||
              orderDetails.transactions[0].status == 'failed')
          }
        />

        {isCancelOrderModalVisible ? (
          <CancelTransactionModal
            data-test="component-CancelTransactionModal"
            customerName={orderDetails.customerName}
            handleOrderCancel={handleOrderCancel}
            handleIsOrderCancelBoxChecked={handleIsOrderCancelBoxChecked}
            handleVisibilityCancelOrderModal={handleVisibilityCancelOrderModal}
            isOrderCancelBoxChecked={isOrderCancelBoxChecked}
            requestingCancelOrderDetails={requestingCancelOrderDetails}
          />
        ) : null}
        {isSendLoyaltyModalVisible ? (
          <SendLoyaltyModal
            data-test="component-SendLoyaltyModal"
            getTypesOfRewardOptions={getTypesOfRewardOptions}
            customerName={orderDetails.customerName}
            handleVisibilitySendLoyaltyModal={handleVisibilitySendLoyaltyModal}
            handleSendLoyaltySubmit={handleSendLoyaltySubmit}
            requestingSendLoyaltyPointsByCustomerId={
              requestingSendLoyaltyPointsByCustomerId
            }
          />
        ) : null}
      </ContainerDiv>
    </TableWrapper>
  );
};

export default OrderDetails;
