import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { Icon } from 'merchant-portal-components';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const FieldWrappedDiv = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 12px 0;
`;

const ComboImage = styled.img`
  width: 50px;
  background-color: #d3d3d3;
`;

const ComboCardDiv = styled.div`
  display: grid;
  grid-template-columns: 60px auto 60px;
  align-items: center;
  cursor: pointer;
`;

const HeaderDiv = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: 50px auto 60px;
  padding: 0 0 25px;
  font-weight: bold;
`;

const StyledSortableContainer = styled.div`
  height: 100%;
  overflow-y: scroll; // need to be in container for auto scroll when sorting
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 24px;
`;

const styles = {
  switchBase: {
    transition: 'none',
  },
  switchBaseDefault: {
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const SortableItem = SortableElement(({ combo, itemIndex, arrayHelpers }) => {
  return (
    <FieldWrappedDiv>
      <Icon
        className="far fa-equals"
        style={{ cursor: 'move', textAlign: 'center' }}
      />
      <ComboCardDiv>
        <div>
          {combo.image_url ? (
            <ComboImage src={combo.image_url} alt={combo.name} />
          ) : (
            <div />
          )}
        </div>
        <div>{combo.name}</div>
        <div style={{ textAlign: 'right' }}>
          <Switch
            checked={combo.is_active}
            onChange={() => {
              arrayHelpers.form.setFieldValue(
                `combos[${itemIndex}].is_active`,
                !combo.is_active
              );
            }}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </ComboCardDiv>
    </FieldWrappedDiv>
  );
});

const SortableList = SortableContainer(({ combosList, arrayHelpers }) => {
  return (
    <StyledSortableContainer>
      <HeaderDiv>
        <div />
        <div>Name</div>
        <div style={{ textAlign: 'right' }}>Active</div>
      </HeaderDiv>
      {combosList.map((combo, index) => (
        <SortableItem
          key={`combo-${index}`}
          index={index}
          itemIndex={index}
          combo={combo}
          arrayHelpers={arrayHelpers}
        />
      ))}
    </StyledSortableContainer>
  );
});

const RenderSortableComboList = ({ arrayHelpers }) => {
  const combosList = arrayHelpers.form.values.combos;
  const setFieldValue = arrayHelpers.form.setFieldValue;

  return (
    <Fragment>
      <SortableList
        helperClass="sortableHelper"
        combosList={combosList}
        arrayHelpers={arrayHelpers}
        onSortEnd={({ oldIndex, newIndex }) => {
          if (oldIndex !== newIndex) {
            const newList = arrayMove(combosList, oldIndex, newIndex);
            setFieldValue(`combos`, newList);
          }
        }}
        distance={1}
      />
    </Fragment>
  );
};

export default withStyles(styles)(RenderSortableComboList);
