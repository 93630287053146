import React from 'react';
import { TitleArea } from '../Table/TableElements';

function ChildLocationMultiSelector({
  handleChildLocationSelection,
  selectedParentLocation,
  selectedChildLocations,
  customWidth,
}) {
  return (
    <TitleArea
      selectName="childLocation"
      selectLabel="Child Location"
      customWidth={customWidth}
      getOptionLabel={(business) => business.display_name}
      getOptionValue={(business) => business.id}
      multiEnabled={true}
      gridTemplate={'1fr / 2fr '}
      selectValue={selectedChildLocations}
      options={
        Object.keys(selectedParentLocation).length !== 0
          ? selectedParentLocation.sub_businesses
          : []
      }
      isDisabled={
        Object.keys(selectedParentLocation).length === 0 ||
        selectedParentLocation?.sub_businesses?.length === 1
          ? true
          : false
      }
      onChange={handleChildLocationSelection}
    />
  );
}

export default ChildLocationMultiSelector;
