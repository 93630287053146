import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './customerActions';
import CustomerDetails from './components/CustomerDetails';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge } from 'merchant-portal-components';

const isRapidDeploymentClient = (appIdentifier) =>
  [
    'dennys',
    'tropical_treats',
    'butchies',
    'opus_restaurant',
    'moxies',
    'shark',
    // 'rock_creek',
    'chop',
  ].includes(appIdentifier);

/**
 * Class based component handling how components work
 */
class CustomerListContainer extends Component {
  componentDidMount() {
    track.page('customer-service-customer-detail');
    this.props.getCustomerDetails(this.props.match.params.customerId);
  }

  handleBackButtonClick = () => {
    this.props.history.goBack();
  };

  componentWillUnmount() {
    this.props.resetCustomerDetailsReducerState();
  }

  render() {
    const { customerReducer, accountReducer } = this.props;
    const {
      getCustomerGlobalCreditsIsRequesting,
      getCustomerLoyaltyInformationIsRequesting,
      getCustomerDetailsIsRequesting,
    } = customerReducer;

    const { appMetaData, appIdentifier } = accountReducer;

    const componentsAreRequesting =
      getCustomerDetailsIsRequesting &&
      getCustomerLoyaltyInformationIsRequesting &&
      getCustomerGlobalCreditsIsRequesting;

    const pageName = 'Customer Details';

    return (
      <Fragment>
        <BreadCrumb pageName={pageName} />
        {componentsAreRequesting ? (
          <LoadingBlueLarge />
        ) : (
          <CustomerDetails
            customerId={this.props.match.params.customerId}
            handleBackButtonClick={this.handleBackButtonClick}
            loyaltyIsVisible={
              appMetaData.configuration.program_type == 'points' &&
              !isRapidDeploymentClient(appIdentifier)
            }
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customerReducer: state.customerReducer,
  accountReducer: state.accountReducer,
});

export default connect(mapStateToProps, actions)(CustomerListContainer);
