import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6.1px;
  background-color: #d3d3d3;
`;

const MediaDefaultImageWrapper = styled.div`
  width: ${(props) =>
    props.imgWrapperWidth ? props.imgWrapperWidth : '180px'};
  height: ${(props) =>
    props.imgWrapperWidth ? props.imgWrapperWidth : '180px'};
  border: ${(props) => (props.Image ? 'none' : 'dashed 2px #2440ae')};
  border-radius: 6.1px;
`;

const MediaDefaultImage = styled.img`
  width: ${(props) => (props.imgWidth ? props.imgWidth : '103px')};
  height: ${(props) => (props.imgHeight ? props.imgHeight : '91px')};
  object-fit: cover;
  transform: translate(40%, 45%);
`;

const MediaWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${(props) => props.isColumn && 'auto auto'};
  margin-right: ${(props) => props.isColumn && 'auto'};
  align-items: ${(props) => props.isColumn && 'center'};
`;

const ChooseImageButton = styled.div`
  width: 180px;
  height: 43px;
  border-radius: 8px;
  background-color: #e6ebff;
  font-weight: 600;
  color: #2440ae;
  text-align: center;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #d9e0ff;
  }
`;

const ChooseImage = ({
  imageUrl,
  setIsImageGallaryVisible,
  isColumn,
  imgWidth,
  imgWrapperWidth,
  imgHeight,
}) => {
  return (
    <MediaWrapper isColumn={isColumn}>
      <MediaDefaultImageWrapper
        Image={imageUrl}
        imgWrapperWidth={imgWrapperWidth}
      >
        {!imageUrl ? (
          <MediaDefaultImage
            src="https://s3.amazonaws.com/assets.smoothpay.com/web/portal-assets/image-icon%402x.png"
            imgWidth={imgWidth}
            imgHeight={imgHeight}
          />
        ) : (
          <Image src={imageUrl} />
        )}
      </MediaDefaultImageWrapper>
      <ChooseImageButton onClick={() => setIsImageGallaryVisible(true)}>
        Choose Image
      </ChooseImageButton>
    </MediaWrapper>
  );
};

export default ChooseImage;
