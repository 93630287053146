import React, { Component } from 'react';
import { Select } from 'merchant-portal-components';
import styled from 'styled-components';

class CustomerSearchSelect extends Component {
  state = {
    queryString: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.queryString !== this.state.queryString) {
      this.props.getCustomersByQuery(this.state.queryString);
    }
  }

  customerLabel = (customer) =>
    `${customer.first_name} ${customer.last_name} (${customer.email})`;

  handleSearchChange = (queryString) => {
    this.setState({ queryString });
  };

  renderNoOptionsMessage = () => {
    if (this.state.queryString.length < 3) {
      return 'Type at least 3 characters to search';
    } else {
      if (this.props.customerSearchManager.requestingGetCustomersByQuery) {
        return 'Loading...';
      } else {
        return 'No results found';
      }
    }
  };

  render() {
    const { customerSearchManager, selectCustomer, onChange } = this.props;
    const {
      requestingGetCustomersByQuery,
      selectedCustomer,
      customersByQuery,
    } = customerSearchManager;

    return (
      <Select
        whiteBackground
        light
        options={customersByQuery}
        getOptionValue={(customer) => customer.customer_id}
        getOptionLabel={(customer) => this.customerLabel(customer)}
        isLoading={requestingGetCustomersByQuery}
        noOptionsMessage={this.renderNoOptionsMessage()}
        isSearchable={true}
        inputValue={this.state.queryString}
        onChange={(selectedCustomer) => {
          selectCustomer(selectedCustomer);
          onChange ? onChange(selectedCustomer) : null;
        }}
        onInputChange={(queryString) => this.handleSearchChange(queryString)}
        value={selectedCustomer}
      />
    );
  }
}

export default CustomerSearchSelect;
