import React, { Fragment } from "react";
import styled from "styled-components";
import { Formik, FieldArray } from "formik";
import { Collapse } from "@material-ui/core";
import ChevronUpIcon from "../../../../../../components/Icon/ChevronUpIcon";
import ChevronDownIcon from "../../../../../../components/Icon/ChevronDownIcon";
import { EditIcon } from "merchant-portal-components";
import { Button, ButtonRow } from "merchant-portal-components";
import RenderProductOptionsFieldArray from "./RenderProductOptionsFieldArray";
import ConfirmCancelModal from "../../components/ConfirmCancelModal";
import OptionsSummary from "./OptionsSummary";

const Card = styled.div`
  background: ${(props) => (props.disabled ? "#ebebeb" : "white")};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? "#959595" : "initial")};
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 18px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const EditOptionWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 16px;
  grid-gap: 10px;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const productOptionsAreValid = (productOptions) =>
  productOptions.every((option) => option.product_option_id);

const ProductOptionsForm = ({
  isExpanded,
  setIsExpanded,
  initialValues,
  allOptions,
  setProductOptionsAreEditable,
  productOptionsAreEditable,
  confirmCancelModalIsVisible,
  setConfirmCancelModalIsVisible,
  putProductOptions,
  disabled,
}) => {
  return (
    <Card
      disabled={disabled}
      onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, dirty, setValues }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>Options - Optional</Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            {!isExpanded && (
              <OptionsSummary
                productOptionsInitialValues={initialValues.productOptions}
                allOptions={allOptions}
              />
            )}
            <Collapse in={isExpanded}>
              <div style={{ display: "grid", gridGap: "25px" }}>
                {values.productOptions.length !== 0 && (
                  <EditOptionWrapper>
                    <div>Edit Options</div>
                    <EditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setProductOptionsAreEditable(
                          !productOptionsAreEditable
                        );
                      }}
                    />
                  </EditOptionWrapper>
                )}
                <FieldArray
                  name="productOptions"
                  render={(arrayHelpers) => (
                    <RenderProductOptionsFieldArray
                      arrayHelpers={arrayHelpers}
                      allOptions={allOptions}
                      productOptionsAreEditable={productOptionsAreEditable}
                    />
                  )}
                />
                <ButtonRow justifyContent="space-between">
                  <Button
                    secondary
                    onClick={(e) => {
                      e.stopPropagation();
                      dirty
                        ? setConfirmCancelModalIsVisible(true)
                        : setIsExpanded(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !dirty || !productOptionsAreValid(values.productOptions)
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(false);
                      putProductOptions(values.productOptions);
                    }}
                  >
                    Save
                  </Button>
                </ButtonRow>
              </div>
            </Collapse>
            {confirmCancelModalIsVisible && (
              <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                  setValues(initialValues);
                  setConfirmCancelModalIsVisible(false);
                  setIsExpanded(false);
                }}
              />
            )}
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};
export default ProductOptionsForm;
