import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { setAvailabilityOption } from '../utils/helper.js';
import { dayToString } from '../utils/helper.js';
import { renderAvailabilityOptions } from '../utils/helper.js';
import {
  RenderTimePicker,
  RenderSelect,
} from '../../../../../../components/FormElements/FormikElements';
import { Button } from 'merchant-portal-components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const LineDivider = styled.hr`
  border: solid 1px #e1e1e1;
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px 20px;
  width: 100%;

  > :last-child {
    justify-self: right;
  }

  > :first-child {
    justify-self: left;
  }
`;

const HoursSelectionGrid = styled.div`
  display: grid;
  grid-column-gap: 25px;
  align-items: center;
  margin-top: 10px;
  grid-template-columns: 160px 250px auto;
  grid-gap: 25px;
  min-height: 75px;
`;

const TimeSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const DayName = styled.p`
  font-weight: 600;
  align-self: center;
  text-transform: capitalize;
  font-size: 14px !important;
`;

const HoursField = styled.div`
  text-align: left;

  .rw-popup-container.rw-dropup {
    z-index: 99999 !important;
    position: absolute;
    top: 100%;
    left: 0;
  }
`;

const AvailableHoursModal = ({
  //comboAvailabilities,
  setFieldValue,
  toggleAvailableHoursModal,
  comboAvailability,
  handleReset,
  setIsComboAvailableAllTime,
}) => {
  const onChangeTime = (field, value, index, setFieldValue) => {
    const newArray = comboAvailability.map((comboAvailableHours, i) => {
      if (i === index) {
        return {
          ...comboAvailableHours,
          [field]: value,
        };
      }

      return comboAvailableHours;
    });
    setFieldValue('comboAvailability', newArray);
    //setCombosAvailableHours(newArray);
  };

  return (
    <Dialog open fullWidth maxWidth="md">
      <DialogTitle>
        <ModalTitle>
          <div>Set Combo Available Hours</div>
          <div
            onClick={() => toggleAvailableHoursModal()}
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalTitle>
      </DialogTitle>
      <DialogContent>
        {comboAvailability.map((comboAvailableHours, index) => {
          const initialFormState = {
            mySelectKey: [
              !comboAvailableHours.isAvailableAllDay &&
              !comboAvailableHours.start_time
                ? {
                    id: 2,
                    name: 'Not Available All Day',
                  }
                : comboAvailableHours.isAvailableAllDay
                ? {
                    id: 1,
                    name: 'Available All Day',
                  }
                : {
                    id: 3,
                    name: 'Select Available Hours',
                  },
            ],
          };

          const [myForm, setMyForm] = useState(initialFormState);
          return (
            <div key={index}>
              <HoursSelectionGrid key={index}>
                <DayName>
                  {dayToString(comboAvailableHours.start_day_of_week)}
                </DayName>
                {/* <Field
                  // name={`combosAvailableHours[${index}]`}
                  component={RenderSelect}
                  options={renderAvailabilityOptions()}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option}
                  onChange={(option) => {
                    setMyForm({ ...myForm, mySelectKey: option });
                    setAvailabilityOption(
                      comboAvailability,
                      option,
                      index,
                      setFieldValue
                    );
                  }}
                  // value={myForm.mySelectKey[0]}
                  // defaultValue={myForm.mySelectKey}
                  required
                  isSearchable={false}
                /> */}
                <FormControl variant="outlined">
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={myForm.mySelectKey[0]?.id || myForm.mySelectKey.id}
                    onChange={(e) => {
                      const optionId = e.target.value;
                      const selectedOption = renderAvailabilityOptions().find(
                        (item) => item?.id === optionId
                      );
                      setMyForm({ ...myForm, mySelectKey: selectedOption });
                      setAvailabilityOption(
                        comboAvailability,
                        selectedOption,
                        index,
                        setFieldValue
                      );
                    }}
                  >
                    {renderAvailabilityOptions().map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!comboAvailableHours.isAvailableAllDay &&
                !comboAvailableHours.start_time ? (
                  <div>Combo is not available all day</div>
                ) : comboAvailableHours.isAvailableAllDay ? (
                  'Combo is Available all day'
                ) : (
                  <TimeSelectionGrid>
                    <HoursField>
                      Start
                      <Field
                        name={`comboAvailability[${index}].start_time`}
                        component={RenderTimePicker}
                        onChange={(value) => {
                          onChangeTime(
                            'start_time',
                            value,
                            index,
                            setFieldValue
                          );
                          setFieldValue(
                            `comboAvailability[${index}].start_time`,
                            value
                          );
                        }}
                        hideDate
                        max={new Date(moment('January 1, 3999 23:30:00'))}
                      />
                    </HoursField>
                    <HoursField>
                      End
                      <Field
                        name={`comboAvailability[${index}].end_time`}
                        component={RenderTimePicker}
                        onChange={(value) => {
                          onChangeTime('end_time', value, index, setFieldValue);
                          setFieldValue(
                            `comboAvailability[${index}].end_time`,
                            value
                          );
                        }}
                        hideDate
                      />
                    </HoursField>
                  </TimeSelectionGrid>
                )}
              </HoursSelectionGrid>
              <LineDivider />
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <ButtonsWrapper>
          <Button
            secondary
            onClick={() => {
              handleReset();
              toggleAvailableHoursModal();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setTimeout(() => {
                toggleAvailableHoursModal();
              }, 1000);
            }}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default AvailableHoursModal;
