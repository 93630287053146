import React, { useState, useEffect } from 'react';
import * as mediaActions from '../mediaActions';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import CreateEditImageForm from '../components/CreateEditImageForm';

const CreateEditImageContainer = ({
  isEditing,
  hideCreateImageForm,
  uploadImage,
  history,
  getImages,
  parentBusinessLocations,
}) => {
  const [isCropping, setIsCropping] = useState(false);
  const [selectedParentLocation, setSelectedParentLocation] = useState({});
  const [selectedChildLocations, setSelectedChildLocations] = useState([]);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: null,
  });
  const [imageDetails, setImageDetails] = useState({
    imageFile: '',
    src: '',
    imageName: '',
    imageAltText: '',
    childLocations: [],
  });
  const handleSubmit = (values) => {
    uploadImage(
      values.imageFile,
      values.imageName,
      values.imageAltText,
      values.childLocations
    );
  };
  useEffect(() => {
    if (Object.keys(parentBusinessLocations).length === 1) {
      const onlyParentLocation =
        parentBusinessLocations[Object.keys(parentBusinessLocations)[0]];
      setSelectedParentLocation(onlyParentLocation);
      if (onlyParentLocation.sub_businesses.length === 1) {
        setSelectedChildLocations(onlyParentLocation.sub_businesses);
      }
    }
  }, [parentBusinessLocations.length]);
  const isAllowedImageFormat = (uploadedFormat) =>
    ['image/jpg', 'image/jpeg', 'image/png'].includes(uploadedFormat);

  const getImageDetails = (imageUploaded) => {
    if (
      imageUploaded.files &&
      imageUploaded.files[0].size / 1000 / 1000 <= 2 &&
      isAllowedImageFormat(imageUploaded.files[0].type)
    ) {
      setImageDetails({
        imageFile: imageUploaded.files[0],
        src: URL.createObjectURL(imageUploaded.files[0]),
        imageName: imageUploaded.files[0].name
          .replace(/\.([^.]*)$/, '')
          .slice(0, 29),
      });
    } else {
      setImageDetails({
        ...imageDetails,
        imageError: 'Image is too big or invalid format',
      });
    }
  };

  const onImageLoaded = (image) => {
    setImageDetails({ ...imageDetails, imageRef: image });
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop({
      ...percentCrop,
      aspect: null,
    });
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onChangeToIncompleteCropClick = (aspectRatio) => {
    setCrop({
      aspect: aspectRatio,
      unit: '%',
      width: 100,
    });
  };

  const makeClientCrop = (crop) => {
    if (imageDetails.imageRef && crop.width && crop.height) {
      getCroppedImg(imageDetails.imageRef, crop, 'newFile.jpeg').then(
        (croppedImageDetails) =>
          setImageDetails({ ...imageDetails, croppedImageDetails })
      );
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleCropSubmit = () => {
    setImageDetails({
      ...imageDetails,
      imageFile: imageDetails.croppedImageDetails,
      src: URL.createObjectURL(imageDetails.croppedImageDetails),
      croppedImageDetails: null,
    });
    setIsCropping(false);
  };

  const handleCropCancel = () => {
    setIsCropping(false);
  };

  return (
    <Dialog open fullWidth maxWidth="md">
      <CreateEditImageForm
        initialValues={imageDetails}
        hideCreateImageForm={hideCreateImageForm}
        handleSubmit={handleSubmit}
        isCropping={isCropping}
        setIsCropping={setIsCropping}
        setImageDetails={setImageDetails}
        getImageDetails={getImageDetails}
        crop={crop}
        onImageLoaded={onImageLoaded}
        onCropComplete={onCropComplete}
        onChangeToIncompleteCropClick={onChangeToIncompleteCropClick}
        onCropChange={onCropChange}
        handleCropSubmit={handleCropSubmit}
        handleCropCancel={handleCropCancel}
        getImages={getImages}
        history={history}
        selectedParentLocation={selectedParentLocation}
        setSelectedParentLocation={setSelectedParentLocation}
        selectedChildLocations={selectedChildLocations}
        setSelectedChildLocations={setSelectedChildLocations}
      />
    </Dialog>
  );
};

export default connect(null, mediaActions)(CreateEditImageContainer);
