import React, { useEffect } from 'react';
import styled from 'styled-components';
import Card from '../Engagement/MobileMessages/components/Card';
import nav from '../Full/_nav';
import { TitleArea } from '../../components/Table/TableElements';
import categoiryIcon from '../../../public/menu_icons/categories-icon.png';
import categoiryIconInactive from '../../../public/menu_icons/categories-icon-inactive.png';
import VariantIcon from '../../../public/menu_icons/variant-icon.png';
import VariantIconInactive from '../../../public/menu_icons/variant-icon-inactive.png';
import OptionIcon from '../../../public/menu_icons/options-icon.png';
import OptionIconInactive from '../../../public/menu_icons/options-icon-inactive.png';
import OptionPriceIcon from '../../../public/menu_icons/option-price-icon.png';
import OptionPriceIconInactive from '../../../public/menu_icons/option-price-icon-inactive.png';
import productIcon from '../../../public/menu_icons/product-icon.png';
import productIconInactive from '../../../public/menu_icons/product-icon-inactive.png';
import comboIcon from '../../../public/menu_icons/combo-icon.png';
import comboIconInactive from '../../../public/menu_icons/combo-icon-inactive.png';
import posMappingIcon from '../../../public/menu_icons/pos-mapping-icon.png';
import posMappingIconInactive from '../../../public/menu_icons/pos-mapping-icon-inactive.png';
import upsellIcon from '../../../public/menu_icons/upsell-icon.png';
import upsellIconInactive from '../../../public/menu_icons/upsell-icon-inactive.png';

export const SelectLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  max-width: 900px;
`;
export const TitleWrapper = styled.div`
  margin-top: 36px;
  margin-bottom: 10px;
`;
export const Title = styled.p`
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: #191818;
`;
export const SubTitle = styled.p`
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.03px;
  color: #191818;
`;

const MenuManagement = ({
  history,
  parentLocations,
  selectedMenuManagementChildLocation,
  selectedMenuManagementParentLocation,
  setMenuManagementSelectedChildLocation,
  setMenuManagementSelectedParentLocation,
  posIntegrationEnabled,
}) => {
  const menuManagementNav = nav.items.find((x) => x.url === '/menu-management');
  useEffect(() => {
    if (parentLocations.length === 1) {
      setMenuManagementSelectedParentLocation(parentLocations[0]);
    }
    if (Object.keys(selectedMenuManagementChildLocation).length > 0) {
      setMenuManagementSelectedChildLocation(
        selectedMenuManagementChildLocation
      );
    }
  }, [parentLocations.length]);

  useEffect(() => {
    if (selectedMenuManagementParentLocation?.sub_businesses?.length === 1) {
      setMenuManagementSelectedChildLocation(
        selectedMenuManagementParentLocation.sub_businesses[0]
      );
    }
  }, [selectedMenuManagementParentLocation]);
  const getLogoURL = (menuName, isActive) => {
    let logoURL = '';
    if (menuName === 'Categories') {
      logoURL = isActive ? categoiryIcon : categoiryIconInactive;
    } else if (menuName === 'Variants') {
      logoURL = isActive ? VariantIcon : VariantIconInactive;
    } else if (menuName === 'Options') {
      logoURL = isActive ? OptionIcon : OptionIconInactive;
    } else if (menuName === 'Option Price') {
      logoURL = isActive ? OptionPriceIcon : OptionPriceIconInactive;
    } else if (menuName === 'Products') {
      logoURL = isActive ? productIcon : productIconInactive;
    } else if (menuName === 'Combos') {
      logoURL = isActive ? comboIcon : comboIconInactive;
    } else if (menuName === 'POS Mapping') {
      logoURL = isActive ? posMappingIcon : posMappingIconInactive;
    } else if (menuName === 'upsell') {
      logoURL = isActive ? upsellIcon : upsellIconInactive;
    }
    return logoURL;
  };
  return (
    <div>
      <TitleWrapper>
        <Title>Menu Management</Title>
        <SubTitle>
          Please select a child location to start menu management
        </SubTitle>
      </TitleWrapper>
      <SelectLocationWrapper>
        <TitleArea
          selectName="parentLocation"
          selectLabel="Parent Location"
          selectValue={selectedMenuManagementParentLocation}
          onChange={(data) => {
            setMenuManagementSelectedParentLocation(data);
          }}
          options={parentLocations}
          getOptionLabel={(business) => business.display_name || ''}
          getOptionValue={(business) => business.id}
          customWidth="410px"
          isDisabled={parentLocations.length === 1 ? true : false}
        />
        <TitleArea
          selectName="childLocation"
          selectLabel="Child Location"
          selectValue={
            Object.keys(selectedMenuManagementChildLocation).length !== 0
              ? selectedMenuManagementChildLocation
              : {}
          }
          onChange={(data) => {
            setMenuManagementSelectedChildLocation(data);
          }}
          options={
            Object.keys(selectedMenuManagementParentLocation).length !== 0
              ? selectedMenuManagementParentLocation.sub_businesses
              : []
          }
          getOptionLabel={(business) => business.display_name || ''}
          getOptionValue={(business) => business.id}
          customWidth="410px"
          isDisabled={
            Object.keys(selectedMenuManagementParentLocation).length === 0 ||
            selectedMenuManagementParentLocation?.sub_businesses?.length === 1
              ? true
              : false
          }
        />
      </SelectLocationWrapper>
      {menuManagementNav.children.map((item, index) => {
        if (
          (!posIntegrationEnabled && item.name !== 'POS Mapping') ||
          posIntegrationEnabled
        ) {
          return (
            <Card
              key={index}
              logo={getLogoURL(item.name, true)}
              inactiveLogo={getLogoURL(item.name, false)}
              title={item.name}
              description={item.description}
              onArrowClick={() => history.push(item.url)}
              isActive={
                Object.keys(selectedMenuManagementChildLocation).length === 0
                  ? false
                  : true
              }
            />
          );
        }
      })}
    </div>
  );
};

export default MenuManagement;
