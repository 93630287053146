import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';

const NoCustomersFound = styled.div`
  text-align: center;
  position: relative;
  transform: translateY(35%);
  height: 250px;
  font-size: 38px;
  color: #d8d8d8;
`;

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

/**
 * Return static table headers
 * @returns {JSX.Element} - Rendered Headers
 */
const renderHeaders = () => (
  <TrHead>
    <Th style={{ width: '480px' }} align="left">
      Email
    </Th>
    <Th style={{ width: '304px' }} align="left">
      First Name
    </Th>
    <Th style={{ width: '304px' }} align="left">
      Last Name
    </Th>
  </TrHead>
);

/**
 * Return table rows
 * @param {List} customers - List of objects containing customers information
 * @param {Function} handleEditIconClick - function to handle edit icon click
 * @returns {JSX.Element} - Rendered Rows
 */
const renderRows = (customers, handleEditIconClick) => {
  return customers.map((customer, index) => {
    return (
      <Tr
        onClick={() => handleEditIconClick(customer.customer_id)}
        style={{ cursor: 'pointer' }}
        key={index}
        data-test="component-customerListTableRows"
      >
        <Td style={{ width: '680px' }} align="left">
          {customer.email}
        </Td>
        <Td style={{ width: '254px' }} align="left">
          {customer.first_name}
        </Td>
        <Td style={{ width: '254px' }} align="left">
          {customer.last_name}
        </Td>
      </Tr>
    );
  });
};

/**
 * Render table with customers information
 * @param {Object} props - React props
 * @returns {JSX.Element} - Rendered component
 */
const CustomerListTable = (props) => {
  const { customers, getCustomersIsRequesting, handleEditIconClick } = props;
  return (
    <Fragment>
      <TableWrapper data-test="component-customerListTable">
        <Table noBorder>
          <TBody striped>
            {renderHeaders()}
            {!getCustomersIsRequesting && customers.length
              ? renderRows(customers, handleEditIconClick)
              : null}
          </TBody>
        </Table>
      </TableWrapper>
      {getCustomersIsRequesting ? (
        <LoadingBlueLarge />
      ) : customers.length ? null : (
        <NoCustomersFound data-test="component-noCustomersFound">
          No Customers Found
        </NoCustomersFound>
      )}
    </Fragment>
  );
};

export default CustomerListTable;
