import {
  GET_IMAGES_REQUEST,
  GET_IMAGES_SUCCESS,
  GET_IMAGES_FAILURE,
  SHOW_CREATE_IMAGE_FORM,
  HIDE_CREATE_IMAGE_FORM,
  SHOW_EDIT_IMAGE_FORM,
  HIDE_EDIT_IMAGE_FORM,
  CREATE_IMAGE_REQUEST,
  CREATE_IMAGE_SUCCESS,
  CREATE_IMAGE_FAILURE,
  EDIT_IMAGE_REQUEST,
  EDIT_IMAGE_SUCCESS,
  EDIT_IMAGE_FAILURE,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAILURE,
  REINIT_IMAGES,
  RESET_MEDIA_REDUCER_STATE,
  UPDATE_SORTED_IMAGES_LIST,
  DISPLAY_CONFIRM_MODAL,
  SET_MEDIA_SELECTED_CHILD_LOCATION,
  SET_MEDIA_SELECTED_PARENT_LOCATION,
} from './mediaTypes';
import { UPDATE_MEDIA_REQUEST_PARAMS } from '../Pagination/types';

export default function (
  state = {
    requestParams: {
      page_number: 1,
      page_size: 25,
      sorting_option: '',
    },
    requestingGetImages: false,
    requestingDeleteImage: false,
    images: null,
    error: null,
    meta: {},
    imageDeleteSuccess: null,
    requestingDeleteImage: false,
    displayConfirmModal: false,
    selectedMediaChildLocation: {},
    selectedMediaParentLocation: {},
  },
  action
) {
  let defaultState = {
    requestParams: {
      page_number: 1,
      page_size: 25,
      sorting_option: '',
    },
    requestingGetImages: false,
    requestingDeleteImage: false,
    images: null,
    error: null,
    meta: {},
    imageDeleteSuccess: null,
    requestingDeleteImage: false,
    selectedMediaChildLocation: {},
    selectedMediaParentLocation: {},
  };

  switch (action.type) {
    case REINIT_IMAGES:
      return {
        ...state,
        imageDeleteSuccess: null,
        requestingDeleteImage: false,
      };
    case GET_IMAGES_REQUEST:
      return {
        ...state,
        requestingGetImages: true,
      };
    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        requestingGetImages: false,
        images: action.images,
        meta: action.meta,
      };
    case UPDATE_MEDIA_REQUEST_PARAMS:
      return {
        ...state,
        requestParams: {
          ...state.requestParams,
          ...action.payload,
        },
      };
    case GET_IMAGES_FAILURE:
      return {
        ...state,
        requestingGetImages: false,
        error: action.error,
      };
    case SHOW_CREATE_IMAGE_FORM:
      return {
        ...state,
        createImageFormIsVisible: true,
      };
    case HIDE_CREATE_IMAGE_FORM:
      return {
        ...state,
        createImageFormIsVisible: false,
      };
    case SHOW_EDIT_IMAGE_FORM:
      return {
        ...state,
        editImageFormIsVisible: true,
        imageBeingEdited: action.imageBeingEdited,
      };
    case HIDE_EDIT_IMAGE_FORM:
      return {
        ...state,
        editImageFormIsVisible: false,
        imageBeingEdited: null,
      };
    case CREATE_IMAGE_REQUEST:
      return {
        ...state,
        requestingCreateImage: true,
      };
    case CREATE_IMAGE_SUCCESS:
      return {
        ...state,
        requestingCreateImage: false,
        createImageFormIsVisible: false,
      };
    case CREATE_IMAGE_FAILURE:
      return {
        ...state,
        requestingCreateImage: false,
      };
    case EDIT_IMAGE_REQUEST:
      return {
        ...state,
        requestingEditImage: true,
      };
    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        requestingDeleteImage: true,
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        requestingDeleteImage: false,
        imageDeleteSuccess: true,
        displayConfirmModal: false,
      };
    case DELETE_IMAGE_FAILURE:
      return {
        ...state,
        requestingDeleteImage: false,
        imageDeleteSuccess: false,
      };
    case EDIT_IMAGE_SUCCESS:
      return {
        ...state,
        requestingEditImage: false,
        editImageFormIsVisible: false,
      };
    case EDIT_IMAGE_FAILURE:
      return {
        ...state,
        requestingEditImage: false,
      };
    case DISPLAY_CONFIRM_MODAL:
      return {
        ...state,
        displayConfirmModal: action.boolean,
      };
    case RESET_MEDIA_REDUCER_STATE:
      return defaultState; //none was defined
    case UPDATE_SORTED_IMAGES_LIST:
      return {
        ...state,
        images: action.imagesList,
        requestParams: {
          ...state.requestParams,
          sorting_option: action.sortOrder,
        },
      };
    case SET_MEDIA_SELECTED_CHILD_LOCATION:
      return {
        ...state,
        selectedMediaChildLocation: action.selectedLocation,
      };
    case SET_MEDIA_SELECTED_PARENT_LOCATION:
      return {
        ...state,
        selectedMediaParentLocation: action.selectedLocation,
      };
    default:
      return state;
  }
}
