import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from './priceActions';
import { initiateEditProductWizard } from '../Products/productsActions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import Price from './components/Price';
import { SELECT_LOCATION_SCREEN } from './priceTypes';
import { getVariants } from '../Variants/variantsActions';
import { getOptions } from '../OptionManager/optionActions';
const BackText = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;

const LocationText = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;

const PriceContainer = ({
  options,
  variants,
  getOptionPrices,
  filterOptionsByVariants,
  putOptionPrices,
  priceReducer,
  resetPriceReduxState,
  getItemsOverrides,
  getProductAssignedBusinesses,
  setIsOptionOverridesModal,
  getAssociatedProductForOption,
  initiateEditProductWizard,
  updateSelectedLocations,
  getOptions,
  getVariants,
  selectedMenuManagementChildLocation,
  history,
}) => {
  const {
    optionPrices,
    items,
    isRequesting,
    itemOverrides,
    isRequestingItemOverrides,
    formattedBusinesses,
    isOptionOverridesModal,
    accociatedProductList,
    selectedLocations,
  } = priceReducer;
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedVariants, selectVariants] = useState(null);
  const [page, setPage] = useState(SELECT_LOCATION_SCREEN);
  useEffect(() => {}, []);
  useEffect(() => {
    getOptions({
      page_number: 1,
      page_size: '2000',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: true,
    });
    getVariants({
      page_number: 1,
      page_size: '2000',
      sorting_option: 'title-asc',
      search_string: '',
      has_next_page: true,
    });
    return () => {
      resetPriceReduxState();
    };
  }, []);
  useEffect(() => {
    if (selectedOption !== null) {
      // getProductAssignedBusinesses(selectedOption);
      getOptionPrices(selectedOption);
      getAssociatedProductForOption(selectedOption.id);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption) {
      getItemsOverrides(selectedOption, items);
    }
  }, [items]);
  const handleSave = (itemsToSave, filteredItems) => {
    putOptionPrices(itemsToSave, selectedOption, filteredItems);
  };

  return (
    <Fragment>
      <BackText onClick={() => history.push('/menu-management')}>
        <i className="fa fa-chevron-left" />
        <div> Back to Menu Management</div>
      </BackText>
      {Object.keys(selectedMenuManagementChildLocation).length !== 0 && (
        <LocationText>
          <i className="fal fa-store-alt" />
          <div> {selectedMenuManagementChildLocation.display_name}</div>
        </LocationText>
      )}
      <BreadCrumb />
      {options && variants && (
        <Price
          isRequesting={isRequesting}
          items={items}
          selectOption={setSelectedOption}
          selectVariants={selectVariants}
          options={options}
          variants={variants}
          selectedVariants={selectedVariants}
          optionPrices={optionPrices}
          handleSave={handleSave}
          itemOverrides={itemOverrides}
          selectedOption={selectedOption}
          isRequestingItemOverrides={isRequestingItemOverrides}
          formattedBusinesses={formattedBusinesses}
          isOptionOverridesModal={isOptionOverridesModal}
          setIsOptionOverridesModal={setIsOptionOverridesModal}
          page={page}
          setPage={setPage}
          accociatedProductList={accociatedProductList}
          getAssociatedProductForOption={getAssociatedProductForOption}
          initiateEditProductWizard={initiateEditProductWizard}
          selectedLocations={selectedLocations}
          updateSelectedLocations={updateSelectedLocations}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  options: state.optionReducer.options,
  variants: state.variantsReducer.allVariants,
  priceReducer: state.priceReducer,
  accociatedProductList: state.priceReducer.accociatedProductList,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});

export default connect(mapStateToProps, {
  ...actions,
  initiateEditProductWizard,
  getOptions,
  getVariants,
})(PriceContainer);
