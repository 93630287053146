import React, { Fragment, useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Wrapper,
  Title,
  ImageAndFieldWrapper,
  FieldWrapper,
  RightWrapper,
  ImageWrapper,
  HelperTextAndImageUploadButton,
  DeleteAndCropWrapper,
  Image,
  ImageError,
} from './createEditImageFormStyles';
import ImageCropModal from './ImageCropModal';
import { TooltipIcon } from '../../../components/Icon';
import { Button, ButtonRow } from 'merchant-portal-components';
import { Formik, Field } from 'formik';
import { RenderTextField } from '../../../components/FormElements/FormikElements';
import ChildLocationMultiSelector from '../../../components/LocationSelectors/ChildLocationMultiSelector';
import ParentLocationSelector from '../../../components/LocationSelectors/ParentLocationSelector';

const altTextDescription = `Alt Text is a description of an image that gets displayed if the users device can't 
   render the image or if a user is using a screen reading device to access your site. 
   Alt text can also be leveraged for SEO purposes.`;

const CreateEditImageForm = ({
  initialValues,
  hideCreateImageForm,
  handleSubmit,
  isCropping,
  setIsCropping,
  crop,
  setImageDetails,
  getImageDetails,
  onImageLoaded,
  onCropComplete,
  onChangeToIncompleteCropClick,
  onCropChange,
  handleCropSubmit,
  handleCropCancel,
  selectedParentLocation,
  setSelectedParentLocation,
  selectedChildLocations,
  setSelectedChildLocations,
}) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          ...initialValues,
          childLocations: selectedChildLocations.map((child) => child.id) || [],
        }}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, setFieldTouched, errors }) => (
          <Wrapper>
            <Title>Upload Image</Title>
            <ImageAndFieldWrapper>
              <ImageWrapper
                src={values.src}
                onDragEnter={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDragOver={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  getImageDetails(e.dataTransfer);
                }}
              >
                {!values.imageFile && (
                  <HelperTextAndImageUploadButton>
                    <p>Drag and Drop Image</p>
                    <p style={{ margin: '0 45%' }}> or</p>
                    <input
                      type="file"
                      id="imageUpload"
                      onChange={() => {
                        getImageDetails(document.getElementById('imageUpload'));
                      }}
                    ></input>
                    <label className="button" htmlFor="imageUpload">
                      Browse Computer
                    </label>
                    <p style={{ margin: '10px 40px 0 30px' }}>
                      {' '}
                      Maximum File Size: 2MB{' '}
                    </p>
                    <p style={{ margin: '0px 40px 0 30px' }}>
                      {' '}
                      Format: .jpg, .png, .JPEG{' '}
                    </p>
                  </HelperTextAndImageUploadButton>
                )}
                {values.imageFile && (
                  <Image
                    id="imageDisplay"
                    src={values.src}
                    alt=""
                    height="245"
                    width="245"
                  />
                )}
              </ImageWrapper>
              <RightWrapper>
                <FieldWrapper>
                  <Field
                    name="imageName"
                    component={RenderTextField}
                    type="text"
                    label="Image Name *"
                    validate={() => {
                      if (!values.imageName) return 'Required';
                      if (values.imageName && values.imageName.length > 30) {
                        return 'Image Name cannot be more than 30 characters';
                      }
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    name="imageAltText"
                    component={RenderTextField}
                    type="text"
                    label="Alt"
                  />
                  <Tooltip title={altTextDescription}>
                    <span style={{ height: '10px', margin: '15px 0 0 0' }}>
                      <TooltipIcon />
                    </span>
                  </Tooltip>
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    name="parentLocation"
                    component={ParentLocationSelector}
                    type="select"
                    label="Alt"
                    customWidth="510px"
                    selectedParentLocation={selectedParentLocation}
                    handleParentLocationSelection={(selectedParentLocation) => {
                      setSelectedParentLocation(selectedParentLocation);
                      setSelectedChildLocations(
                        selectedParentLocation.sub_businesses.length === 1
                          ? selectedParentLocation.sub_businesses
                          : []
                      );
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    name="childLocations"
                    component={ChildLocationMultiSelector}
                    type="select"
                    label="Alt"
                    customWidth="510px"
                    selectedParentLocation={selectedParentLocation}
                    selectedChildLocations={selectedChildLocations}
                    handleChildLocationSelection={(selectedChildLocations) => {
                      const selectedLocations =
                        selectedChildLocations.length > 0
                          ? selectedChildLocations
                          : selectedParentLocation.sub_businesses;
                      setSelectedChildLocations(selectedChildLocations);
                      setFieldValue(
                        'childLocations',
                        selectedLocations.map((business) => business.id)
                      );
                    }}
                    validate={() => {
                      if (values.childLocations.length === 0) return 'Required';
                    }}
                  />
                </FieldWrapper>
              </RightWrapper>
            </ImageAndFieldWrapper>
            {values.imageError && <ImageError>{values.imageError}</ImageError>}
            {values.src && (
              <DeleteAndCropWrapper>
                <i
                  className="fas fa-trash-alt"
                  onClick={() => {
                    setImageDetails({
                      imageFile: '',
                      src: '',
                      imageName: '',
                      imageAltText: '',
                      croppedImageDetails: '',
                      imageRef: '',
                    });
                  }}
                ></i>
                <i
                  className="fas fa-crop-alt"
                  onClick={() => setIsCropping(true)}
                ></i>
              </DeleteAndCropWrapper>
            )}
            <ButtonRow
              justifyContent="space-between"
              style={{
                margin: '24px 40px 24px 40px',
                width: 'inherit',
              }}
            >
              <Button
                secondary
                onClick={() => {
                  hideCreateImageForm(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={
                  values.imageName &&
                  values.imageFile &&
                  values.childLocations &&
                  Object.keys(errors).length == 0
                    ? false
                    : true
                }
                onClick={() => handleSubmit(values)}
              >
                Upload
              </Button>
            </ButtonRow>
          </Wrapper>
        )}
      </Formik>
      {isCropping && (
        <ImageCropModal
          src={initialValues.src}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onCropComplete={onCropComplete}
          onChangeToIncompleteCropClick={onChangeToIncompleteCropClick}
          onCropChange={onCropChange}
          handleCropSubmit={handleCropSubmit}
          handleCropCancel={handleCropCancel}
        />
      )}
    </Fragment>
  );
};

export default CreateEditImageForm;
