import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from '../combosAction';
import ComboList from './components/CombosList';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { LoadingBlueLarge, Icon, Button } from 'merchant-portal-components';
import CombosSortModal from './ComboSort/CombosSortModal';
import { TextField, InputAdornment } from '@material-ui/core';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 37px 0;
`;
const MessageWrapper = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const TextSearch = styled.div``;
const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  margin: 0 37px 37px 37px;
`;
const BackText = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;
const LocationText = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;
let CombosListContainer = ({
  combosList,
  initiateEditCombo,
  toggleCombo,
  updateRequestParams,
  requestParams,
  businesses,
  meta,
  isRequestingGetCombos,
  initiateCreateCombo,
  updateCombosRequestParams,
  updateSortedCombosList,
  isSavingComboPositions,
  handleSortingComboSubmit,
  allInitialCombos,
  searchString,
  setSearchString,
  getCombos,
  selectedMenuManagementChildLocation,
  history,
}) => {
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };
  const handleClearSearch = () => {
    setSearchString('');
  };
  return (
    <Fragment>
      <BackText onClick={() => history.push('/menu-management')}>
        <i className="fa fa-chevron-left" />
        <div> Back to Menu Management</div>
      </BackText>
      {Object.keys(selectedMenuManagementChildLocation).length !== 0 && (
        <LocationText>
          <i className="fal fa-store-alt" />
          <div> {selectedMenuManagementChildLocation.display_name}</div>
        </LocationText>
      )}
      <BreadCrumb pageName="Combos and Specials" />
      <Wrapper>
        <TopDiv>
          <TextSearch>
            <TextField
              type="text"
              style={{ width: '100%' }}
              variant="outlined"
              value={searchString}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon className="fal fa-search" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      className="fas fa-times-circle"
                      style={{ color: '#193053' }}
                      onClick={handleClearSearch}
                    />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
              placeholder="Search combos"
            />
          </TextSearch>
          <Button
            onClick={() => initiateCreateCombo()}
            style={{ width: '100%' }}
          >
            Create a Combo
          </Button>
        </TopDiv>
        {!isRequestingGetCombos && combosList.length > 0 ? (
          <ComboList
            initiateEditCombo={initiateEditCombo}
            combos={combosList}
            toggleCombo={toggleCombo}
            requestParams={requestParams}
            businesses={businesses}
            setIsSortModalOpen={setIsSortModalOpen}
            searchString={searchString}
            handleClearSearch={handleClearSearch}
            getCombos={getCombos}
          />
        ) : combosList.length === 0 && !isRequestingGetCombos ? (
          <MessageWrapper>No combos found.</MessageWrapper>
        ) : (
          <LoadingBlueLarge />
        )}
        {isSortModalOpen && (
          <CombosSortModal
            combosList={combosList}
            setIsSortModalOpen={setIsSortModalOpen}
            isSavingComboPositions={isSavingComboPositions}
            handleSortingComboSubmit={handleSortingComboSubmit}
          />
        )}
      </Wrapper>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isRequestingGetCombos: state.combosReducer.isRequestingGetCombos,
  combosList: state.combosReducer.combosList,
  meta: state.combosReducer.meta,
  requestParams: state.combosReducer.requestParams,
  isSavingComboPositions: state.combosReducer.isSavingComboPositions,
  allInitialCombos: state.combosReducer.allInitialCombos,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});

export default connect(mapStateToProps, actions)(CombosListContainer);
