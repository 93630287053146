import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableWithButtonRow,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';
import { STRING, BOOLEAN, sortByTypes } from '../../../../../utils/sort/sort';
import { SmallToggleSwitch } from '../../../../../components/ToggleSwitch/ToggleSwitch';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import { Tooltip, Switch } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const OptionsList = ({
  options,
  toggleOptionIsActive,
  requestParams,
  editOption,
  optionsLength,
  handleSort,
  getOptions,
}) => {
  const [showSubOptions, setShowSubOptions] = useState([]);
  const [showItems, setShowItems] = useState([]);

  const renderHeaders = () => (
    <TrHead>
      <Th
        align="left"
        style={{ paddingLeft: '37px' }}
        value="name"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
      >
        Name
      </Th>
      <Th
        align="left"
        value="id"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
      >
        ID
      </Th>
      <Th align="left">
        Min
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Minimum amount of item quantity that's required"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th align="left">
        Max
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Maximum amount of item quantity. Cannot go over this amount"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      <Th align="left">
        Included
        <Tooltip
          style={{ marginRight: 'auto' }}
          title="Amount of items that are free"
        >
          <span>
            <TooltipIcon marginLeft />
          </span>
        </Tooltip>
      </Th>
      {/* <Th align="left">Products Linked</Th> */}
      <Th
        align="right"
        value="is_active"
        sortingOption={requestParams.sorting_option}
        isSortable={false}
        style={{ paddingRight: '45px' }}
      >
        Active
      </Th>
    </TrHead>
  );
  const renderRows = () => {
    return (
      options &&
      options.map((option, index) => {
        if (!option.parent_id) {
          return (
            <Fragment key={index}>
              <Tr
                onClick={() => editOption(option.id)}
                key={index}
                style={{ height: '78px', color: '#191818', cursor: 'pointer' }}
              >
                <Td align="left" padding={'0px 0px 0px 37px'}>
                  <div>{option.name}</div>
                </Td>
                <Td align="left">{option.id}</Td>
                <Td align="left">{option.minimum_pick}</Td>
                <Td align="left">{option.maximum_pick}</Td>
                <Td align="left">{option.included_pick}</Td>
                <Td align="right" padding={'0px 37px 0px 0px'}>
                  <Switch
                    checked={option.is_active}
                    onChange={() => toggleOptionIsActive(option)}
                    value="checkedB"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Td>
              </Tr>
              {option.sub_options &&
                option.sub_options.length !== 0 &&
                showSubOptions.includes(index) &&
                option.sub_options.map((subOption, subOptionIndex) => (
                  <Tr key={subOptionIndex} style={{ height: '90px' }}>
                    <Td align="left" padding={'0px 0px 0px 37px'}>
                      {subOption.name}
                    </Td>
                    <Td align="left">{subOption.id}</Td>
                    <Td align="left">{subOption.minimum_pick}</Td>
                    <Td align="left">{subOption.maximum_pick}</Td>
                    <Td align="left">{subOption.included_pick}</Td>

                    <Td align="right" padding={'0px 37px 0px 0px'}>
                      <Switch
                        checked={subOption.is_active}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => toggleOptionIsActive(subOption)}
                        value="checkedB"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Td>
                  </Tr>
                ))}
              {option.items &&
                option.items.length !== 0 &&
                showItems.includes(index) &&
                option.items.map((item, itemIndex) => (
                  <Tr key={itemIndex} style={{ height: '90px' }}>
                    <Td align="left" padding={'0px 0px 0px 37px'}>
                      {item.name}
                    </Td>
                    <Td align="left">{item.id}</Td>
                    <Td align="left" />
                    <Td align="left" />
                    <Td align="left" />
                    <Td align="left" />
                    <Td align="right" padding={'0px 37px 0px 0px'}>
                      <Switch
                        checked={subOption.is_active}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => toggleOptionIsActive(subOption)}
                        value="checkedB"
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Td>
                  </Tr>
                ))}
            </Fragment>
          );
        }
      })
    );
  };

  return (
    <Wrapper>
      <TableWithButtonRow>
        <TableWrapper noShadow>
          <Table style={{ borderRight: 'none', borderLeft: 'none' }} noBorder>
            <TBody>
              {renderHeaders()}
              {renderRows()}
            </TBody>
          </Table>
        </TableWrapper>
      </TableWithButtonRow>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...options,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: optionsLength,
          }}
          requestParams={requestParams}
          updateAction={getOptions}
          type="options"
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default OptionsList;
