import React, { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './AppIntegrationActions';
import { LoadingBlueLarge } from 'merchant-portal-components';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import AppIntegration from './components/AppIntegration';

const AppIntegrationContainer = ({
  appIntegrationReducer,
  getAppIntegrationConfig,
  updateAppIntegrationConfig,
}) => {
  const {
    appIntegration,
    requestingAppIntegration,
    appIntegrationPatchSchema,
    delimiter,
  } = appIntegrationReducer;

  useEffect(() => {
    getAppIntegrationConfig(delimiter);
  }, []);

  const handleSubmit = (values) => {
    updateAppIntegrationConfig(values, appIntegrationPatchSchema, delimiter);
  };

  return (
    <Fragment>
      <BreadCrumb />
      {requestingAppIntegration && <LoadingBlueLarge />}
      {!requestingAppIntegration && (
        <AppIntegration
          appIntegration={appIntegration}
          delimiter={delimiter}
          handleSubmit={handleSubmit}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  appIntegrationReducer: state.appIntegrationReducer,
});

export default connect(mapStateToProps, actions)(AppIntegrationContainer);
