import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './variantsActions';
import VariantDetailsContainer from './VariantDetails/VariantDetailsContainer';
import VariantListContainer from './VariantList/VariantListContainer';
const VariantsContainer = ({
  variantCurrentPage,
  variantToEdit,
  componentType,
  //resetToInitialValues,
  selectedMenuManagementChildLocation,
  getVariants,
  history,
  // isVariantAvailable,
  requestParams,
  updateVariantsRequestParams,
}) => {
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    return () => {
      updateVariantsRequestParams({
        ...requestParams,
        page_number: 1,
      });
    };
  }, []);
  useEffect(() => {
    const debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getVariants(newRequestParams);
      }
      updateVariantsRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchString, componentType]);
  return (
    <div>
      {variantCurrentPage === 'list' ? (
        <VariantListContainer
          history={history}
          searchString={searchString}
          setSearchString={setSearchString}
        />
      ) : variantCurrentPage === 'create' ? (
        <VariantDetailsContainer history={history} />
      ) : (
        <VariantDetailsContainer
          history={history}
          variantToEdit={variantToEdit}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  variantCurrentPage: state.variantsReducer.variantCurrentPage,
  variantToEdit: state.variantsReducer.variantToEdit,
  isVariantAvailable: state.variantsReducer.isVariantAvailable,
  requestParams: state.variantsReducer.requestParams,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});
export default connect(mapStateToProps, actions)(VariantsContainer);
