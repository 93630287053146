import React, { Fragment } from 'react';
import { Formik, Field } from 'formik';
import {
  RenderTextField,
  RenderTimePicker,
  RenderSelect,
  RenderRadioGroup,
} from '../../../../../components/FormElements/FormikElements';
import { Button, ButtonRow } from 'merchant-portal-components';
import ChooseImage from '../../../../MediaModal/components/ChooseImage';
import {
  PageTitle,
  BackText,
  Card,
  MainWrapper,
  TitleDiv,
  Title,
  Description,
  MessageDiv,
  ImageDiv,
  MessageAction,
  MessageTiming,
  PreviewDiv,
  SectionTitle,
  TimeWrapper,
} from '../styles/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import TabsComponent from './TabsComponent';
import MessageTokens from './MessageTokens';

// formik validating if form is dirty as well so have to go manual
const isValid = (values) => {
  if (
    values.messages.default.title &&
    values.messages.default.title.length > 256
  ) {
    return false;
  }
  if (
    values.messages.default.message &&
    values.messages.default.message.length > 1024
  ) {
    return false;
  }
  return true;
};

const GenericDetailsTemplate = ({
  data,
  saveMessage,
  isSavingDisabled,
  appIdentifier,
}) => {
  return (
    <div>
      <Card>
        <TitleDiv>
          <Title>{data.notification_type.name}</Title>
          <Description>{data.notification_type.description}</Description>
        </TitleDiv>
        <MainWrapper>
          <Formik initialValues={data} enableReinitialize={true}>
            {({ values, touched, setFieldValue }) => (
              <Fragment>
                <div style={{ paddingRight: '30px' }}>
                  <SectionTitle>Message</SectionTitle>
                  <MessageDiv>
                    <Field
                      name={'messages.default.title'}
                      component={RenderTextField}
                      label="Title"
                      validate={(val) => {
                        if (val && val.length > 256) {
                          return 'Must be 256 characters or less';
                        }
                        return null;
                      }}
                    />
                    <Field
                      name={'messages.default.message'}
                      component={RenderTextField}
                      // required
                      validate={(val) => {
                        if (val && val.length > 1024) {
                          return 'Must be 1024 characters or less';
                        }
                        return null;
                      }}
                      rows={4}
                      label="Message"
                      style={{ position: 'relative' }}
                      InputProps={{
                        endAdornment: (
                          <MessageTokens
                            tokens={data.notification_type.tokens}
                          />
                        ),
                      }}
                    />
                  </MessageDiv>
                  {
                    // hiding for now because order status doesnt have image
                  }
                  {/* <ImageDiv>
                    <SectionTitle>Image (optional)</SectionTitle>
                    <ChooseImage
                      imageUrl={''}
                      setIsImageGallaryVisible={true}
                      isColumn
                      imgWidth={'32px'}
                      imgHeight={'28px'}
                      imgWrapperWidth={'55px'}
                    />
                  </ImageDiv> */}
                  <MessageAction>
                    <SectionTitle>Message Action</SectionTitle>
                    <Field
                      name={'messages.default.push_action_link'}
                      component={RenderSelect}
                      options={[
                        {
                          label: 'Home Page',
                          value: `${appIdentifier}://homefeed`,
                        },
                      ]}
                      // getOptionLabel={category => category.name}
                      // getOptionValue={category => category.id}
                      valueKey="value"
                      placeholder="Select which page mobile users will be directed to"
                    />
                    {
                      // hiding for now because web deep link isnt supported on backend/web
                    }
                    {/* <Field
                      name={`messageAction.pages`}
                      component={RenderSelect}
                      options={[{ id: '1', name: 'test' }]}
                      // getOptionLabel={category => category.name}
                      // getOptionValue={category => category.id}
                      valueKey="id"
                      placeholder="Select which page web users will be directed to"
                    /> */}
                  </MessageAction>

                  {
                    // hiding for now because order status doesnt use it
                  }

                  {/* <MessageTiming>
                    <SectionTitle>Message Timing</SectionTitle>

                    <Field
                      name={`messageAction.pages`}
                      component={RenderSelect}
                      options={[{ id: '1', name: 'test' }]}
                      // getOptionLabel={category => category.name}
                      // getOptionValue={category => category.id}
                      valueKey="id"
                      placeholder="At what threshold do you want to send this message?"
                    />

                    <Field name="message.timing" component={RenderRadioGroup}>
                      <FormControlLabel
                        value="send_immediately"
                        label="Send immediately"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="send_at_optimized_time"
                        label="Send at optimized time"
                        control={<Radio />}
                      />
                      <FormControlLabel
                        value="send_at_specific_time_of_day"
                        label="Send at specific time of day"
                        control={<Radio />}
                      />
                    </Field>

                    <TimeWrapper>
                      <Field
                        name="productInformation.start_time"
                        component={RenderTimePicker}
                        // onChange={}
                        hideDate
                        max={new Date('January 1, 3999 23:30:00')}
                      />
                    </TimeWrapper>
                  </MessageTiming> */}
                </div>

                <PreviewDiv>
                  <SectionTitle>Preview</SectionTitle>

                  <TabsComponent values={values} />
                </PreviewDiv>

                <ButtonRow
                  style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                  <Button
                    disabled={isSavingDisabled || !isValid(values)}
                    onClick={() => saveMessage(values)}
                  >
                    Save
                  </Button>
                </ButtonRow>
              </Fragment>
            )}
          </Formik>
        </MainWrapper>
      </Card>
    </div>
  );
};

export default GenericDetailsTemplate;
