import {
  ROLE_FSO_SUPER_ADMIN,
  ROLE_FSO_ADMIN,
  // ROLE_BUSINESS_SMOOTHPAY,
  ROLE_FSO_OPERATOR,
} from '../../utils/rolesRepository';

import {
  WEB_ORDERING_RAPID_DEPLOYMENT,
  WEB_ORDERING_ESSENTIALS,
  WEB_ORDERING_PLUS,
  MOBILE_APPS_ESSENTIALS,
  MOBILE_APPS_PLUS,
  NO_IMAGE_CLIENT,
  NO_MOBILE_MESSAGES_CLIENT,
  NO_POS_MAPPING_CLIENT,
  NO_LOYALTY_CLIENT,
  NO_COMBOS_CLIENT,
} from './utils/clientPackageRepo';

import apnIcon from '../../../public/img/MobileMessages/apn-icon.png';

import categoriesIcon from '../../../public/img/MenuManagement/categories-icon.png';
import inactiveCategoriesIcon from '../../../public/img/MenuManagement/categories-icon-inactive.png';
import optionsIcon from '../../../public/img/MenuManagement/options-icon.png';
import inactiveOptionsIcon from '../../../public/img/MenuManagement/options-icon-inactive.png';

export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'fal fa-tachometer-alt', //'fa fa-tachometer'//'icon-speedometer',
    //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //   children: [
    //     {
    //       name: 'Users',
    //       url: '/users-dashboard',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //     {
    //       name: 'Transactions',
    //       url: '/transactions-dashboard',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //     {
    //       name: 'Top Locations',
    //       url: '/top-locations-dashboard',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //   ],
    // },
    {
      name: 'Engagement',
      url: '/admin',
      icon: 'fal fa-chart-line',
      authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR],
      children: [
        //     {
        //       name: 'Mobile Messages',
        //       url: '/mobile-messages',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         WEB_ORDERING_PLUS,
        //         MOBILE_APPS_ESSENTIALS,
        //       ],
        //       hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //     {
        //       name: 'Offers',
        //       url: '/offers',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         MOBILE_APPS_ESSENTIALS,
        //       ],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //     {
        //       name: 'Coupons',
        //       url: '/coupon-management',
        //       icon: '',
        //       disableForTiers: [MOBILE_APPS_ESSENTIALS],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //     {
        //       name: 'Segments',
        //       url: '/segments',
        //       icon: 'fal fa-share-alt',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         MOBILE_APPS_ESSENTIALS,
        //       ],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        {
          name: 'Media',
          url: '/media',
          icon: 'fal fa-image',
          hideDisplay: [NO_IMAGE_CLIENT],
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
        },
        //     {
        //       name: 'App Feed',
        //       url: '/app-feed',
        //       icon: 'fal fa-bullhorn',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         WEB_ORDERING_PLUS,
        //       ],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //     {
        //       name: 'Layout Assignment',
        //       url: '/layout-assignment',
        //       icon: 'fal fa-bullhorn',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         WEB_ORDERING_PLUS,
        //       ],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //   ],
        //   notOnSidebar: [
        //     {
        //       name: 'One Time Messages',
        //       url: '/mobile-messages/one-time-messages',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         WEB_ORDERING_PLUS,
        //         MOBILE_APPS_ESSENTIALS,
        //       ],
        //       hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //     {
        //       name: 'Automated Messages',
        //       url: '/mobile-messages/automated-messages',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         WEB_ORDERING_PLUS,
        //         MOBILE_APPS_ESSENTIALS,
        //       ],
        //       hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
        //     {
        //       name: 'Automated Messages',
        //       url: '/mobile-messages/automated-messages/:name',
        //       disableForTiers: [
        //         WEB_ORDERING_RAPID_DEPLOYMENT,
        //         WEB_ORDERING_ESSENTIALS,
        //         WEB_ORDERING_PLUS,
        //         MOBILE_APPS_ESSENTIALS,
        //       ],
        //       hideDisplay: [NO_MOBILE_MESSAGES_CLIENT],
        //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //     },
      ],
    },

    {
      name: 'Menu Management',
      url: '/menu-management',
      icon: 'fal fa-list-alt',
      authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR],
      hideSubMenu: true,
      children: [
        {
          name: 'Categories',
          url: '/categories',
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
          description: 'Manage your menu’s categories and subcategories.',
          icon: categoriesIcon,
          inactiveIcon: inactiveCategoriesIcon,
        },
        {
          name: 'Variants',
          url: '/variants',
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
          description:
            'Establish the different sizes and variations that are available for your products.',
        },

        // {
        //   name: 'Product Options',
        //   url: '/product-options',
        // },
        {
          name: 'Options',
          url: '/options',
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
          description: 'Create and manage customizations of your products.',
          icon: optionsIcon,
          inactiveIcon: inactiveOptionsIcon,
        },
        {
          name: 'Option Price',
          url: '/option-price',
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
          description:
            'Add prices to option items if you want to charge extra for product customizations.',
        },
        {
          name: 'Products',
          url: '/products',
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
          description:
            "Create and manage your menu's products. Assign Categories, Variants, Options, and Businesses to them.",
        },
        // {
        //   name: 'Categories',
        //   url: '/category-manager',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR]
        // },

        // {
        //   name: 'Variants',
        //   url: '/variant-manager',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR]
        // },
        {
          name: 'Combos',
          url: '/combos',
          disableForTiers: [NO_COMBOS_CLIENT],
          authorization: [
            ROLE_FSO_SUPER_ADMIN,
            ROLE_FSO_ADMIN,
            ROLE_FSO_OPERATOR,
          ],
          description:
            'Utilize products in your menu to create combos and give your customers a value deal.',
        },
        {
          name: 'POS Mapping',
          url: '/pos-mapping',
          disableForTiers: [
            WEB_ORDERING_RAPID_DEPLOYMENT,
            WEB_ORDERING_ESSENTIALS,
            MOBILE_APPS_ESSENTIALS,
          ],
          description:
            'Add or update POS mapping IDs for Products, Combos, Items, Offers, and Coupons.',
          hideDisplay: [NO_POS_MAPPING_CLIENT],
          authorization: [
            ROLE_FSO_OPERATOR,
            ROLE_FSO_ADMIN,
            ROLE_FSO_SUPER_ADMIN,
          ],
          // authorizationCallback: appConfig => {
          //   return appConfig && appConfig.pos_integration_enabled;
          // }
        },
        // {
        //   name: 'Upsell',
        //   url: '/upsell',
        //   disableForTiers: [
        //     WEB_ORDERING_RAPID_DEPLOYMENT,
        //     WEB_ORDERING_ESSENTIALS,
        //     MOBILE_APPS_ESSENTIALS,
        //   ],
        //   authorization: [
        //     ROLE_FSO_SUPER_ADMIN,
        //     ROLE_FSO_ADMIN,
        //     ROLE_FSO_OPERATOR,
        //   ],
        // },

        // {
        //   name: 'Combo Management',
        //   url: '/combos',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR]
        // },
        // {
        //   name: 'POS Mapping',
        //   url: '/pos-mapping',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        //   authorizationCallback: appConfig => {
        //     return appConfig && appConfig.pos_integration_enabled;
        //   }
        // }
        // {
        //   name: 'Combo Management',
        //   url: '/combos',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN, ROLE_FSO_OPERATOR]
        // }
        // {
        //   name: 'Nutritional Template',
        //   url: '/nutritional-template',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN]
        // }
        // {
        //   name: 'Upsell',
        //   url: '/upsell',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN]
        // }
        // {
        //   name: 'Pricing Manager',
        //   url: '/pricing-manager',
        // },
        // {
        //   name: 'Calorie Manager',
        //   url: '/calorie-manager',
        // },
        // {
        //   name: 'Location Manager',
        //   url: '/location-manager',
        // }
      ],
    },

    // {
    //   name: 'Customer Service',
    //   url: '/customer-service',
    //   icon: 'fal fa-user-edit',
    //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //   children: [
    //     {
    //       name: 'Order History',
    //       url: '/order-history',
    //       icon: 'fal fa-shopping-cart',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //     {
    //       name: 'Customer Feedback',
    //       url: '/customer-feedback',
    //       icon: 'fal fa-shopping-cart',
    //       disableForTiers: [],
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //     {
    //       name: 'Customers',
    //       url: '/customers',
    //       icon: 'fal fa-user-edit',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //   ],
    //   notOnSidebar: [
    //     {
    //       name: 'Customer Details',
    //       url: '/customers/:customerId',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //     {
    //       name: 'Order Details',
    //       url: '/order-history/:orderId',
    //       authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
    //     },
    //   ],
    // },

    // {
    //   name: 'Finance',
    //   url: '/finance',
    //   icon: 'fal fa-money-bill-alt',
    //   authorization: [
    //     ROLE_FSO_SUPER_ADMIN,
    //     ROLE_FSO_ADMIN,
    //     ROLE_BUSINESS_ADMIN,
    //     ROLE_FINANCE_ADMIN,
    //     ROLE_BUSINESS_SMOOTHPAY,
    //   ],
    //   children: [
    //     {
    //       name: 'Settlements',
    //       url: '/settlements',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_SMOOTHPAY,
    //       ],
    //     },
    //     {
    //       name: 'Transaction Report',
    //       url: '/transaction-report',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_BUSINESS_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_SMOOTHPAY,
    //       ],
    //     },
    //     {
    //       name: 'Loyalty Report',
    //       url: '/loyalty-report',
    //       disableForTiers: [NO_LOYALTY_CLIENT],
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_ADMIN,
    //       ],
    //     },
    //     {
    //       name: 'Product Report',
    //       url: '/product-performance-report',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_ADMIN,
    //       ],
    //     },
    //     {
    //       name: 'Combo Report',
    //       url: '/combo-performance-report',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_ADMIN,
    //       ],
    //     },
    //     {
    //       name: 'Location Report',
    //       url: '/location-transaction-report',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_ADMIN,
    //       ],
    //     },
    //     {
    //       name: 'Customer Report',
    //       url: '/customer-report',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_ADMIN,
    //       ],
    //     },
    //   ],
    //   notOnSidebar: [
    //     {
    //       name: 'Batch Details',
    //       url: '/settlements/:id',
    //       authorization: [
    //         ROLE_FSO_SUPER_ADMIN,
    //         ROLE_FSO_ADMIN,
    //         ROLE_FINANCE_ADMIN,
    //         ROLE_BUSINESS_SMOOTHPAY,
    //       ],
    //     },
    //   ],
    // },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'fal fa-cog',
      authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
      children: [
        // {
        //   name: 'Tags',
        //   url: '/tags',
        //   icon: 'fal fa-tag',
        //   disableForTiers: [
        //     WEB_ORDERING_RAPID_DEPLOYMENT,
        //     WEB_ORDERING_ESSENTIALS,
        //   ],
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        // },
        // {
        //   name: 'Locations',
        //   url: '/locations',
        //   icon: 'fal fa-building',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        // },
        {
          name: 'Portal Users',
          url: '/roles',
          authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
        },
        // {
        //   name: 'App Detail',
        //   url: '/app-detail',
        //   authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN]
        // }
      ],
      // notOnSidebar: [
      //   {
      //     name: 'Locations Detail',
      //     url: '/locations/:businessLocationId',
      //     authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
      //   },
      //   {
      //     name: 'View Delivery Zones',
      //     url: '/locations/:businessLocationId/delivery-zones/view/',
      //     authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
      //   },
      //   {
      //     name: 'Create Delivery Zones',
      //     url: '/locations/:businessLocationId/delivery-zones/create/',
      //     authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
      //   },
      //   {
      //     name: 'Edit Delivery Zone',
      //     url:
      //       '/locations/:businessLocationId/delivery-zones/edit/:deliveryZoneId',
      //     authorization: [ROLE_FSO_SUPER_ADMIN, ROLE_FSO_ADMIN],
      //   },
      // ],
    },

    {
      name: 'Profile',
      url: '/password',
      authorization: [
        ROLE_FSO_SUPER_ADMIN,
        ROLE_FSO_ADMIN,
        // ROLE_BUSINESS_SMOOTHPAY,
      ],
      icon: 'fal fa-user-circle',
    },
    // {
    //   name: 'Super Admin',
    //   url: '/super-admin',
    //   authorization: [ROLE_FSO_SUPER_ADMIN],
    //   icon: 'fal fa-user-circle',
    //   children: [
    //     {
    //       name: 'App Configurations',
    //       url: '/app-detail',
    //       authorization: [ROLE_FSO_SUPER_ADMIN],
    //     },
    //     {
    //       name: 'App Integrations',
    //       url: '/app-integration',
    //       authorization: [ROLE_FSO_SUPER_ADMIN],
    //     },
    //   ],
    // },
  ],
};
