import React from 'react';
import { Modal } from 'merchant-portal-components';
import { Button, ButtonRow } from 'merchant-portal-components';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { FormGrid, FormRow } from 'merchant-portal-components';
import {
  renderSelect,
  renderTextField,
} from '../../../../../components/FormElements/ReduxForm';
import {
  ModalContainerDiv,
  HorizontalLine,
  ModalStyledP,
  ModalGridWrapper,
  ModalBody,
  ContainerDiv,
} from '../../utils/commonStyledComponents';

const StyledSpan = styled.span`
  font-weight: ${(props) => (props.bold ? 'bold' : 'initial')};
`;

const required = (value) => (value ? undefined : 'Required');
const amountOfRewardCredits = (value) =>
  value < 1 || value > 200 ? 'Amount must be between 1-200' : undefined;
const amountOfRewardLoyaly = (value) =>
  value < 1 || value > 100000 ? 'Amount must be between 1-100,000' : undefined;

export const SendLoyaltyModal = (props) => {
  const {
    customerName,
    getTypesOfRewardOptions,
    handleVisibilitySendLoyaltyModal,
    handleSendLoyaltySubmit,
    formValues,
    requestingSendLoyaltyPointsByCustomerId,
  } = props;

  return (
    <Modal fullWidth maxWidth="sm">
      <div className="modal">
        <FormGrid style={{ background: 'white', padding: '20px' }}>
          <ModalStyledP margin="0 0 5px 0">
            You are about to <StyledSpan bold>send a Loyalty Reward</StyledSpan>{' '}
            to <StyledSpan bold>{customerName}</StyledSpan>
          </ModalStyledP>
          <Field
            validate={required}
            name="typeOfReward"
            label="*Type of Reward"
            whiteBackground
            light
            topMod="-24px"
            inLine
            component={renderSelect}
            options={getTypesOfRewardOptions()}
            getOptionValue={(option) => option}
            getOptionLabel={(option) => option.label}
            simpleValue
            valueKey="value"
          />
          <Field
            validate={[
              required,
              formValues && formValues.typeOfReward == 'global-credits'
                ? amountOfRewardCredits
                : amountOfRewardLoyaly,
            ]}
            name="amountOfReward"
            label="*Reward Amount"
            component={renderTextField}
            type="number"
          />
          <Field
            validate={required}
            name="rewardNotes"
            label="*Note"
            component={renderTextField}
            rows={3}
          />
          <ModalContainerDiv center margin="0 auto">
            <ModalStyledP lightBlue center>
              This action cannot be undone
            </ModalStyledP>
            <ModalStyledP fontSize="18px" center>
              Are you sure you want to send ?
            </ModalStyledP>
          </ModalContainerDiv>
          <ButtonRow justifyContent="space-between">
            <Button
              type="button"
              secondary
              onClick={() => handleVisibilitySendLoyaltyModal()}
            >
              CANCEL
            </Button>
            <Button
              type="button"
              disabled={
                requestingSendLoyaltyPointsByCustomerId ||
                !formValues ||
                (formValues &&
                  (!formValues.typeOfReward ||
                    !formValues.amountOfReward ||
                    !formValues.rewardNotes ||
                    (formValues.typeOfReward == 'global-credits'
                      ? formValues.amountOfReward < 1 ||
                        formValues.amountOfReward > 200
                      : formValues.amountOfReward < 1 ||
                        formValues.amountOfReward > 100000)))
              }
              onClick={() => handleSendLoyaltySubmit(formValues)}
            >
              {requestingSendLoyaltyPointsByCustomerId
                ? 'SENDING...'
                : 'YES, SEND'}
            </Button>
          </ButtonRow>
        </FormGrid>
      </div>
    </Modal>
  );
};

let SendLoyaltyModalForm = reduxForm({
  form: 'sendLoyaltyModalForm',
})(SendLoyaltyModal);

SendLoyaltyModalForm = connect((state) => {
  return {
    initialValues: { typeOfReward: 'global-credits' },
    formValues: getFormValues('sendLoyaltyModalForm')(state),
  };
})(SendLoyaltyModalForm);

export default SendLoyaltyModalForm;
