import { TextField } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React, { useState } from 'react';
import {
  TimePicker,
  ErrorMessage,
  Label,
  StyledDateTimePicker,
} from 'merchant-portal-components';
import { Select } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import RadioGroup from '@material-ui/core/RadioGroup';
import { SingleDatePicker } from 'react-dates';
import { ALL_ORDER_TYPE_ID } from '../../modules/MenuManagement/Products/productsTypes';
import moment from 'moment';

const priceRegex = /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/;

const getError = (touched, errors, fieldName) => {
  if (!fieldName) return;
  let arrayFieldName = fieldName.split('.');
  let touchedAccess = touched;
  let errorsAccess = errors;
  arrayFieldName.forEach((name) => {
    if (touchedAccess) {
      touchedAccess = touchedAccess[name];
    }
    if (errorsAccess) {
      errorsAccess = errorsAccess[name];
    }
  });
  return touchedAccess && errorsAccess;
};

export const RenderCheckBox = ({
  field,
  form,
  label,
  formValues,
  options,
  error,
  ...rest
}) => {
  const { setFieldValue } = form;

  const handleChange = (value) => {
    let formValue = field.value;

    if (formValue) {
      if (
        formValue.includes(ALL_ORDER_TYPE_ID) &&
        value === ALL_ORDER_TYPE_ID
      ) {
        formValue = formValue.filter((fValue) => fValue !== value);
      } else if (
        formValue.includes(ALL_ORDER_TYPE_ID) ^
        (value === ALL_ORDER_TYPE_ID)
      ) {
        formValue = options.map((option) => option.value);
      } else if (formValue.includes(value)) {
        formValue = formValue.filter((fValue) => fValue !== value);
      } else {
        formValue = [...formValue, value];
      }
    }
    setFieldValue(field.name, formValue);
  };

  return (
    <div>
      {options.map((option, index) => (
        <div key={index} style={{ display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            name={option.label}
            onChange={() => handleChange(option.value)}
            checked={field.value.includes(option.value)}
            color="primary"
            style={{
              marginLeft: 0,
              marginBottom: '14px',
              marginRight: '14px',
              padding: 0,
            }}
            {...rest}
          />
          <span
            style={
              option.value === ALL_ORDER_TYPE_ID ? { fontWeight: 600 } : null
            }
          >
            {option.label}
          </span>
        </div>
      ))}
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
};

export const RenderTextField = ({
  field,
  form: { touched, errors },
  error,
  isPrice,
  ...props
}) => {
  const value =
    field.value === 0
      ? field.value
      : props.value === 0
      ? props.value
      : field.value || props.value || '';
  const errorMessage = getError(touched, errors, field.name) || error;
  const inputProps = {};
  if (props.min !== undefined) {
    inputProps.min = props.min;
  }
  return (
    <div>
      <TextField
        type={isPrice ? 'number' : 'text'}
        {...field}
        {...props}
        value={value}
        error={errorMessage && errorMessage != ''}
        variant="outlined"
        // autoComplete={props.autoComplete ? props.autoComplete : 'off'}
        style={{ ...props.style, width: '100%' }}
        multiline={props.rows ? true : false} // it strictly expects a boolean. rows is int
        rows={props.rows || 1}
        //InputLabelProps={{ touched: field.name ? true : false }}
        min={0}
        InputProps={{ ...props.InputProps, inputProps: inputProps }}
        InputLabelProps={{
          shrink:
            !(
              field.value === undefined ||
              field.value === null ||
              field.value === ''
            ) || field.touched,
        }}
        onChange={(e) => {
          if (isPrice) {
            if (
              e.target.value === null ||
              e.target.value === '' ||
              priceRegex.test(e.target.value)
            ) {
              field.onChange && field.onChange(e);
              props.onChange && props.onChange(e);
            }
          } else {
            field.onChange && field.onChange(e);
            props.onChange && props.onChange(e);
          }
        }}
        // onChange={e => {
        //   if (isPrice) {
        //     if (priceRegex.test(e.target.value)) {
        //       field.onChange(e);
        //     }
        //   } else {
        //     field.onChange(e);
        //   }
        // }}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </div>
  );
};

export const RenderTimePicker = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  let value = field.value;
  if (value) {
    value = new Date(value);
  } else {
    value = new Date();
  }

  return (
    <div>
      <TimePicker
        {...field}
        {...props}
        value={value}
        //min={new Date()}
        // onChange={onChange}
        dropUp
        step={props.step || 30}
        date={!props.hideDate}
        inputProps={{
          component: (props) => <input {...props} readOnly />,
        }}
        min={props.min ? props.min : new Date('January 1, 1969 00:00:00')}
        max={props.max ? props.max : new Date('January 1, 3999 23:30:00')}
      />
    </div>
  );
};

export const RenderSelect = ({
  field,
  form: { touched, errors, setFieldValue, setTouched },
  label,
  options,
  isMulti,
  valueKey,
  isDisabled,
  disabled,
  optionsSelectionDisabled,
  getValueOnChange,
  error = '',
  placeholder,
  ...rest
}) => {
  const setValue = (value) => {
    let newValue = isMulti
      ? value.map((item) => item[valueKey || 'value'])
      : value[valueKey || 'value'];

    setFieldValue(field.name, newValue);
    getValueOnChange && getValueOnChange(newValue);
  };
  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) => field?.value?.indexOf(option[valueKey || 'value']) >= 0
          )
        : options.find((option) => option[valueKey || 'value'] === field.value);
    } else {
      return isMulti ? [] : '';
    }
  };
  const errorMessage = getError(touched, errors, field.name);
  return (
    <div>
      {label && <Label>{label}</Label>}
      <Select
        // onChange={e => {
        //   rest.onChange && rest.onChange(e);
        //   setValue(e);
        // }}
        {...field}
        {...rest}
        placeholder={placeholder}
        isDisabled={isDisabled}
        error={errorMessage}
        touched={touched}
        // value={getValue()}
        // value={getValue() || ''}
        // value={!optionsSelectionDisabled && getValue()}
        setTouched={setTouched}
        value={!optionsSelectionDisabled ? getValue() : ''}
        options={options}
        isMulti={isMulti}
        onChange={(e) => {
          if (!optionsSelectionDisabled) {
            rest.onChange && rest.onChange(e);
            field.onChange && field.onChange(e);
            setValue(e);
          }
        }}
      />
      <ErrorMessage> {touched && error} </ErrorMessage>
    </div>
  );
};

export const RenderSingleDatePicker = ({ field, form, ...rest }) => {
  const [focused, setFocused] = useState(false);

  return (
    <StyledDateTimePicker
      time={false}
      dropUp={true}
      defaultValue={new Date(moment(field.value, 'YYYY-MM-DD'))}
      value={new Date(moment(field.value, 'YYYY-MM-DD'))}
      onChange={(date) => {
        field.onChange(moment(date).format('YYYY-MM-DD'));
        form.setFieldValue(field.name, moment(date).format('YYYY-MM-DD'));
      }}
      date={true}
      inputProps={{
        component: (props) => <input {...props} readOnly />,
      }}
    />
  );
};
export const RenderDateTimePicker = ({ field, form, ...props }) => {
  const [focused, setFocused] = useState(false);

  return (
    <StyledDateTimePicker
      disabled={props.disabled}
      dropUp={true}
      defaultValue={
        props.defaultValue
          ? props.defaultValue
          : moment(moment(field.value).format('YYYY-MM-DD HH:mm:ss')).toDate()
      }
      value={moment(moment(field.value).format('YYYY-MM-DD HH:mm:ss')).toDate()}
      onChange={(date) => {
        field.onChange(moment(date).format('YYYY-MM-DD HH:mm:ss'));
        form.setFieldValue(
          field.name,
          moment(date).format('YYYY-MM-DD HH:mm:ss')
        );
      }}
      date={true}
      step={15}
      inputProps={{
        component: (props) => <input {...props} readOnly />,
      }}
      min={props.min ? props.min : new Date('January 1, 1969 00:00:00')}
      max={props.max ? props.max : new Date('January 1, 3999 23:30:00')}
    />
  );
};

export const RenderToggleSwitch = ({
  field,
  form: { touched, error },
  ...props
}) => {
  return (
    <div style={props.style}>
      <SmallToggleSwitch checked={field.value} {...props} />
    </div>
  );
};

export const RenderRadioGroup = ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    value={input}
    onChange={(event) => input.onChange(event.target.value)}
  />
);

export const required = (value) =>
  value || value === 0 ? undefined : 'Required';

export const validateTwoChar = (value) => {
  const minLength = 2;
  let error;
  if (value.length < minLength) {
    error = `Required at least ${minLength} characters`;
  }
  return error;
};
export const maxLengthValidationForNameField = (value) => {
  let error = null;
  const maxLength = 50;
  if (!value) error = 'Required';
  if (value.length > maxLength) {
    error = `Cannot be more than ${maxLength} characters`;
  }

  return error;
};
export const urlValidator = (value) => {
  let error = null;

  if (value && !value.toLowerCase().startsWith('http://')) {
    error = `Enter a valid url`;
  }

  return error;
};
export const bulkValidator =
  (...args) =>
  (value) => {
    for (const validator of args) {
      const error = validator(value);

      if (error) {
        return error;
      }
    }

    return undefined;
  };

export const isNumberValidation = (val) => {
  let error;
  if (!val) error = 'Required';
  if (!new RegExp(`^[0-9]*$`).test(val)) {
    error = 'Must be a valid number';
  }
  if (parseInt(val) == 0) {
    error = 'Must be greater than 0';
  }
  return error;
};

export const pointsCostValidation = (val) => {
  let error;
  if (!val) error = 'Required';
  if (parseInt(val) <= 0) {
    error = 'Must be greater than 0';
  }
  return error;
};

export const isPriceValidation = (val) => {
  let error;
  if (!val) error = 'Required';
  if (val == 0) error = 'Must be greater than 0';
  if (isNaN(val)) error = 'Must be a valid number';
  if (val < 0) error = 'Must be greater than zero';
  return error;
};

export const isComboIncludedOptionValidation = (val) => {
  let error;
  if (!new RegExp(`^[0-9]*$`).test(val)) {
    error = 'Must be a valid number';
  }
  if (!val) error = null;
  return error;
};

export const isExtraChargePriceValidation = (val) => {
  if (!val || val === undefined || val === '') return null;
  if (val <= 0) return 'Must be greater than 0';
  if (isNaN(val)) return 'Must be a valid number';
};

export const isAlphabetValidation = (val) => {
  let error = null;
  if (!new RegExp(`^[A-Za-z]*( )?[A-Za-z]*$`).test(val)) {
    error = 'Must be alphabets';
  }
  if (!val) error = 'Required';
  return error;
};

export const isLatLng = (val) => {
  let error;
  if (!new RegExp(`[+-]?([0-9]*[.])[0-9]+`).test(val)) {
    error = 'Must be valid lat or lng';
  }
  if (!val) error = 'Required';
  return error;
};

export const isValidPostalCode = (state) => (value) => {
  let error;
  if (!value) {
    error = 'Required';
  } else {
    switch (state) {
      case 'CA':
        if (!new RegExp(/^[A-Za-z]\d[A-Za-z]( )?\d[A-Za-z]\d$/).test(value)) {
          error = 'Must be valid postal code';
        }
        break;
      case 'US':
        if (!new RegExp(`^\\d{5}(-{0,1}\\d{4})?$`).test(value)) {
          error = 'Must be valid postal code';
        }
        break;
    }
  }
  return error;
};
