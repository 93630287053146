import React, { useState } from 'react';
import {
  ParamsWrapper,
  DatePickerLabel,
  DatePickerWrapper,
} from 'merchant-portal-components';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from 'moment';

function ReportDateFilter(props) {
  const { requestParams } = props;

  const [maxStartDate, setMaxStartDate] = useState(new Date());

  const updateDateParam = (paramName, date) => {
    let formattedDate;

    if (paramName === 'start_datetime') {
      const time = '00:00:00';
      formattedDate = `${Moment(date).format('YYYY-MM-DD')} ${time}`;
    } else if (paramName === 'end_datetime') {
      const time = '23:59:00';
      formattedDate = `${Moment(date).format('YYYY-MM-DD')} ${time}`;
    } else {
      formattedDate = Moment(date).format('YYYY-MM-DD');
    }

    props.updateRequestParams({
      ...requestParams,
      [paramName]: formattedDate,
    });
  };

  return (
    <DatePickerWrapper>
      <div>
        <DatePickerLabel>Start Date</DatePickerLabel>
        <DateTimePicker
          defaultValue={new Date(Moment().subtract(1, 'days'))}
          time={false}
          max={maxStartDate}
          onChange={(value) => {
            const start =
              Object.keys(requestParams).filter(
                (data) => data === 'start_datetime'
              ).length > 0
                ? 'start_datetime'
                : 'start_date';
            updateDateParam(start, value);
          }}
        />
      </div>
      <div>
        <DatePickerLabel>End Date</DatePickerLabel>
        <DateTimePicker
          defaultValue={new Date()}
          time={false}
          max={new Date()}
          onChange={(value) => {
            const end =
              Object.keys(requestParams).filter(
                (data) => data === 'end_datetime'
              ).length > 0
                ? 'end_datetime'
                : 'end_date';
            updateDateParam(end, value);
            setMaxStartDate(value);
          }}
        />
      </div>
    </DatePickerWrapper>
  );
}

export default ReportDateFilter;
