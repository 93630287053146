import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, FieldArray } from 'formik';
// import * as Yup from 'yup';
import { Collapse } from '@material-ui/core';
import ChevronDownIcon from '../../../../../../components/Icon/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/Icon/ChevronUpIcon';
import { Button, ButtonRow } from 'merchant-portal-components';
import { EditIcon } from 'merchant-portal-components';
import RenderProductVariantsFieldArray from './RenderProductVariantsFieldArray';
import VariantSummary from './VariantSummary';
import ConfirmCancelModal from '../../components/ConfirmCancelModal';
import { SCAN_AND_GO } from '../../../productsTypes';

const Card = styled.div`
  background: ${(props) => (props.disabled ? '#ebebeb' : 'white')};
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  padding: 32px 48px;
  color: ${(props) => (props.disabled ? '#959595' : 'initial')};
  label {
    z-index: 0;
  }
`;

const TitleAndCollapseButton = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 18px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 21px;
`;

const EditVariantWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px 16px;
  grid-gap: 10px;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
`;

const variantsAreValid = (productVariants, shouldValidateBarcode) => {
  const priceIsValid = productVariants.every(
    (variant) =>
      variant.price && variant.product_variant_id && !isNaN(variant.price)
  );
  const isDefaultSelected = productVariants.some(
    (variant) => variant.is_default
  );

  let isBarcodeValid = true;

  if (shouldValidateBarcode) {
    isBarcodeValid = productVariants.every(
      (variant) => variant.barcode && !isNaN(variant.barcode)
    );
  }
  return priceIsValid && isDefaultSelected;
};

// const validationSchema = Yup.object().shape({
//   productVariants: Yup.array()
//     .of(
//       Yup.object().shape({
//         price: Yup.string()
//           .length(2, 'LAALA')
//           .required('Required')
//       })
//     )
//     .min(1, 'Must have at least one variant')
// });

let ProductVariantsForm = ({
  disabled,
  isExpanded,
  setIsExpanded,
  initialValues,
  productVariantsAreEditable,
  setProductVariantsAreEditable,
  updateProductVariantsInitialValues,
  allVariants,
  putProductVariants,
  setConfirmCancelModalIsVisible,
  confirmCancelModalIsVisible,
  productId,
  appConfig,
  posMappingValues,
  orderType,
}) => {
  return (
    <Card
      disabled={disabled}
      onClick={() => !disabled && setIsExpanded(!isExpanded)}
    >
      <Formik initialValues={initialValues} enableReinitialize={true}>
        {({ values, dirty, setValues }) => (
          <Fragment>
            <TitleAndCollapseButton>
              <Title>Variants And Price</Title>
              {!isExpanded ? (
                <ChevronDownIcon
                  disabled={disabled}
                  onClick={() => setIsExpanded(true)}
                />
              ) : (
                <ChevronUpIcon
                  disabled={dirty}
                  onClick={() => setIsExpanded(false)}
                />
              )}
            </TitleAndCollapseButton>
            {!isExpanded && (
              <VariantSummary
                productVariantsInitialValues={initialValues.productVariants}
                allVariants={allVariants}
              />
            )}
            <Collapse in={isExpanded}>
              <div style={{ display: 'grid', gridGap: '25px' }}>
                {values.productVariants.length !== 0 && (
                  <EditVariantWrapper>
                    <div>Edit Variants</div>
                    <EditIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setProductVariantsAreEditable(
                          !productVariantsAreEditable
                        );
                      }}
                    />
                  </EditVariantWrapper>
                )}
                <FieldArray
                  name="productVariants"
                  render={(arrayHelpers) => {
                    return (
                      <RenderProductVariantsFieldArray
                        arrayHelpers={arrayHelpers}
                        productVariantsAreEditable={productVariantsAreEditable}
                        allVariants={allVariants}
                        updateProductVariantsInitialValues={
                          updateProductVariantsInitialValues
                        }
                        productId={productId}
                        appConfig={appConfig}
                        posMappingValues={posMappingValues}
                        orderType={orderType}
                      />
                    );
                  }}
                />
                <ButtonRow justifyContent="space-between">
                  <Button
                    secondary
                    onClick={(e) => {
                      e.stopPropagation();
                      dirty
                        ? setConfirmCancelModalIsVisible(true)
                        : setIsExpanded(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !dirty ||
                      !values.productVariants.length ||
                      !variantsAreValid(
                        values.productVariants,
                        orderType.includes(SCAN_AND_GO)
                      )
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(false);
                      putProductVariants(values.productVariants);
                    }}
                  >
                    Save
                  </Button>
                </ButtonRow>
                {confirmCancelModalIsVisible && (
                  <ConfirmCancelModal
                    onCancel={() => setConfirmCancelModalIsVisible(false)}
                    onConfirm={() => {
                      setValues(initialValues);
                      setConfirmCancelModalIsVisible(false);
                      setIsExpanded(false);
                    }}
                  />
                )}
              </div>
            </Collapse>
          </Fragment>
        )}
      </Formik>
    </Card>
  );
};
export default ProductVariantsForm;
