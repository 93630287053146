import {
  IS_SIGNING_IN,
  AUTH_USER,
  UNAUTH_USER,
  SESSION_EXPIRED_UNAUTH_USER,
  AUTH_ERROR,
  FETCH_MESSAGE,
  BUSINESS_INFO,
  USER_ROLE,
  RESET_PASSWORD_FORM,
  ERROR_MESSAGE,
  PROMPT_REAUTH,
  UPDATE_CREATED_AT_TIMER,
  SIGN_IN_FAILURE,
  PASSWORD_RESET_REQUEST_SENT,
} from './types';

export default function (
  state = {
    token: localStorage.getItem('token'),
    displayName: localStorage.getItem('displayName'),
    role: localStorage.getItem('role'),
    isSigningIn: false,
    shouldClearOnError: false,
    errorMessage: '',
    appIdentifier: localStorage.getItem('appIdentifier'),
    appMetaData: JSON.parse(localStorage.getItem('appMetaData')),
    createdAt: localStorage.getItem('createdAt'),
    username: localStorage.getItem('username'),
    shouldPromptReauth: false,
    initialFormValues: { email: '', password: '' },
    resetPasswordRequestSent: false,
    posIntegrationEnabled: false,
    sessionErrors: false,
  },
  action
) {
  switch (action.type) {
    case 'UPDATE_REFRESH_SESSION_ERRORS':
      return {
        ...state,
        sessionErrors: action.sessionErrors,
      };
    case IS_SIGNING_IN:
      return {
        ...state,
        isSigningIn: true,
        shouldClearOnError: false,
        initialFormValues: {
          email: action.loginFormEmail,
          password: action.loginFormPassword,
        },
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        initialFormValues: { ...state.initialFormValues, password: '' },
      };
    case AUTH_USER:
      return {
        ...state,
        error: '',
        token: action.token,
        isSigningIn: false,
        reauthenticate: false,
        shouldPromptReauth: false,
      };
    case UNAUTH_USER:
      return {
        ...state,
        error: '',
        token: false,
        displayName: false,
        role: false,
        appIdentifier: false,
        createdAt: false,
      };
    case SESSION_EXPIRED_UNAUTH_USER:
      return {
        ...state,
        error: '',
        token: false,
        displayName: false,
        reauthenticate: action.reauthenticate,
        role: false,
        appIdentifier: false,
        createdAt: false,
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        isSigningIn: false,
        shouldClearOnError: false,
      };
    case FETCH_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case BUSINESS_INFO:
      return {
        ...state,
        businessLocations: action.response.locations,
        displayName: action.response.display_name,
        appIdentifier: action.response.app_identifier,
        appMetaData: action.response.app_detail,
        username: action.username,
        posIntegrationEnabled: action.posIntegrationEnabled,
      };
    case USER_ROLE:
      return {
        ...state,
        role: action.response,
      };
    case RESET_PASSWORD_FORM:
      return {
        ...state,
        shouldClearOnError: true,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.message,
      };
    case PROMPT_REAUTH:
      return {
        ...state,
        shouldPromptReauth: true,
      };
    case UPDATE_CREATED_AT_TIMER:
      return {
        ...state,
        createdAt: action.createdAt,
      };
    case PASSWORD_RESET_REQUEST_SENT:
      return {
        ...state,
        [action.key]: action.value,
      };
  }

  return state;
}
