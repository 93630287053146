import React from 'react';
import styled from 'styled-components';
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  padding: 12px;
  padding-left:0px;
  align-items: center;
  margin-top: ${props => props.marginTop || 'inherit'};
`;
const OverrideWrapper = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 0.5fr;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
`;
const HeadOption = styled.div`
    font-weight: 600;
    font-size: 21px;
    margin-bottom:10px;

    span{
        font-weight:normal;
    }
`;
const ContentOption = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 0.5fr;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
`;
const Button = styled.div`
    background:#2440ae;
    font-size:17px;
    color:#fff;
    padding: 15px;
    border-radius: 40px;
    text-align: center;
    width: auto;
    font-weight: 600;
    min-width: 190px;
`;

const OptionOverridesContainer = () => {
  return (
    <Wrapper>
      <OverrideWrapper>
        <div>
            <HeadOption>Overrides <span>(Optional)</span></HeadOption>
            <ContentOption>Override item prices and availabilty for specific locations</ContentOption>
        </div>
        <div>
          <Button>Add Override</Button>
        </div>
      </OverrideWrapper>
    </Wrapper>
  );
};

export default OptionOverridesContainer;
