import React from 'react';
import styled from 'styled-components';
import { Select } from 'merchant-portal-components';
import { formatLocations } from '../../../../utils/childLocations/formatLocations';

const LocationSelect = styled.div`
  width: 450px;
  min-width: 450px;
  max-width: 450px;
  margin-top: 13px;
`;

function ReportLocationFilter(props) {
  const { businesses, requestParams } = props;

  const updateLocationParam = (paramName, locations) => {
    const locationsUpdated = locations.map((location) => location.id);

    props.updateRequestParams({
      ...requestParams,
      [paramName]: locationsUpdated,
    });
  };

  return (
    <LocationSelect>
      <Select
        isMulti
        checkbox
        // size="small"
        options={formatLocations(businesses)}
        getOptionLabel={(business) => business.name}
        getOptionValue={(business) => business}
        onChange={(value) => updateLocationParam('business_ids', value)}
        label="Filter by location"
        light
      />
    </LocationSelect>
  );
}

export default ReportLocationFilter;
