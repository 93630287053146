import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import { RenderSelect } from '../../../../../components/FormElements/FormikElements';
import { Modal } from 'merchant-portal-components';
import { HrLine } from 'merchant-portal-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';

const ModalBody = styled.div`
  min-width: 800px;
  padding: 20px;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Notes = styled.div`
  margin-top: 1em;
`;

const ProductImage = styled.img`
  width: 100px;
  background-color: #d3d3d3;
`;
const StyledTd = styled(Td)`
  border: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const renderHeaders = () => {
  return (
    <TrHead>
      <Th align="center" width="50" />
      <Th align="left">Product Name</Th>
      <Th align="center">New Sub Category</Th>
    </TrHead>
  );
};

const ProductCategoryAssignmentModal = ({
  productsAssociatedToCategory,
  categoryValues,
  setProductCategoryAssignmentModalIsVisible,
  handleCategorySave,
  categoryInitialValues,
}) => {
  const initialValues = {
    products: productsAssociatedToCategory.map((product) => ({
      id: product.id,
      name: product.name,
      image_url: product.image_url,
      associatedCategories: product.associatedCategories,
      subCategoryIndex: null,
    })),
  };

  return (
    <Formik initialValues={initialValues}>
      {({
        values,
        handleReset,
        dirty,
        errors,
        setFieldValue,
        handleSave,
        touched,
      }) => (
        <Modal bodyScroll>
          <ModalBody>
            <Title>New Product Category Assignment</Title>
            <Notes>
              Your category was assigned to at least one product. If a category
              has sub categories, you must assign the sub categories to a
              product. Please select a new sub category for the following
              products
            </Notes>
            <HrLine />

            <Table noBorder>
              <TBody>
                {renderHeaders()}

                {values.products.map((product, index) => (
                  <Tr key={index}>
                    <StyledTd p align="center">
                      <ProductImage
                        src={product?.image_url}
                        alt={product?.name}
                      />
                    </StyledTd>
                    <StyledTd align="left" bold>
                      {product.name}
                    </StyledTd>
                    <StyledTd>
                      <Field
                        name={`products[${index}].subCategoryIndex`}
                        component={RenderSelect}
                        options={categoryValues.categorySubCategories.map(
                          (category, index) => ({ ...category, index: index })
                        )}
                        getOptionLabel={(category) => category.name}
                        getOptionValue={(category) => category.index}
                        valueKey="index"
                        placeholder="Select SubCategory*"
                      />
                    </StyledTd>
                  </Tr>
                ))}
              </TBody>
            </Table>
            <ButtonWrapper>
              <Button
                secondary
                onClick={() =>
                  setProductCategoryAssignmentModalIsVisible(null, false)
                }
              >
                Discard
              </Button>

              <Button
                disabled={
                  values.products.filter(
                    (product) => product.subCategoryIndex == null
                  ).length
                }
                onClick={() =>
                  handleCategorySave(
                    categoryValues,
                    categoryInitialValues,
                    values.products
                  )
                }
              >
                Save
              </Button>
            </ButtonWrapper>
          </ModalBody>
        </Modal>
      )}
    </Formik>
  );
};

export default ProductCategoryAssignmentModal;
