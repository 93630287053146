import {
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST,
  GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
  PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
  IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN,
  SPECIAL_HOURS_TO_DELETE,
  DELETE_SPECIAL_HOURS_REQUEST,
  DELETE_SPECIAL_HOURS_SUCCESS,
  IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN,
  SPECIAL_HOURS_TO_EDIT,
  UPDATE_SPECIAL_HOURS_REQUEST,
  UPDATE_SPECIAL_HOURS_SUCCESS,
  RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE,
  SET_BUSINESS_WIZARD_IS_VISIBLE,
  CREATE_BUSINESS_LOCATION_REQUEST,
  CREATE_BUSINESS_SUCCESS,
  POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS,
  PUT_REGULAR_HOURS_SUCCESS,
  INITIATE_CREATE_LOCATION,
  INITIATE_EDIT_LOCATION,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_SPECIAL_HOURS_FAILURE,
  PUT_REGULAR_HOURS_FAILURE,
  GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS,
  GET_PAYMENT_INTEGRATION_SUCESS,
  UPDATE_PAYMENT_INTEGRATION_SUCESS,
  SET_MENU_MANAGEMENT_SELECTED_CHILD_LOCATION,
  SET_MENU_MANAGEMENT_SELECTED_PARENT_LOCATION,
} from './types';
import { getBusinessLocationsOfMerchant } from '../LocationListManager/actions';
import api from '../../../api';
import { createAlert } from '../../Alert/actions';
import Moment from 'moment';

import {
  formatValuesForPatch,
  formatPostSpecialHour,
  formatPatchSpecialHour,
  formatRegularHours,
  formatPutRegularHours,
  constructBusinessLocationFormat,
} from './utils/actionsFormatters';
import { getOptions } from '../../MenuManagement/OptionManager/optionActions';
import { getVariants } from '../../MenuManagement/Variants/variantsActions';

/*
 * API HANDLERS
 */

export function getBusinessLocationDetailsByBusinessId(businessLocationId) {
  let formattedResponse;

  return function (dispatch) {
    dispatch({ type: GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_REQUEST });
    return getBusinessLocationToggles(businessLocationId)
      .then((response) => {
        formattedResponse = constructBusinessLocationFormat(response.data.data);

        Promise.all([
          getBusinessLocationAddress(businessLocationId),
          getBusinessLocationDetails(businessLocationId),
        ])
          .then((addressResponse) => {
            formattedResponse = formattedResponse(
              addressResponse[0].data.data,
              addressResponse[1].data.data
            );

            Promise.all([
              getBusinessLocationRegularHours(businessLocationId),
              getBusinessLocationSpecialHours(businessLocationId),
            ]).then((hoursResponse) => {
              formattedResponse = formattedResponse(hoursResponse[0].data.data)(
                hoursResponse[1].data.data
              );
              dispatch({
                type: GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
                payload: formattedResponse,
              });
            });
          })
          .catch((error) => {
            formattedResponse = formattedResponse(null, null)(null)(null);
            dispatch({
              type: GET_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
              payload: formattedResponse,
            });
            dispatch({
              type: INITIATE_CREATE_LOCATION,
              data: formattedResponse,
            });
          });
      })
      .catch((error) => {
        dispatch(
          createAlert({
            type: 'error',
            message: error,
          })
        );
        console.log(error);
      });
  };
}

function getBusinessLocationToggles(businessLocationId) {
  return api.get(`businesses/${businessLocationId}`);
}
function getBusinessLocationAddress(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/address`);
}

function getBusinessLocationDetails(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/detail`);
}

function getBusinessLocationRegularHours(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/hours`);
}

function getBusinessLocationSpecialHours(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/hours-overrides`);
}
function getBusinessPaymentIntegrationDetails(businessLocationId) {
  return api.get(`businesses/${businessLocationId}/integrations`);
}

function patchBusinessLocationToggles(businessLocationId, values) {
  return api.patch(`businesses/${businessLocationId}`, values);
}

function patchBusinessLocationDetails(businessLocationId, values) {
  return api.patch(`businesses/${businessLocationId}/detail`, values);
}

function putBusinessLocationRegularHours(businessLocationId, values) {
  return api.put(`businesses/${businessLocationId}/hours`, { hours: values });
}

export function getBusinessPaymentIntegrationSchema() {
  return (dispatch) => {
    api.get(`business/integration/configuration`).then((response) => {
      dispatch({
        type: GET_PAYMENT_INTEGRATION_SCHEMA_SUCESS,
        schema: response.data.data.reduce(
          (acc, data) => (acc = { ...acc, ...data }),
          {}
        ),
      });
    });
  };
}

export function updatePaymentIntegration(businessId, values) {
  let payload = {
    integration: Object.keys(values).reduce((acc, paymentType) => {
      return acc.concat({
        type: paymentType,
        provider: values[paymentType].provider,
        configuration: { ...values[paymentType].configuration },
      });
    }, []),
  };

  return (dispatch) => {
    api
      .put(`businesses/${businessId}/integration`, payload)
      .then((response) => {
        dispatch({
          type: UPDATE_PAYMENT_INTEGRATION_SUCESS,
          paymentIntegration: values,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Payment Integration updated successfully',
          })
        );
      })
      .catch((error) => console.log(error));
  };
}

export function getPaymentIntegration(businessId) {
  return (dispatch) => {
    api.get(`businesses/${businessId}/integrations`).then((response) => {
      dispatch({
        type: GET_PAYMENT_INTEGRATION_SUCESS,
        paymentIntegration: response.data.data.reduce((acc, data) => {
          acc[data.type] = data;
          return acc;
        }, {}),
      });
    });
  };
}

export function initiateDeleteSpecialHours(
  businessLocationId,
  specialHoursToDelete
) {
  return (dispatch) => {
    dispatch({
      type: DELETE_SPECIAL_HOURS_REQUEST,
    });
    let toDelete = specialHoursToDelete.map((specialHourToDelete) =>
      deleteSpecialHour(businessLocationId, specialHourToDelete)
    );
    Promise.all(toDelete).then((responses) => {
      dispatch({
        type: DELETE_SPECIAL_HOURS_SUCCESS,
      });
      dispatch(
        createAlert({
          type: 'success',
          message: 'Special Hours deleted successfully',
        })
      );
      dispatch(getBusinessLocationDetailsByBusinessId(businessLocationId));
    });
  };
}

export function initiateUpdateSpecialHours(
  businessLocationId,
  postSpecialHours,
  patchSpecialHours,
  deleteSpecialHours
) {
  return (dispatch) => {
    let hoursError = new Set();
    postSpecialHours.map((specialHourToPost) => {
      if (specialHourToPost.orderAheadIsOpen) {
        if (
          Moment(specialHourToPost.orderAheadOpenTime).isBefore(
            specialHourToPost.openTime
          ) ||
          Moment(specialHourToPost.orderAheadCloseTime).isAfter(
            specialHourToPost.closeTime
          )
        ) {
          hoursError.add(specialHourToPost.dayName);
        }
      }
    });
    patchSpecialHours.map((patchSpecialHour) => {
      if (patchSpecialHour.orderAheadIsOpen) {
        if (
          Moment(patchSpecialHour.orderAheadOpenTime).isBefore(
            patchSpecialHour.openTime
          ) ||
          Moment(patchSpecialHour.orderAheadCloseTime).isAfter(
            patchSpecialHour.closeTime
          )
        ) {
          hoursError.add(patchSpecialHour.dayName);
        }
      }
    });
    if (Array.from(hoursError).length > 0) {
      dispatch(
        createAlert({
          type: 'error',
          message: `Order Ahead Hours should be between Regular Hours.
                    Error in days - ${Array.from(hoursError).map(
                      (error) => ` ${error} `
                    )}`,
        })
      );
    } else {
      dispatch({
        type: UPDATE_SPECIAL_HOURS_REQUEST,
      });
      let formattedPostSpecialHours = postSpecialHours.map(
        (specialHourToPost) =>
          postSpecialHour(
            businessLocationId,
            formatPostSpecialHour(specialHourToPost)
          )
      );
      let formattedPatchSpecialHours = patchSpecialHours.map(
        (specialHourToPatch) =>
          patchSpecialHour(
            businessLocationId,
            specialHourToPatch.id,
            formatPatchSpecialHour(specialHourToPatch)
          )
      );
      let toDelete = deleteSpecialHours.map((specialHourToDelete) =>
        deleteSpecialHour(businessLocationId, specialHourToDelete)
      );

      Promise.all([
        ...formattedPostSpecialHours,
        ...formattedPatchSpecialHours,
        ...toDelete,
      ])
        .then((responses) => {
          dispatch({
            type: UPDATE_SPECIAL_HOURS_SUCCESS,
          });
          dispatch(getBusinessLocationDetailsByBusinessId(businessLocationId));
          dispatch(
            createAlert({
              type: 'success',
              message: 'Special Hours saved successfully',
            })
          );
        })
        .catch((error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                Object.keys(error.response.data.errors.details).length > 0
                  ? Object.entries(error.response.data.errors.details).map(
                      ([key, value]) => value
                    )
                  : error.response.data.errors.message,
            })
          );
          dispatch({
            type: UPDATE_SPECIAL_HOURS_FAILURE,
          });
        });
    }
  };
}

function deleteSpecialHour(businessLocationId, specialHourToDelete) {
  return api.delete(
    `businesses/${businessLocationId}/hours-overrides/${specialHourToDelete.id}`
  );
}

function postSpecialHour(businessLocationId, specialHourToPost) {
  return api.post(
    `businesses/${businessLocationId}/hours-overrides`,
    specialHourToPost
  );
}

export function putRegularHours(businessLocationId, regularHours) {
  const [formattedRegularHours, hoursError] =
    formatPutRegularHours(regularHours);
  return function (dispatch) {
    if (hoursError.length > 0) {
      dispatch(
        createAlert({
          type: 'error',
          message: `Order Ahead Hours should be between Regular Hours.
                      Error in days - ${hoursError.map(
                        (error) => ` ${error} `
                      )}`,
        })
      );
    } else {
      api
        .put(`businesses/${businessLocationId}/hours`, {
          hours: formattedRegularHours,
        })
        .then((respone) => {
          dispatch({
            type: 'PUT_REGULAR_HOURS_SUCCESS',
            regularHours: regularHours,
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Business hours updated successfully',
            })
          );
        })
        .catch((error) => {
          dispatch(
            createAlert({
              type: 'error',
              message:
                Object.keys(error.response.data.errors.details).length > 0
                  ? Object.entries(error.response.data.errors.details).map(
                      ([key, value]) => value
                    )
                  : error.response.data.errors.message,
            })
          );
          dispatch({
            type: PUT_REGULAR_HOURS_FAILURE,
          });
        });
    }
  };
}

function patchSpecialHour(
  businessLocationId,
  specialHourId,
  specialHourToPatch
) {
  return api.patch(
    `businesses/${businessLocationId}/hours-overrides/${specialHourId}`,
    specialHourToPatch
  );
}

/*
 * STORING TO USE ELSEWHERE
 */
export function specialHoursToDelete(specialHoursToDelete) {
  return function (dispatch) {
    dispatch({
      type: SPECIAL_HOURS_TO_DELETE,
      specialHoursToDelete,
    });
  };
}

export function specialHoursToEdit(specialHoursToEdit) {
  return function (dispatch) {
    dispatch({
      type: SPECIAL_HOURS_TO_EDIT,
      specialHoursToEdit,
    });
  };
}

/*
 * MODAL HANDLERS
 */

export function isCreateAndEditSpecialHoursModalOpen(
  isCreateAndEditSpecialHoursModalOpen
) {
  return function (dispatch) {
    dispatch({
      type: IS_CREATE_AND_EDIT_SPECIAL_HOURS_MODAL_OPEN,
      isCreateAndEditSpecialHoursModalOpen,
    });
  };
}

export function isDeleteSpecialHoursConfirmModalOpen(
  isDeleteSpecialHoursConfirmModalOpen
) {
  return function (dispatch) {
    dispatch({
      type: IS_DELETE_SPECIAL_HOURS_CONFIRM_MODAL_OPEN,
      isDeleteSpecialHoursConfirmModalOpen,
    });
  };
}

export function alertError(message) {
  return function (dispatch) {
    dispatch(
      createAlert({
        type: 'error',
        message: message,
      })
    );
  };
}

export function resetBusinessLocationDetailsReducerState() {
  return {
    type: RESET_BUSINESS_LOCATION_DETAILS_REDUCER_STATE,
  };
}

export function initiateCreateLocation() {
  return function (dispatch) {
    dispatch({
      type: INITIATE_CREATE_LOCATION,
      data: constructBusinessLocationFormat(null)(null, null)(null)(null),
    });
  };
}

export function initiateEditLocation(businessId) {
  return function (dispatch) {
    dispatch({
      type: INITIATE_EDIT_LOCATION,
      businessId: businessId,
    });
  };
}

export function createBusinessInformation(businessInformation) {
  // if(!businessInformation['external_id']) delete businessInformation['external_id'];
  return function (dispatch) {
    dispatch({ type: CREATE_BUSINESS_LOCATION_REQUEST });
    return api
      .post(`/businesses`, businessInformation)
      .then((response) => {
        dispatch({
          type: CREATE_BUSINESS_SUCCESS,
          businessId: response.data.data.id,
          businessInformation: businessInformation,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business location created successfully',
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateBusinessInformation(
  businessInformation,
  businessId,
  initialValues
) {
  const patchData = formatValuesForPatch(businessInformation, initialValues);
  return function (dispatch) {
    dispatch({ type: CREATE_BUSINESS_LOCATION_REQUEST });
    return api
      .patch(`/businesses/${businessId}`, patchData.businessInformation)
      .then((response) => {
        dispatch({
          type: UPDATE_BUSINESS_SUCCESS,
          businessId: businessId,
          businessInformation: businessInformation,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business location updated successfully',
          })
        );
        dispatch(getBusinessLocationsOfMerchant());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function createAddressAndDetailsInformation(data, businessId) {
  const { address, details } = data;
  delete address.deliveryzone;

  return function (dispatch) {
    return Promise.all([
      api.post(`/businesses/${businessId}/addresses`, address),
      api.post(`/businesses/${businessId}/details`, details),
    ])
      .then((response) => {
        dispatch({
          type: 'POST_BUSINESS_ADDRESS_AND_DETAILS_SUCCESS',
          data: data,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Business address updated successfully',
          })
        );
      })
      .catch((error) => console.log(error));
  };
}

export function updateAddressAndDetailsInformation(
  data,
  businessId,
  initialValues
) {
  const patchData = formatValuesForPatch(data, initialValues);
  return function (dispatch) {
    let promises = [];
    if (patchData.details) {
      promises.push(
        api.patch(`businesses/${businessId}/detail`, patchData.details)
      );
    }
    if (patchData.address) {
      promises.push(
        api.patch(`businesses/${businessId}/address`, patchData.address)
      );
    }
    Promise.all(promises)
      .then((responses) => {
        dispatch({
          type: PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_SUCCESS,
          data: data,
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Location Details successfully saved.',
          })
        );
      })
      .catch((error) => {
        dispatch({
          type: PATCH_BUSINESS_LOCATION_DETAILS_BY_BUSINESS_ID_FAILURE,
        });
      });
  };
}

export function setMenuManagementSelectedChildLocation(selectedLocation) {
  return function (dispatch) {
    localStorage.setItem('selectedChildLocationId', selectedLocation.id);
    dispatch({
      type: SET_MENU_MANAGEMENT_SELECTED_CHILD_LOCATION,
      selectedLocation,
    });
    // dispatch(
    //   getOptions({
    //     searchString: '',
    //     page_number: 1,
    //     page_size: 10000,
    //     selectedChildLocationId: selectedLocation.id,
    //   })
    // );
    // dispatch(getVariants());
  };
}

export function setMenuManagementSelectedParentLocation(selectedLocation) {
  return function (dispatch) {
    dispatch({
      type: SET_MENU_MANAGEMENT_SELECTED_PARENT_LOCATION,
      selectedLocation,
    });
  };
}
