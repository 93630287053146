import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ProductsMappingContainer from './ProductsMapping/ProductsMappingContainer';
import ItemsMappingContainer from './ItemsMapping/ItemsMappingContainer';
import CombosMappingContainer from './CombosMapping/CombosMappingContainer';
import CouponsMappingContainer from './CouponsMapping/CouponsMappingContainer';
import OffersMappingContainer from './OffersMapping/OffersMappingContainer';
import NoOptionVariantsSelected from '../../MenuManagement/PriceManager/components/NoOptionVariantsSelected';
import { Select } from 'merchant-portal-components';
import {
  setSelectedValuePOS,
  getPOSDiscountsMappingFields,
  getItemsPOSValues,
} from './posAction';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { getOptionPrices } from '../PriceManager/priceActions';
import { renderOptionsModified } from './ItemsMapping/helper/helper';

const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-bottom: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

const Title = styled.div`
  font-size: 32px;
  color: #191818;
  letter-spacing: 0.04px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 27px;
`;

const CardTitle = styled.p`
  font-size: 17px;
  font-weight: 600;
  color: #191818;
  margin-bottom: 26px;
`;

const MappingOptionsSelect = styled.div`
  width: 280px;
  padding-bottom: 24px;
`;

const ItemOptionSelect = styled.div`
  width: 280px;
  padding-bottom: 24px;
`;
const BackText = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;

  &: hover {
    color: #3051d4;
  }
`;

const LocationText = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;
const POSContainer = ({
  setSelectedValuePOS,
  getPOSDiscountsMappingFields,
  posDiscountsMappingFields,
  isShowingPosDiscount,
  isRequestingPosMappingFields,
  options,
  getItemsPOSValues,
  variants,
  itemsPosValues,
  optionPrices,
  history,
  selectedMenuManagementChildLocation,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    isShowingPosDiscount && getPOSDiscountsMappingFields();
  }, []);

  const renderMappingOptions = () => {
    if (isShowingPosDiscount)
      return [
        {
          id: 0,
          name: 'Products',
        },
        {
          id: 1,
          name: 'Items/Sub-Items',
        },
        {
          id: 2,
          name: 'Combos',
        },
        {
          id: 3,
          name: 'Offers',
        },
        {
          id: 4,
          name: 'Coupons',
        },
      ];
    else
      return [
        {
          id: 0,
          name: 'Products',
        },
        {
          id: 1,
          name: 'Items/Sub-Items',
        },
      ];
  };

  const [selectedValue, setSelectedValue] = useState(-1);

  const type = (selectedValue) => {
    return selectedValue.name.slice(0, -1).toLowerCase();
  };

  if (isRequestingPosMappingFields) return <LoadingBlueLarge />;
  return (
    <div>
      <BackText onClick={() => history.push('/menu-management')}>
        <i className="fa fa-chevron-left" />
        <div> Back to Menu Management</div>
      </BackText>

      {Object.keys(selectedMenuManagementChildLocation).length !== 0 && (
        <LocationText>
          <i className="fal fa-store-alt" />
          <div> {selectedMenuManagementChildLocation.display_name}</div>
        </LocationText>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '11fr 3fr',
          alignItems: 'center',
        }}
      >
        <BreadCrumb />
      </div>
      <Wrapper>
        <CardTitle>Which part of the menu would you like to map?</CardTitle>
        <MappingOptionsSelect>
          <Select
            options={renderMappingOptions()}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option}
            onChange={(option) => {
              setSelectedValuePOS([]);
              setSelectedValue(option);
            }}
            valueKey="id"
            required
            placeholder="Select the menu to map"
            isSearchable={false}
          />
        </MappingOptionsSelect>
        {selectedValue.id === 1 && (
          <div>
            <CardTitle>Please select an Option/Sub-Option</CardTitle>
            <ItemOptionSelect>
              <Select
                options={renderOptionsModified(options)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(option) => {
                  setSelectedOption(option);
                }}
                valueKey="id"
                required
                placeholder="Select Option/Sub-Option"
              />
            </ItemOptionSelect>
          </div>
        )}
      </Wrapper>
      {selectedValue === -1 && (
        <NoOptionVariantsSelected
          text={'Please make a selection above to start POS Mapping'}
          customWidth
        />
      )}
      {selectedValue.id === 0 && (
        <ProductsMappingContainer selectedValue={selectedValue} />
      )}
      {selectedValue.id === 1 && selectedOption && (
        <ItemsMappingContainer
          items={itemsPosValues}
          selectedOption={selectedOption}
          getItemsPOSValues={getItemsPOSValues}
          variants={variants}
          optionPrices={optionPrices}
        />
      )}
      {selectedValue.id === 2 &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <CombosMappingContainer
            type={type(selectedValue)}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
          />
        )}
      {selectedValue.id === 3 &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <OffersMappingContainer
            type={type(selectedValue)}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
          />
        )}
      {selectedValue.id === 4 &&
        posDiscountsMappingFields &&
        isShowingPosDiscount && (
          <CouponsMappingContainer
            type={type(selectedValue)}
            setSelectedValuePOS={setSelectedValuePOS}
            posDiscountsMappingFields={posDiscountsMappingFields}
            posDiscountsFieldsNum={
              Object.keys(posDiscountsMappingFields).length
            }
          />
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  posDiscountsMappingFields: state.posReducer.posDiscountsMappingFields,
  isShowingPosDiscount: state.posReducer.isShowingPosDiscount,
  isRequestingPosMappingFields: state.posReducer.isRequestingPosMappingFields,
  options: state.optionReducer.options,
  optionPrices: state.posReducer.optionPrices,
  variants: state.variantsReducer.allVariants,
  isRequestingItemsPosValues: state.posReducer.isRequestingItemsPosValues,
  itemsPosValues: state.posReducer.itemsPosValues,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});

export default connect(mapStateToProps, {
  getPOSDiscountsMappingFields,
  setSelectedValuePOS,
  getOptionPrices,
  getItemsPOSValues,
})(POSContainer);
