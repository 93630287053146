import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  getProducts,
  getProductsMappingFields,
  getProductPosMappingValues,
  setIsRequestingProductPosMappings,
  submitProductPosMappingValues,
} from '../posAction';
import ProductsMappingTable from './components/ProductsMappingTable';

const Wrapper = styled.div`
  width: 1000px;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  margin-top: 24px;

  @media (max-width: 1313px) {
    width: 100%;
  }
`;

let ProductsMappingContainer = ({
  getProducts,
  products,
  getProductsMappingFields,
  productsMappingFields,
  getProductPosMappingValues,
  currentProductMappingValues,
  setIsRequestingProductPosMappings,
  isRequestingProductPosMappings,
  submitProductPosMappingValues,
}) => {
  useEffect(() => {
    getProductsMappingFields();
    getProducts();
  }, []);

  let filteredProducts = products;

  let currentSearchStr = '';
  const handleProductsSearch = (searchString) => {
    if (searchString.length > 2 && searchString != currentSearchStr) {
      filteredProducts = filteredProducts.filter((product) =>
        product.productName.toLowerCase().includes(searchString.toLowerCase())
      );
    }
  };

  const handleGetPosMappings = (product, productsMappingFields) => {
    if (product) {
      getProductPosMappingValues(product.productId, productsMappingFields);
    }
  };

  return (
    <Wrapper>
      <ProductsMappingTable
        products={filteredProducts}
        productsMappingFields={productsMappingFields}
        handleProductsSearch={handleProductsSearch}
        handleGetPosMappings={handleGetPosMappings}
        currentProductMappingValues={currentProductMappingValues}
        setIsRequestingProductPosMappings={setIsRequestingProductPosMappings}
        isRequestingProductPosMappings={isRequestingProductPosMappings}
        submitProductPosMappingValues={submitProductPosMappingValues}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  products: state.posReducer.products,
  productsMappingFields: state.posReducer.productsMappingFields,
  currentProductMappingValues: state.posReducer.currentProductMappingValues,
  isRequestingProductPosMappings:
    state.posReducer.isRequestingProductPosMappings,
});

export default connect(mapStateToProps, {
  getProducts,
  getProductsMappingFields,
  getProductPosMappingValues,
  setIsRequestingProductPosMappings,
  submitProductPosMappingValues,
})(ProductsMappingContainer);
