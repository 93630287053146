import React, { Fragment, useState } from 'react';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import {
  RenderTextField,
  required,
  RenderSelect,
  RenderToggleSwitch,
} from '../../../components/FormElements/FormikElements';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Button, ButtonRow } from 'merchant-portal-components';
import ConfirmCancelModal from '../../MenuManagement/Products/ProductWizard/components/ConfirmCancelModal';

const FormWrapper = styled.div`
  padding: 20px;
`;

const AppIntegrationForm = ({ appIntegration, delimiter, handleSubmit }) => {
  const [
    confirmCancelModalIsVisible,
    setConfirmCancelModalIsVisible,
  ] = useState(false);

  const { configuration } = appIntegration;

  const isEditable = (config) =>
    [
      'registration|body',
      'config|logo',
      'config|support_email',
      'config|app_display_name',
      'config|special_instruction',
      'config|enable_track_button',
    ].includes(config);

  const renderHeaders = () => {
    return (
      <TrHead>
        <Th align="left" style={{ fontSize: '15px' }}>
          Sr.No
        </Th>
        <Th align="left" style={{ fontSize: '15px' }}>
          Name/Label
        </Th>
        <Th align="left" style={{ fontSize: '15px' }}>
          Type
        </Th>
        <Th align="left" style={{ fontSize: '15px' }}></Th>
      </TrHead>
    );
  };

  const renderRowsByType = (configkey, setFieldValue) => {
    let fieldData = '';
    switch (typeof configuration[configkey]) {
      case 'boolean':
        fieldData = (
          <Td padding="20px" style={{ width: '40%' }}>
            <Field
              name={configkey}
              disabled={!isEditable(configkey)}
              component={RenderToggleSwitch}
              onChange={(e) => setFieldValue(configkey, e.target.checked)}
            />
          </Td>
        );
        break;
      case 'number':
        if (configuration[configkey]) {
          fieldData = (
            <Td padding="20px" style={{ width: '40%' }}>
              <Field
                name={configkey}
                disabled={!isEditable(configkey)}
                component={RenderTextField}
                isPrice
                label={`* ${formatDelimitedLabels(configkey, true)}`}
                validate={required}
              />
            </Td>
          );
        } else {
          fieldData = (
            <Td padding="20px" style={{ width: '40%' }}>
              <Field
                name={configkey}
                disabled={!isEditable(configkey)}
                component={RenderTextField}
                isPrice
                label={` ${formatDelimitedLabels(configkey, true)}`}
              />
            </Td>
          );
        }
        break;
      case 'string':
        if (configuration[configkey]) {
          fieldData = (
            <Td padding="20px" style={{ width: '40%' }}>
              <Field
                name={configkey}
                disabled={!isEditable(configkey)}
                component={RenderTextField}
                type="text"
                label={`* ${formatDelimitedLabels(configkey, true)}`}
                validate={required}
              />
            </Td>
          );
        } else {
          fieldData = (
            <Td padding="20px" style={{ width: '40%' }}>
              <Field
                name={configkey}
                disabled={!isEditable(configkey)}
                component={RenderTextField}
                type="text"
                label={`${formatDelimitedLabels(configkey, true)}`}
              />
            </Td>
          );
        }
        break;
      case 'object':
        if (!configuration[configkey]) {
          fieldData = (
            <Td padding="20px" style={{ width: '40%' }}>
              <Field
                name={configkey}
                disabled={!isEditable(configkey)}
                component={RenderTextField}
                type="text"
                label={`${formatDelimitedLabels(configkey, true)}`}
              />
            </Td>
          );
        }
        break;
    }
    return fieldData;
  };
  const renderRows = (setFieldValue) => {
    return Object.keys(configuration).map((configkey, index) => {
      const patternToCheck = [/bcc$/g, /cc$/g, /id$/g, /api_key$/];
      const isPatternMatched = patternToCheck.some((pattern) =>
        configkey.match(pattern)
      );

      return (
        (!isPatternMatched ||
          (isPatternMatched && !configuration[configuration] == null)) && (
          <Tr style={{ padding: '20px' }} key={index}>
            <Td padding="20px" style={{ width: '10%' }}>
              {index + 1}
            </Td>
            <Td padding="20px" style={{ width: '25%' }}>
              {formatDelimitedLabels(configkey, false)}
            </Td>
            {renderRowsByType(configkey, setFieldValue)}
            <Td padding="20px" style={{ width: '10%' }}></Td>
          </Tr>
        )
      );
    });
  };

  const formatDelimitedLabels = (fieldName, isLabel) => {
    if (!fieldName.includes(delimiter)) {
      return fieldName;
    } else {
      const fragmentedKeys = fieldName.split(delimiter);
      if (!isLabel) {
        if (fragmentedKeys.length == 2) {
          return fragmentedKeys.join(' -> ');
        } else {
          const newLabelKeys = fragmentedKeys.filter(
            (val) => !new RegExp(`^[0-9]*$`).test(val)
          );
          return newLabelKeys.join(' -> ');
        }
      } else {
        return fragmentedKeys.pop();
      }
    }
  };

  return (
    <Formik initialValues={configuration} enableReinitialize={true}>
      {({ values, handleReset, dirty, setFieldValue, isValid }) => (
        <FormWrapper>
          <Form>
            <Table>
              <thead>{renderHeaders()}</thead>
              <TBody striped style={{ padding: '10px' }}>
                {renderRows(setFieldValue)}
              </TBody>
            </Table>
            {(appIntegration.type == 'transactional_email' ||
              appIntegration.type == 'sms') && (
              <ButtonRow
                justifyContent="space-between"
                style={{
                  margin: '24px 40px 24px 40px',
                  width: 'inherit',
                }}
              >
                <Button
                  style={{ outline: 'none' }}
                  secondary
                  onClick={() => {
                    dirty ? setConfirmCancelModalIsVisible(true) : '';
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{ outline: 'none' }}
                  disabled={!dirty || !isValid}
                  onClick={() => {
                    handleSubmit({
                      ...appIntegration,
                      configuration: { ...values },
                    });
                  }}
                >
                  Save
                </Button>
              </ButtonRow>
            )}
            {confirmCancelModalIsVisible && (
              <ConfirmCancelModal
                onCancel={() => setConfirmCancelModalIsVisible(false)}
                onConfirm={() => {
                  handleReset();
                  setConfirmCancelModalIsVisible(false);
                }}
              />
            )}
          </Form>
        </FormWrapper>
      )}
    </Formik>
  );
};

export default AppIntegrationForm;
