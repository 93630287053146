import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { submitUser, toggleCreateUserModal } from '../../rolesActions';
import {
  setMenuManagementSelectedChildLocation,
  setMenuManagementSelectedParentLocation,
} from '../../../BusinessLocationDetails/LocationDetailsManager/actions';
import CreateEditUserModal from './CreateEditUserModal';

const CreateEditUserModalContainer = ({
  roles,
  toggleCreateUserModal,
  submitUser,
  businesses,
  account,
  isModal,
  parentLocations,
  selectedMenuManagementChildLocation,
  selectedMenuManagementParentLocation,
  setMenuManagementSelectedParentLocation,
  setMenuManagementSelectedChildLocation,
}) => {
  return (
    <div>
      <CreateEditUserModal
        roles={roles}
        businesses={businesses}
        submitUser={submitUser}
        toggleCreateUserModal={toggleCreateUserModal}
        account={account}
        isModal={isModal}
        parentLocations={parentLocations}
        selectedMenuManagementChildLocation={
          selectedMenuManagementChildLocation
        }
        selectedMenuManagementParentLocation={
          selectedMenuManagementParentLocation
        }
        setMenuManagementSelectedParentLocation={
          setMenuManagementSelectedParentLocation
        }
        setMenuManagementSelectedChildLocation={
          setMenuManagementSelectedChildLocation
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.rolesReducer.account,
  parentLocations: state.businessLocationsList.businessLocationsOfMerchant,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
  selectedMenuManagementParentLocation:
    state.businessLocationDetails.selectedMenuManagementParentLocation,
});

export default connect(mapStateToProps, {
  submitUser,
  setMenuManagementSelectedParentLocation,
  setMenuManagementSelectedChildLocation,
})(CreateEditUserModalContainer);
