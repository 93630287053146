import styled from 'styled-components';

export const MainDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: white;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  grid-gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
`;

export const TimeSelectionGrid = styled.div`
  display: grid;
  grid-template-columns: 150px 150px;
  grid-gap: 20px;
`;

export const HoursField = styled.div`
  text-align: left;
`;

export const ImageWrapper = styled.div`
  display: grid;
  align-items: center;
  background-color: #f8f8fa;
  grid-template: 200px 20px 50px/ 250px;
  grid-row-gap: 20px;
  padding: 20px;
  margin-right: 40px;
  margin-left: 140px;
  width: 290px;
`;

export const ChooseImage = styled.div`
  border: 2px dashed #979797;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 12px;
`;
