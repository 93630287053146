import React, { Component } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import { connect } from 'react-redux';
import * as actions from './appFeedActions';
import DashedCreateRowButton from './DashedCreateRowButton';
import FeedRowFormContainer from './components/FeedRowFormContainer';
import FeedRow from './FeedRow';
import { Button, ButtonRow, LoadingButton } from 'merchant-portal-components';
import FeedPreview from './components/FeedPreview/FeedPreview';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import { LoadingBlue } from 'merchant-portal-components';
import getProducts from '../HOCs/Products/getProducts';
import getCombos from '../HOCs/Combos/getCombos';
import getOffers from '../HOCs/Offers/getOffers';
import { Card } from '../MenuManagement/components/MenuManagementElements';
import { Select } from 'merchant-portal-components';
import styled from 'styled-components';
import { HrLine } from 'merchant-portal-components';
import LeaveFormModalPrompt from '../../components/Modal/LeaveFormModalPrompt';

const MultiSelectWrapper = styled.div`
  width: 355px;
  margin: 20px;
  margin-left: auto;
`;

const DragHandle = SortableHandle(({ feedRow, setId, valueChanged }) => {
  return (
    <FeedRow setId={setId} feedRow={feedRow} valueChanged={valueChanged} />
  );
});

const SortableItem = SortableElement(({ feedRow, setId, valueChanged }) => {
  return (
    <div>
      <li
        key={`li-${feedRow.id}`}
        style={{ listStyle: 'none', height: '100%' }}
      >
        <DragHandle
          setId={setId}
          feedRow={feedRow}
          valueChanged={valueChanged}
        />
      </li>
    </div>
  );
});

const FeedRows = SortableContainer((props) => {
  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {!props.feedRows
        ? null
        : props.feedRows.map((feedRow, index) => {
            if (
              !props.feedRowFormIsVisible ||
              (props.forms.CreateFeedRow &&
                props.forms.CreateFeedRow.values.id != feedRow.id)
            ) {
              return (
                <SortableItem
                  setId={props.setId}
                  key={`item-${index}`}
                  feedRow={feedRow}
                  index={index}
                  addStyle={props.openEditAppFeedForm}
                  disabled={props.feedRowIsEditing || feedRow.is_pinned}
                  valueChanged={props.valueChanged}
                />
              );
            }
          })}
    </ul>
  );
});

class AppFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: null,
      editingRow: '',
      scrollHeight: null,
      previewSegments: [],
    };
  }

  componentDidMount() {
    track.page('engagement-app-feed');
    if (this.props.formInitialData) {
      this.props.fetchAppFeed(this.props.itemId);
    }

    if (this.props.formInitialData) {
      track.page('engagement-app-feed-edit');
    } else {
      track.page('engagement-app-feed-create');
    }
  }

  setIdToState = (id) => {
    this.setState({ selectedId: id });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.token && this.props.token) {
      this.props.fetchAppFeed(this.props.itemId);
    }

    if (this.props.reduxReset === true) {
      this.props.undoAppFeedChanges();
      this.props.setReduxReset(false);
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      this.props.valueChanged(true);
      const { feedRows, feedRowsOriginalOrder } = this.props;

      // console.log(
      //   arrayMove(feedRows, oldIndex, newIndex)
      //     .map((row, index) =>
      //       row.is_pinned
      //         ? {
      //             ...row,
      //             position: feedRows.find(({ id }) => id == row.id).position
      //           }
      //         : { ...row, position: index + 1 }
      //     )
      //     .sort((a, b) => (a.position > b.position ? 1 : -1))
      // );

      this.setState({
        newAppFeed: arrayMove(feedRows, oldIndex, newIndex)
          .map((row, index) =>
            row.is_pinned
              ? {
                  ...row,
                  position: feedRows.find(({ id }) => id == row.id).position,
                }
              : { ...row, position: index + 1 }
          )
          .sort((a, b) => (a.position > b.position ? 1 : -1)),
      });
      if (
        JSON.stringify(this.state.newAppFeed) ===
        JSON.stringify(feedRowsOriginalOrder) //unsure if right way to compare objects
      ) {
        this.props.valueChanged(false);
        //for when a user sorts and then sorts back to orginal order
        this.props.undoAppFeedChanges();
      } else {
        this.props.setSortAppFeed(this.state.newAppFeed);
      }
    }
  };

  handlePreviewSegmentsChange = (previewSegments) => {
    this.setState({ previewSegments });
  };

  componentWillUnmount() {
    this.props.resetAppFeedReducerState();
  }

  render() {
    const {
      feedRows,
      segmentIds,
      feedRowsOriginalOrder,
      requestingCreateAppFeed,
      allSegments,
      feedRowFormIsVisible,
      openCreateFeedRowForm,
      updateFeedRow,
      hideFeedRowForm,
    } = this.props;

    let showSaveAndCancelButtons =
      JSON.stringify(feedRows) !== JSON.stringify(feedRowsOriginalOrder);

    return (
      <div>
        {/* <BreadCrumb pageName="App Feed Management" /> */}

        <Card style={{ gridGap: '0px' }}>
          <div>
            <div style={{ paddingBottom: '13px' }}>
              Create, Edit, Sort, or Delete app feed cards that will appear in
              your Home feed. View your changes in the preview and Publish to
              your app, when ready. Select Segment(s) to preview the Home feed
              as they would see it.
            </div>
            <MultiSelectWrapper>
              {allSegments && (
                <Select
                  isMulti
                  options={allSegments}
                  getOptionLabel={(segment) => segment.name}
                  getOptionValue={(segment) => segment}
                  onChange={this.handlePreviewSegmentsChange}
                  value={this.state.previewSegments}
                  placeholder="Select segment(s) to preview as"
                  light
                  topMod={'-8px'}
                />
              )}
            </MultiSelectWrapper>
            <HrLine />
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <div>
              <div>
                {/* {showSaveAndCancelButtons && (
                  <ButtonRow
                    justifyContent="space-between"
                    style={{ marginBottom: '16px' }}
                  >
                    <Button secondary onClick={this.props.undoAppFeedChanges}>
                      Cancel
                    </Button>
                    {requestingCreateAppFeed ? (
                      <LoadingButton disabled>Publish</LoadingButton>
                    ) : (
                      <Button
                        onClick={() => this.props.createAppFeed(feedRows)}
                      >
                        Publish
                      </Button>
                    )}
                  </ButtonRow>
                )} */}
                <DashedCreateRowButton
                  openCreateFeedRowForm={openCreateFeedRowForm}
                />
                <div>
                  {feedRows && (
                    <FeedRows
                      items={feedRows}
                      setId={(id) => this.setIdToState(id)}
                      onSortEnd={this.onSortEnd}
                      useDragHandle={true}
                      {...this.props}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <FeedPreview
                previewSegments={this.state.previewSegments}
                selectedId={this.state.selectedId}
                feedRows={feedRows}
                allOffers={this.props.allOffers}
                allSegments={this.props.allSegments}
                allTags={this.props.allTags}
                cardById={this.state.selectedId}
                feedRowFormIsVisible={feedRowFormIsVisible}
              />
            </div>
          </div>
        </Card>
        {feedRowFormIsVisible && (
          <FeedRowFormContainer
            onSubmit={(formValues) => {
              const formItems = formValues.items.map(
                (item) =>
                  (item = {
                    ...item,
                    title: item.title
                      ? item.title.length > 48
                        ? item.title.slice(0, 48)
                        : item.title
                      : item.title,
                  })
              );
              const newFormValues = { ...formValues, items: formItems };

              updateFeedRow(newFormValues);

              hideFeedRowForm();
              this.props.valueChanged(true);
            }}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    feedRows: state.appFeed.feedRows,

    segmentIds: state.appFeed.segmentIds,
    requestingCreateAppFeed: state.appFeed.requestingCreateAppFeed,
    appFeedSortSave: state.appFeed.appFeedSortSave,
    openEditAppFeedForm: state.appFeed.openEditAppFeedForm,
    feedRowBeingEdited: state.appFeed.feedRowBeingEdited,
    feedRowIsEditing: state.appFeed.feedRowIsEditing,
    forms: state.form,
    feedRowFormIsVisible: state.appFeed.feedRowFormIsVisible,
    feedRowsOriginalOrder: state.appFeed.feedRowsOriginalOrder,
    token: state.accountReducer.token,
    allSegments: state.segments.availableSegments,
    allTags: state.tagsReducer.allTags,
    allOffers: state.offers.response,
  };
}

AppFeed = connect(mapStateToProps, actions)(AppFeed);

AppFeed = getProducts(AppFeed);
AppFeed = getCombos(AppFeed);
AppFeed = getOffers(AppFeed);

export default AppFeed;
