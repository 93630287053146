import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Icon } from 'merchant-portal-components';
import CustomerSearchSelect from '../../../CustomerSearchManager';
import BusinessSearchManager from '../../../BusinessSearchManager/BusinessSearchManagerContainer';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, FieldArray } from 'redux-form';
import {
  renderSelect,
  renderDateTimePicker,
  renderTextField,
} from '../../../../components/FormElements/ReduxForm';
import { Button } from 'merchant-portal-components';

const AdvancedFilterBtnGrid = styled.div`
  display: grid;
  grid-template-columns: 150px 10px;
  grid-gap: 10px;
  cursor: pointer;
  align-content: center;
  margin-right: auto;
`;

const IconWrapper = styled.div`
  font-size: 14px;
  text-align: left;
  align-self: center;
`;

const AdvancedFilterP = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  align-self: center;
`;

const AdvancedFilterBtnWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 40px 0;
  margin-right: 92px;
`;

const AddFilterBtn = styled.p`
  color: ${(props) => (props.disabled ? '#979eaa' : props.theme.blue)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const RemoveButtonContainer = styled.div`
  text-align: right;
  align-self: center;
  margin-left: auto;
`;

const RemoveButton = styled.p`
  color: ${(props) => props.theme.blue};
  cursor: pointer;
`;

const HorizontalLine = styled.hr`
  border: none;
  border-top: solid 1px ${(props) => props.theme.lightGray};
`;

const ApplyButtonContainer = styled.div`
  margin-left: auto;
`;
const AddFilterBtnContainer = styled.div`
  margin-right: auto;
  align-self: center;
`;

const AddAndApplyButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 35px;
`;

const options = [
  {
    label: 'Customer Name/Email',
    value: 'customer_id',
  },
  {
    label: 'Location',
    value: 'business_id',
  },
  {
    label: 'Date',
    value: 'order_date',
  },
  // {
  //   label: 'Order ID',
  //   value: 'order_id'
  // },
  {
    label: 'POS Order ID',
    value: 'pos_order_id',
  },
  {
    label: 'Status',
    value: 'status',
  },
];

const statusOptions = [
  {
    label: 'Completed',
    value: 'completed',
  },
  {
    label: 'Partial',
    value: 'partial',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Refunded',
    value: 'refunded',
  },
];

export class AdvancedFilter extends Component {
  handleApplyButtonClick = (filters) => {
    this.props.handleApplyFilters(filters);
  };

  getOptions = (usedOptions) => {
    return options.map((option) => {
      let found = false;
      usedOptions.forEach((filter) => {
        if (filter.type == option.value) {
          found = true;
        }
      });
      return {
        ...option,
        isDisabled: found,
      };
    });
  };

  renderFilters = ({ fields, meta: { error, submitFailed } }) => (
    <Fragment>
      {fields.map((filter, index) => {
        return (
          <div key={index}>
            <FilterContainer>
              <Field
                name={`${filter}.type`}
                light
                component={renderSelect}
                options={this.getOptions(fields.getAll())}
                getOptionValue={(option) => option}
                getOptionLabel={(option) => option.label}
                simpleValue
                valueKey="value"
                onChange={() => this.props.change(`${filter}.value`, null)}
              />

              {fields.get(index).type ? (
                fields.get(index).type == 'customer_id' ? (
                  <CustomerSearchSelect
                    defaultValue={fields.get(index).value}
                    onChange={(selectedCustomer) =>
                      this.props.change(
                        `${filter}.value`,
                        selectedCustomer.customer_id
                      )
                    }
                  />
                ) : fields.get(index).type == 'business_id' ? (
                  <BusinessSearchManager
                    defaultValue={fields.get(index).value}
                    onChange={(selectedBusiness) =>
                      this.props.change(`${filter}.value`, selectedBusiness.id)
                    }
                  />
                ) : // fields.get(index).type == 'order_id' ||
                fields.get(index).type == 'pos_order_id' ? (
                  <div style={{ bottom: '14px' }}>
                    <Field
                      name={`${filter}.value`}
                      component={renderTextField}
                      type="number"
                    />
                  </div>
                ) : fields.get(index).type == 'order_date' ? (
                  <div style={{ bottom: '14px' }}>
                    <Field
                      name={`${filter}.value`}
                      component={renderDateTimePicker}
                      hideTime
                      noDefault
                      noMinTime
                    />
                  </div>
                ) : fields.get(index).type == 'status' ? (
                  <Field
                    name={`${filter}.value`}
                    component={renderSelect}
                    options={statusOptions}
                    getOptionValue={(option) => option}
                    getOptionLabel={(option) => option.label}
                    simpleValue
                    valueKey="value"
                  />
                ) : (
                  <div />
                )
              ) : (
                <div />
              )}

              <RemoveButtonContainer>
                <RemoveButton onClick={() => fields.remove(index)}>
                  Remove
                </RemoveButton>
              </RemoveButtonContainer>
            </FilterContainer>
            <HorizontalLine />
          </div>
        );
      })}
      <AddAndApplyButtonContainer>
        {fields.length < 6 &&
        (!fields.length || fields.get(fields.length - 1).value) ? (
          <AddFilterBtnContainer>
            <AddFilterBtn onClick={() => fields.push({})}>
              + ADD FILTER
            </AddFilterBtn>
          </AddFilterBtnContainer>
        ) : (
          <AddFilterBtnContainer>
            <AddFilterBtn disabled>+ ADD FILTER</AddFilterBtn>
          </AddFilterBtnContainer>
        )}
        <ApplyButtonContainer>
          <Button
            type="button"
            disabled={fields.length && !fields.get(fields.length - 1).value}
            onClick={() => this.handleApplyButtonClick(fields.getAll())}
          >
            Apply
          </Button>
        </ApplyButtonContainer>
      </AddAndApplyButtonContainer>
    </Fragment>
  );

  render() {
    const {
      advancedFilterIsOpen,
      handleAdvancedFilterButtonClick,
      amountOfFiltersApplied,
    } = this.props;

    return (
      <Fragment>
        <AdvancedFilterBtnWrapper>
          <AdvancedFilterBtnGrid
            onClick={() => handleAdvancedFilterButtonClick()}
          >
            <AdvancedFilterP data-test="component-AdvancedFilterP">
              {!advancedFilterIsOpen && amountOfFiltersApplied > 0
                ? `(${amountOfFiltersApplied})`
                : ``}{' '}
              Advanced Filter
            </AdvancedFilterP>
            <IconWrapper>
              {advancedFilterIsOpen ? (
                <Icon className="fa fa-caret-up" />
              ) : (
                <Icon className="fa fa-caret-down" />
              )}
            </IconWrapper>
          </AdvancedFilterBtnGrid>
        </AdvancedFilterBtnWrapper>
        {advancedFilterIsOpen && (
          <FieldArray
            data-test="field-FieldArray-filters"
            name="filters"
            component={this.renderFilters}
          />
        )}
      </Fragment>
    );
  }
}

let AdvancedFilterForm = reduxForm({
  form: 'advancedFilterForm',
})(AdvancedFilter);

AdvancedFilterForm = connect((state) => {
  return {
    initialValues: {
      filters: [],
    },
    formValues: getFormValues('advancedFilterForm')(state),
  };
})(AdvancedFilterForm);

export default AdvancedFilterForm;
