import {
  GET_OPTION_PRICES_SUCCESS,
  FILTER_OPTIONS_BY_VARIANTS,
  PUT_OPTION_PRICES_REQUEST,
  PUT_OPTION_PRICES_SUCCESS,
  RESET_PRICE_REDUX_STATE,
  GET_ITEM_OVERRIDES_REQUEST,
  GET_ITEMS_OVERRIDES,
  GET_FORMATTED_BUSINESSES,
  GET_OPTION_PRICES_REQUEST,
  SET_IS_OPTION_OVERRIDES_MODAL,
  SET_SELECTED_LOCATIONS,
} from './priceTypes';
import api from '../../../api';
import { createAlert } from '../../Alert/actions';

export function getOptionPrices(option) {
  return function (dispatch) {
    dispatch({
      type: GET_OPTION_PRICES_REQUEST,
    });
    api
      .get(`/menu/options/${option.id}?include=variants,items`)
      .then((response) => {
        const variants = response.data.data.variants;
        dispatch({
          type: GET_OPTION_PRICES_SUCCESS,
          optionPrices: response.data.data,
          items: response.data.data.items,
        });

        if (variants) {
          dispatch(filterOptionsByVariants(variants));
        }
      });
  };
}
export function updateSelectedLocations(selectedLocations) {
  return function (dispatch) {
    dispatch({
      type: SET_SELECTED_LOCATIONS,
      selectedLocations: selectedLocations,
    });
  };
}

export function filterOptionsByVariants(variants) {
  return function (dispatch, getState) {
    const variantIds = variants.map((variant) => variant.id);
    const { optionPrices } = getState().priceReducer;
    const allVariants = getState().variantsReducer.allVariants;
    let items = [];
    let option = optionPrices;

    if (option.sub_options.length) {
      option.sub_options.forEach((subOption) => {
        //start

        if (subOption.items.length) {
          subOption.items.forEach((item) => {
            if (item.sub_items && item.sub_items.length) {
              let subItems = [];
              item.sub_items.forEach((subItem) => {
                subItems = [
                  ...subItems,
                  getItemPrices(
                    subItem,
                    variants,
                    variantIds,
                    allVariants,
                    subOption
                  ),
                ];
              });
              items = [
                ...items,
                {
                  id: item.id,
                  name: subOption.name + ' - ' + item.name,
                  sub_items: subItems,
                },
              ];
            } else {
              items = [
                ...items,
                getItemPrices(
                  item,
                  variants,
                  variantIds,
                  allVariants,
                  subOption
                ),
              ];
            }
          });
        }
        //end
      });
    } else if (option.items.length) {
      option.items.forEach((item) => {
        if (item.sub_items && item.sub_items.length) {
          let subItems = [];
          item.sub_items.forEach((subItem) => {
            subItems = [
              ...subItems,
              getItemPrices(subItem, variants, variantIds, allVariants),
            ];
          });
          items = [
            ...items,
            { id: item.id, name: item.name, sub_items: subItems },
          ];
        } else {
          items = [
            ...items,
            getItemPrices(item, variants, variantIds, allVariants),
          ];
        }
      });
    }

    dispatch({ type: FILTER_OPTIONS_BY_VARIANTS, items });
  };
}
const isToggled = (allVariantsPrices, selectedVariants) => {
  let isToggledOn = false;
  if (
    allVariantsPrices.length > 0 &&
    selectedVariants.length > 0 &&
    allVariantsPrices.length === selectedVariants.length
  ) {
    const allEqual = allVariantsPrices.every(
      (element) => element === allVariantsPrices[0]
    );
    if (allEqual) {
      isToggledOn = true;
    }
  }
  return isToggledOn;
};

const getItemPrices = (item, variants, variantIds, allVariants, subOption) => {
  let existingVariants = [];
  let allVariantsPrices = [];
  let existingPrices = item.prices.map((price) => {
    existingVariants.push(price.variant_id);
    allVariantsPrices.push(price.price);
    let variant = allVariants.find(
      (variant) => variant.id === price.variant_id
    );
    return {
      variant_id: price.variant_id,
      price: price.price,
      additional_price: item.additional_prices.find(
        (additionalPrice) => additionalPrice.variant_id === price.variant_id
      ).additional_price,
      variantName: `${variant.name} - ${variant.label}`,
      isSelected: variantIds.includes(price.variant_id),
    };
  });
  let nonExistingPrices = variants
    .filter((variant) => !existingVariants.includes(variant.id))
    .map((variant) => ({
      variant_id: variant.id,
      price: null,
      additional_price: null,
      variantName: `${variant.name} - ${variant.label}`,
      isSelected: true,
    }));
  const isToggledOn = isToggled(allVariantsPrices, variants);
  return {
    id: item.id,
    option_id: subOption ? subOption.id : null,
    name: subOption ? subOption.name + ' - ' + item.name : item.name,
    variantsAreEqual: isToggledOn,
    prices: [...existingPrices, ...nonExistingPrices].sort((price) =>
      price.isSelected ? -1 : 1
    ),
  };
};

export function putOptionPrices(itemsToSave, selectedOption, filteredItems) {
  return function (dispatch, getState) {
    // dispatch({ type: PUT_OPTION_PRICES_REQUEST });
    // const { id } = getState().priceReducer.optionPrices;
    // const optionId = selectedOption.id;
    // const formatedItemsToSave = formatItems(itemsToSave, true);
    // api
    //   .post(`/menu/options/${optionId || id}/items/batch`, formatedItemsToSave)
    //   .then(() => {
    //     dispatch(getOptionPrices(selectedOption));
    //     dispatch(updateSelectedLocations(filteredItems));
    //     dispatch(
    //       createAlert({
    //         type: 'success',
    //         message: 'Option prices successfully updated',
    //       })
    //     );
    //   });

    dispatch({ type: PUT_OPTION_PRICES_REQUEST });
    const formatedItemsToSave = formatItems(itemsToSave, true);
    let subitems = [];
    let payloaditems = [];
    formatedItemsToSave.forEach((item, index) => {
      subitems.push(item);
      if (formatedItemsToSave.length === index + 1) {
        payloaditems.push(subitems);
      } else if ((index + 1) % 20 === 0) {
        payloaditems.push(subitems);
        subitems = [];
      }
    });
    const { id } = getState().priceReducer.optionPrices;
    const optionId = selectedOption.id;
    payloaditems.forEach((items, index) => {
      api
        .post(`/menu/options/${optionId || id}/items/batch`, items)
        .then(() => {
          dispatch(getOptionPrices(selectedOption));
          dispatch(updateSelectedLocations(filteredItems));
          dispatch(
            createAlert({
              type: 'success',
              message: 'Option prices successfully updated',
            })
          );
        });
    });
  };
}

const formatItems = (items, isRegular) => {
  let formattedItems = [];
  items.forEach((item) => {
    if (item.sub_items) {
      item.sub_items.forEach((subItem) => {
        formattedItems = [
          ...formattedItems,
          {
            id: subItem.id,
            option_id: subItem.option_id,
            prices: subItem.prices
              .map((price) => {
                return {
                  product_variant_id: price.variant_id,
                  is_default: price.isSelected,
                  price: subItem.variantsAreEqual
                    ? subItem.prices[0].price
                    : price.price,
                  additional_price: subItem.variantsAreEqual
                    ? subItem.prices[0].additional_price
                    : price.additional_price,
                };
              })
              .filter((price) => price.is_default),
          },
        ];
      });
    } else {
      formattedItems = [
        ...formattedItems,
        {
          id: item.id,
          option_id: item.option_id,
          prices: item.prices

            .map((price) => {
              return {
                product_variant_id: price.variant_id,
                is_default: price.isSelected,
                price:
                  isRegular && item.variantsAreEqual
                    ? item.prices[0].price
                    : price.price,
                additional_price:
                  !isRegular && item.variantsAreEqual
                    ? item.prices[0].additional_price
                    : price.additional_price,
              };
            })
            .filter((price) => price.is_default),
        },
      ];
    }
  });
  const formatedBatchItems = formattedItems.reduce((acc, current) => {
    acc.push({
      action: 'replace',
      path: `/${current.id}/prices`,
      body: {
        prices: current.prices,
      },
    });
    return acc;
  }, []);
  return formatedBatchItems;
};

export function resetPriceReduxState() {
  return {
    type: RESET_PRICE_REDUX_STATE,
  };
}

export function getItemsOverrides(selectedOption, items) {
  return function (dispatch) {
    dispatch({ type: GET_ITEM_OVERRIDES_REQUEST });
    if (items) {
      let promises = [];
      let optionId;
      items.map((item) => {
        if (item.sub_items) {
          item.sub_items.map((subItem) => {
            optionId = !selectedOption.sub_options.length
              ? selectedOption.id
              : subItem.option_id;
            promises.push(
              api.get(
                `/menu/options/${optionId}/items/${subItem.id}/businesses/overrides`
              )
            );
          });
        } else {
          optionId = !selectedOption.sub_options.length
            ? selectedOption.id
            : item.option_id;

          promises.push(
            api.get(
              `/menu/options/${optionId}/items/${item.id}/businesses/overrides`
            )
          );
        }
      });

      return Promise.all(promises).then((overrides) => {
        dispatch({
          type: GET_ITEMS_OVERRIDES,
          payload: overrides.map((val) => val.data),
        });
      });
    }
  };
}

export function getAssociatedProductForOption(optionId) {
  return function (dispatch) {
    api.get(`/menu/options/${optionId}?include=products`).then((response) => {
      const accociatedProductList = response.data.data.products;
      dispatch({
        type: 'GET_ASSOCIATED_PRODUCT_SUCCESS',
        accociatedProductList: accociatedProductList,
      });
    });
  };
}
export function getProductAssignedBusinesses(selectedOption) {
  return function (dispatch, getState) {
    let productsOption;
    const allBusinesses =
      getState().businessLocationsList.businessLocationsOfMerchant;
    api.get(`/menu/options/${selectedOption.id}/products`).then((response) => {
      productsOption = response.data.data;
      Promise.all(
        productsOption.map((product) =>
          api.get(`/menu/products/${product.product_id}/businesses`)
        )
      ).then((promiseResponse) => {
        dispatch({
          type: GET_FORMATTED_BUSINESSES,
          payload: formatProductBusinessesAssigned(
            promiseResponse,
            allBusinesses
          ),
        });
      });
    });
  };
}

export function formatProductBusinessesAssigned(
  productsBusinesses,
  allBusinesses
) {
  let finalBusinessesArray = [];

  if (productsBusinesses.length) {
    finalBusinessesArray = allBusinesses.map((business) => ({
      ...business,
      ...(!business.is_active && {
        isDisabled: true,
      }),
    }));
  } else {
    finalBusinessesArray = allBusinesses.map((business) => ({
      ...business,
      isDisabled: true,
    }));
  }
  return finalBusinessesArray;
}

export function handleCreateOptionOverrides({
  selectedOption,
  item,
  values,
  summaryData,
  items,
  editingVariant,
  isPageEdit,
  touched,
}) {
  return function (dispatch, getState) {
    if (!isPageEdit && !touched.item) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Please modify the prices to save the overrides!',
        })
      );
      return;
    }

    const initialBusinesses = editingVariant.assignedBusinesses;
    const index = editingVariant.index;

    const initialBusinessesIds =
      initialBusinesses && initialBusinesses.map((val) => val.id);
    const deletedBusinessesIds = _.difference(
      initialBusinessesIds,
      values.assignedBusinesses
    );

    const pricesArrayToObject = (arr) =>
      arr.reduce((acc, price) => {
        acc[price.product_variant_id] = price;
        return acc;
      }, {});

    let businessesObj = {};
    summaryData.forEach((obj, i) => {
      obj.businesses.forEach((business) => {
        if (i == index && deletedBusinessesIds.includes(business.id)) {
          return;
        } else if (!businessesObj[business.id]) {
          businessesObj[business.id] = {
            business_id: business.id,
            prices: pricesArrayToObject(obj.item.prices),
          };
        } else {
          businessesObj[business.id].prices = {
            ...businessesObj[business.id].prices,
            ...pricesArrayToObject(obj.item.prices),
          };
        }
      });
    });

    values.assignedBusinesses.forEach((businessId) => {
      if (!businessesObj[businessId]) {
        businessesObj[businessId] = {
          business_id: businessId,
          prices: pricesArrayToObject(values.item.prices),
        };
      } else {
        values.item.prices.forEach((priceObj) => {
          businessesObj[businessId].prices[priceObj.product_variant_id] =
            priceObj;
        });
      }
    });

    let finalApiStructure = Object.values(businessesObj).map((businessObj) => ({
      ...businessObj,
      prices: Object.values(businessObj.prices),
    }));

    finalApiStructure.map((final) => {
      final.prices.map((variant) => {
        delete variant.name;
        delete variant.isSelected;

        if (selectedOption.included_pick > 0) {
          delete variant.price;
        } else {
          delete variant.additional_price;
        }
      });
    });

    const optionId = item.option_id || selectedOption.id;

    const validateFinalApiStructure = (finalApiStructure) =>
      !finalApiStructure.prices.length;

    if (finalApiStructure.some(validateFinalApiStructure)) {
      dispatch(
        createAlert({
          type: 'error',
          message: 'Please modify the prices to save the overrides!',
        })
      );
      // dispatch({
      //   type: SUBMIT_PRODUCT_OVERRIDES
      // });
      return;
    }

    api
      .put(`/menu/options/${optionId}/items/${item.id}/businesses/overrides`, {
        businesses: finalApiStructure,
      })
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'The values have been successfully saved!',
          })
        );
        dispatch(
          setIsOptionOverridesModal({
            isShowing: false,
            item: null,
            itemIndex: null,
          })
        );
        dispatch(getItemsOverrides(selectedOption, items));
      });
  };
}

export function setIsOptionOverridesModal(overridesModal) {
  return function (dispatch) {
    dispatch({
      type: SET_IS_OPTION_OVERRIDES_MODAL,
      payload: {
        isShowing: overridesModal.isShowing,
        item: overridesModal.item,
        itemIndex: overridesModal.itemIndex,
      },
    });
  };
}
