// Global roles
export const ROLE_FSO_SUPER_ADMIN = "ROLE_FSO_SUPER_ADMIN";
export const ROLE_FSO_ADMIN = "ROLE_FSO_ADMIN";
export const ROLE_FSO_OPERATOR = "ROLE_FSO_OPERATOR";

module.exports = {
  ROLE_FSO_SUPER_ADMIN,
  ROLE_FSO_ADMIN,
  ROLE_FSO_OPERATOR,
};
