import React, { lazy, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from './productsActions';
import { LoadingBlueLarge } from 'merchant-portal-components';
const ProductsListContainer = lazy(() =>
  import('./ProductList/ProductsListContainer')
);
const ProductWizardContainer = lazy(() =>
  import('./ProductWizard/ProductWizardContainer')
);

const ProductsContainer = ({
  productWizardIsVisible,
  history,
  selectedMenuManagementChildLocation,
  getProducts,
  productId,
  updateProductsRequestParams,
  requestParams,
}) => {
  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchString(searchString);
  };
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    return () => {
      updateProductsRequestParams({
        ...requestParams,
        page_number: 1,
      });
      setSearchString('');
    };
  }, []);
  useEffect(() => {
    let debounce = setTimeout(() => {
      const newRequestParams = {
        ...requestParams,
        page_number: 1,
        search_string: searchString,
      };
      if (searchString.length > 2 || searchString === '') {
        getProducts(newRequestParams);
      }
      updateProductsRequestParams(newRequestParams);
    }, 1500);
    return () => {
      clearTimeout(debounce);
    };
  }, [productId, productWizardIsVisible, searchString]);

  return (
    <Suspense fallback={<LoadingBlueLarge />}>
      {!productWizardIsVisible ? (
        <ProductsListContainer
          selectedMenuManagementChildLocation={
            selectedMenuManagementChildLocation
          }
          history={history}
          searchString={searchString}
          setSearchString={setSearchString}
          handleSearch={handleSearch}
        />
      ) : (
        <ProductWizardContainer history={history} />
      )}
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  productWizardIsVisible: state.productsReducer.productWizardIsVisible,
  productId: state.productsReducer.productId,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
  requestParams: state.productsReducer.requestParams,
});

export default connect(mapStateToProps, actions)(ProductsContainer);
