import React from 'react';
import { connect } from 'react-redux';
import MenuManagement from './MenuManagement';
import {
  setMenuManagementSelectedChildLocation,
  setMenuManagementSelectedParentLocation,
} from '../BusinessLocationDetails/LocationDetailsManager/actions';
const MenuManagementContainer = (props) => {
  const {
    history,
    parentLocations,
    selectedMenuManagementChildLocation,
    selectedMenuManagementParentLocation,
    setMenuManagementSelectedChildLocation,
    setMenuManagementSelectedParentLocation,
    posIntegrationEnabled,
  } = props;
  return (
    <MenuManagement
      history={history}
      parentLocations={parentLocations}
      selectedMenuManagementChildLocation={selectedMenuManagementChildLocation}
      selectedMenuManagementParentLocation={
        selectedMenuManagementParentLocation
      }
      setMenuManagementSelectedChildLocation={
        setMenuManagementSelectedChildLocation
      }
      setMenuManagementSelectedParentLocation={
        setMenuManagementSelectedParentLocation
      }
      posIntegrationEnabled={posIntegrationEnabled}
    />
  );
};

const mapStateToProps = (state) => ({
  parentLocations: state.businessLocationsList.businessLocationsOfMerchant,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
  selectedMenuManagementParentLocation:
    state.businessLocationDetails.selectedMenuManagementParentLocation,
  posIntegrationEnabled: state.accountReducer.posIntegrationEnabled,
});

export default connect(mapStateToProps, {
  setMenuManagementSelectedChildLocation,
  setMenuManagementSelectedParentLocation,
})(MenuManagementContainer);
