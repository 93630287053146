import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../productsActions';
import * as selectors from '../productsSelectors';
import ProductList from './components/ProductList';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import _ from 'lodash';
import { getCurrentPageProductList } from '../../utils/getCurrentPageProductList';

import { Button, Icon } from 'merchant-portal-components';
import { TextField, InputAdornment } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  border-radius: 12px;
`;

const MessageWrapper = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
`;
const TopRowTableCard = styled.div`
  padding: 15px 15px 0px;
`;

const BackText = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;

  &: hover {
    color: #3051d4;
  }
`;

const LocationText = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -20px;
`;
const ProductManager = ({
  initiateCreateProduct,
  initiateEditProductWizard,
  requestParams,
  allProducts,
  updateProductsRequestParams,
  selectedMenuManagementChildLocation,
  toggleProduct,
  history,
  getProducts, // get product in appFeed getProducts.js is unnecessary (prevent extra rendering)
  searchString,
  setSearchString,
  handleSearch,
}) => {
  const [displayedProducts, setDisplayProducts] = useState([]);
  const [sortingFactor, setSortingFactor] = useState('');
  const [sortingOrder, setSortingOrder] = useState('');
  const [productListLength, setProductListLength] = useState('');

  useEffect(() => {
    if (!selectedMenuManagementChildLocation.id) {
      history.push('/products');
      return;
    }
    window.scrollTo(0, 0);
  }, []);
  const updateDisplayProducts = useCallback(() => {
    if (allProducts) {
      let filterdProducts = !searchString
        ? allProducts
        : allProducts.filter((product) =>
            product.name.toLowerCase().includes(searchString)
          );
      let sortedProducts = _.orderBy(
        filterdProducts,
        [sortingFactor],
        [sortingOrder]
      );
      let paginatedProducts = getCurrentPageProductList(
        sortedProducts,
        requestParams.page_number,
        requestParams.page_size
      );
      setProductListLength(sortedProducts.length);
      setDisplayProducts(paginatedProducts);
    }
  }, [
    allProducts,
    searchString,
    sortingOrder,
    requestParams.page_size,
    requestParams.page_number,
  ]);
  useEffect(() => {
    updateDisplayProducts();
  }, [updateDisplayProducts]);

  useEffect(() => {
    updateProductsRequestParams({
      page_number: 1,
    });
  }, [searchString]);
  const handleSort = (sortArgs) => {
    setSortingFactor(sortArgs.value);
    setSortingOrder(sortArgs.sortType);
    const currentOptionAsArray = requestParams.sorting_option.split('-');
    if (currentOptionAsArray[1] === 'asc') {
      setSortingOrder('desc');
    } else {
      setSortingOrder('asc');
    }
  };
  const toggleProductAvailability = (product) => {
    toggleProduct(product.id, !product.is_active);
  };
  const handleClearSearch = () => {
    setSearchString('');
  };
  return (
    <div>
      <BackText onClick={() => history.push('/menu-management')}>
        <i className="fa fa-chevron-left" />
        <div> Back to Menu Management</div>
      </BackText>

      {Object.keys(selectedMenuManagementChildLocation).length !== 0 && (
        <LocationText>
          <i className="fal fa-store-alt" />
          <div> {selectedMenuManagementChildLocation.display_name}</div>
        </LocationText>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '11fr 3fr',
          alignItems: 'center',
        }}
      >
        <BreadCrumb />
      </div>
      <>
        <Wrapper>
          <TopRowTableCard>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '4fr 1fr',
                gridGap: '20px',
                justifyContent: 'space-between',
                margin: '37px',
              }}
            >
              <TextField
                type="text"
                style={{ width: '100%' }}
                variant="outlined"
                value={searchString}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon className="fal fa-search" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <Icon
                        className="fas fa-times-circle"
                        style={{ color: '#193053' }}
                        onClick={handleClearSearch}
                      />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
                placeholder="Search by product name"
                disabled={!allProducts}
              />
              <Button
                style={{ fontSize: '16px', width: '100%' }}
                onClick={() => initiateCreateProduct()}
              >
                Create a Product
              </Button>
            </div>
          </TopRowTableCard>
          {allProducts?.length > 0 ? (
            <ProductList
              products={allProducts}
              handleSearch={handleSearch}
              toggleProductAvailability={toggleProductAvailability}
              initiateEditProductWizard={initiateEditProductWizard}
              initiateCreateProduct={initiateCreateProduct}
              // requestingToggleProductIds={requestingToggleProductIds}
              requestParams={requestParams}
              allProductsLength={2}
              // handleSort={this.handleSort}
              searchString={searchString}
              handleClearSearch={handleClearSearch}
              getProducts={getProducts}
            />
          ) : (
            <MessageWrapper>No products found.</MessageWrapper>
          )}
        </Wrapper>
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allProducts: state.productsReducer.allProducts,
  requestingToggleProductIds: selectors.selectRequestingToggleProductIds(state),
  requestingGetProducts: state.productsReducer.requestingGetProducts,
  requestParams: state.productsReducer.requestParams,
  business_id:
    state.businessLocationDetails.selectedMenuManagementChildLocation.id,
});
export default connect(mapStateToProps, actions)(ProductManager);
