import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../appFeedActions';
import { reduxForm, getFormValues } from 'redux-form';
import FeedRowModal from './FeedRowModal';
import { ROLE_FSO_SUPER_ADMIN } from '../../../utils/rolesRepository'

class FeedRowFormContainer extends Component {
  componentDidMount() {
    const {
      feedRowIsEditing,
      feedRowBeingEdited,
      createFeedRowInitialValues
    } = this.props;
    const initialValues = feedRowIsEditing
      ? feedRowBeingEdited
      : createFeedRowInitialValues;
    this.props.initialize(initialValues);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.feedRowIsEditing &&
      !prevProps.initialized &&
      this.props.initialized
    ) {
      const {
        feedRowIsEditing,
        formValues,
        change,
        allSegments,
        allTags
      } = this.props;

      const segmentsValue = formValues.segments;
      const tagsValue = formValues.tags;
      if (feedRowIsEditing) {
        if (
          segmentsValue &&
          segmentsValue.length &&
          typeof segmentsValue[0] !== 'object'
        ) {
          const segmentObjects = segmentsValue.map(segmentId =>
            allSegments.find(segment => segment.segment_id == segmentId)
          );
          change('segments', segmentObjects);
        }
        if (tagsValue && tagsValue.length && typeof tagsValue[0] !== 'object') {
          const tagObjects = tagsValue.map(tagId =>
            allTags.find(tag => tag.tag_id == tagId)
          );
          change('tags', tagObjects);
        }
      }
    }
  }

  cancelFeedRowForm = () => {
    const {
      cancelCreateFeedRowForm,
      cancelEditFeedRowForm,
      updateFeedRow,
      feedRowIsEditing,
      feedRowBeingEdited,
      formValues
    } = this.props;
    if (feedRowIsEditing) {
      // updateFeedRow(feedRowBeingEdited);
      cancelEditFeedRowForm();
    } else {
      cancelCreateFeedRowForm(formValues.id);
    }
  };

  render() {
    const {
      allSegments,
      allTags,
      formValues,
      initialized,
      handleSubmit,
      appIdentifier,
      role,
      feedRowIsEditing
    } = this.props;

    if (initialized) {
      return (
        <FeedRowModal
          feedRowIsEditing={feedRowIsEditing}
          cancel={this.cancelFeedRowForm}
          allSegments={allSegments}
          allTags={allTags}
          formValues={formValues}
          handleSubmit={handleSubmit}
          appIdentifier={appIdentifier}
          isSuperAdmin={role === ROLE_FSO_SUPER_ADMIN}
        />
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    feedRowBeingEdited: state.appFeed.feedRowBeingEdited,
    feedRowIsEditing: state.appFeed.feedRowIsEditing,
    createFeedRowInitialValues: state.appFeed.createFeedRowInitialValues,
    formValues: getFormValues('feedRowForm')(state),
    allSegments: state.segments.availableSegments,
    allTags: state.tagsReducer.allTags,
    appIdentifier: state.accountReducer.appIdentifier,
    role: state.accountReducer.role
  };
}

FeedRowFormContainer = reduxForm({
  form: 'feedRowForm'
})(FeedRowFormContainer);

FeedRowFormContainer = connect(mapStateToProps, actions)(FeedRowFormContainer);

export default FeedRowFormContainer;
