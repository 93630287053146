import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from './mobileMessagesActions';
import { LoadingBlueLarge } from 'merchant-portal-components';
import MobileMessageDetailsContainer from './MobileMessageDetails/MobileMessageDetailsContainer';
import MobileMessagesListContainer from './MobileMessagesList/MobileMessageListContainer';

const OneTimeMobileMessageContainer = (props) => {
  useEffect(() => {
    props.fetchMobileMessages();
  }, []);
  if (
    props.allOffers &&
    // props.allOffers.length > 0 &&
    props.allProducts &&
    // props.allProducts.length > 0 &&
    props.allSegments &&
    // props.allSegments.length > 0 &&
    props.mobileMessagesReducer.response &&
    props.appIdentifier
  ) {
    const { mobileMessagesReducer } = props;
    const {
      isEditOnlyMobileMessage,
      isReadOnlyMobileMessage,
      isCreateOnlyMobileMessage,
    } = mobileMessagesReducer;

    if (
      isEditOnlyMobileMessage ||
      isReadOnlyMobileMessage ||
      isCreateOnlyMobileMessage
    ) {
      return <MobileMessageDetailsContainer {...props} />;
    } else {
      return <MobileMessagesListContainer {...props} />;
    }
  } else {
    return <LoadingBlueLarge height="80vh" />;
  }
};

const mapStateToProps = (state) => ({
  mobileMessagesReducer: state.mobileMessagesReducer,
  allProducts: state.productsReducer.allProducts,
  allSegments: state.segments.availableSegments,
  allOffers: state.offersReducer.allOffers,
  allCombos: state.combosReducer.allInitialCombos,
  appIdentifier: state.accountReducer.appIdentifier,
});

export default connect(mapStateToProps, actions)(OneTimeMobileMessageContainer);
