import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../productsActions";
import ProductCategoriesForm from "../components/ProductCategoriesForm";

const ProductCategoriesFormContainer = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [confirmCancelModalIsVisible, setConfirmCancelModalIsVisible] =
    useState(false);

  return (
    <ProductCategoriesForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      initialValues={{
        categories: props.categoriesInitialValues,
      }}
      categories={props.categories}
      putProductCategories={props.putProductCategories}
      disabled={!props.productId}
      setConfirmCancelModalIsVisible={setConfirmCancelModalIsVisible}
      confirmCancelModalIsVisible={confirmCancelModalIsVisible}
    />
  );
};

const mapStateToProps = (state) => ({
  categories: state.productsReducer.categories,
  categoriesInitialValues: state.productsReducer.categoriesInitialValues,
  productId: state.productsReducer.productId,
});

export default connect(
  mapStateToProps,
  actions
)(ProductCategoriesFormContainer);
