import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ChevronLeftIcon } from 'merchant-portal-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import * as actions from '../variantsActions';
import VariantDetails from './components/VariantDetails';

const BackButton = styled.div`
  width: 176px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 4px 300px;
  grid-gap: 10px;
  align-items: center;
  margin-top: 20px;
  &:hover {
    color: #3051d4;
  }
`;

const VariantDetailsContainer = ({
  changeVariantCurrentPage,
  variantToEdit,
  editVariant,
  createVariant,
  variantIsSaving,
  selectedMenuManagementChildLocation,
  history,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <BackButton
        onClick={() => !variantIsSaving && changeVariantCurrentPage('list')}
      >
        <ChevronLeftIcon />
        <p>Back To Variant Manager</p>
      </BackButton>
      <BreadCrumb
        style={{ margin: ' 0px' }}
        pageName={variantToEdit ? 'Edit Variant' : 'Create Variant'}
      />
      <VariantDetails
        variantToEdit={variantToEdit}
        editVariant={editVariant}
        createVariant={createVariant}
        variantIsSaving={variantIsSaving}
        business_id={selectedMenuManagementChildLocation.id}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  variantIsSaving: state.variantsReducer.variantIsSaving,
  selectedMenuManagementChildLocation:
    state.businessLocationDetails.selectedMenuManagementChildLocation,
});
export default connect(mapStateToProps, actions)(VariantDetailsContainer);
