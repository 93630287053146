import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { Button, ButtonRow } from 'merchant-portal-components';
import AutomatedMessagesList from './components/AutomatedMessagesList';
import {
  getAutomatedMessagesList,
  saveMessage,
} from './automatedMessagesActions';
import { transformAutomatedMessageList } from './utils/helper';
import { LoadingBlueLarge } from 'merchant-portal-components';
import AutomatedMessagesDetail from './components/AutomatedMessagesDetails';

const BackText = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 7px 1fr;
  cursor: pointer;
  align-items: center;
  margin-top: 20px;
  margin-bottom: -30px;

  &: hover {
    color: #3051d4;
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  padding: 20px 50px;
`;

const AutomatedMessages = ({
  history,
  getAutomatedMessagesList,
  isRequestingGetMessagesList,
  automatedMessagesList,
  saveMessage,
  savingIds,
  appIdentifier,
}) => {
  const [detailView, setDetailView] = useState(null);
  useEffect(() => {
    getAutomatedMessagesList();
  }, []);

  useEffect(() => {
    if (!detailView) {
      getAutomatedMessagesList();
    }
  }, [detailView]);

  if (isRequestingGetMessagesList) return <LoadingBlueLarge />;
  else if (detailView) {
    return (
      <AutomatedMessagesDetail
        details={detailView}
        setDetailView={setDetailView}
        saveMessage={saveMessage}
        savingIds={savingIds}
        appIdentifier={appIdentifier}
      />
    );
  }

  return (
    <div>
      <BackText onClick={() => history.push('/mobile-messages')}>
        <i className="fa fa-chevron-left" />
        <div>Back To Mobile Messages</div>
      </BackText>
      <ButtonRow justifyContent="space-between" style={{ margin: 'auto' }}>
        <BreadCrumb pageName="Automated Messages" />
      </ButtonRow>
      <Card>
        <AutomatedMessagesList
          automatedMessagesList={transformAutomatedMessageList(
            automatedMessagesList
          )}
          setDetailView={setDetailView}
        />
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isRequestingGetMessagesList:
    state.automatedMessagesReducer.isRequestingGetMessagesList,
  automatedMessagesList: state.automatedMessagesReducer.automatedMessagesList,
  savingIds: state.automatedMessagesReducer.savingIds,
  appIdentifier: state.accountReducer.appIdentifier,
});

export default connect(mapStateToProps, {
  getAutomatedMessagesList,
  saveMessage,
})(AutomatedMessages);
