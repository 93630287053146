import React from 'react';
import { connect } from 'react-redux';
import { TitleArea } from '../Table/TableElements';

function ParentLocationSelector({
  customWidth,
  parentLocations,
  selectedParentLocation,
  handleParentLocationSelection,
}) {
  return (
    <TitleArea
      selectName="parentLocation"
      selectLabel="Parent Location"
      selectValue={selectedParentLocation}
      onChange={handleParentLocationSelection}
      options={parentLocations}
      getOptionLabel={(business) => business.display_name}
      getOptionValue={(business) => business.id}
      isDisabled={parentLocations.length === 1 ? true : false}
      customWidth={customWidth}
    />
  );
}
const mapStateToProps = (state) => ({
  parentLocations: state.businessLocationsList.businessLocationsOfMerchant,
});

export default connect(mapStateToProps)(ParentLocationSelector);
