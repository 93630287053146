import React, { Component, Fragment } from 'react';
import { Field, FieldArray } from 'redux-form';
import {
  renderTextField,
  renderSelect,
  required,
  maxLength,
  urlValidation,
} from '../../../components/FormElements/ReduxForm';
import { Label, ModalBody } from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import CardsList from '../CardsList';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import { CardFormElements } from './CardForm';
import { Modal } from 'merchant-portal-components';
import styled from 'styled-components';
import SingleItemCard from './FeedPreview/SingleItemCard';
import HorizontalList from './FeedPreview/HorizontalList';

const AllOffersCard = styled.div`
  background: white;
  border-radius: 2px;
  // border: 1px solid lightgray;
  display: inline-block;
  height: 46px;
  line-height: 46px;
  margin: 5px;
  margin-left: 0;
  position: relative;
  width: 100%;
  padding: 0px 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  // cursor: ${(props) => (props.sortDisabled ? 'not-allowed' : 'move')};
`;

const MultiSelectWrapper = styled.div`
  width: 400px;
`;

const ALL_OFFERS = 'all_offers';

const maxLength32 = maxLength(32);
const maxLength50 = maxLength(50);
const maxLength512 = maxLength(512);
class FeedRowModal extends Component {
  state = {
    fieldTouched: false,
  };

  showOrHideListFields = () => {
    // const { feed_style_type } = this.props.formValues;
    // if (
    //   feed_style_type !== "single_card" &&
    //   feed_style_type !== "single_compact_card"
    // )

    return (
      <Fragment>
        <Field
          label="Section Title"
          name="title"
          component={renderTextField}
          type="text"
          marginTop="15px"

          // validate={[required, maxLength50]}
        />

        {/* <Field
          label="List Action Label"
          name="list_action_label"
          component={renderTextField}
          type="text"

          // validate={[required, maxLength32]}
        />
        <Field
          label="List Action URL"
          name="list_action"
          component={renderTextField}
          type="text"
          // validate={[required, maxLength512, urlValidation]}
        /> */}
      </Fragment>
    );
  };

  renderTextFieldArrayOrCardForm = () => {
    const { formValues } = this.props;
    const { feed_type } = formValues;
    // if (
    //   feed_style_type === "single_card" ||
    //   feed_style_type === "single_compact_card"
    // ) {
    //   return (
    //     <CardFormElements
    //       light
    //       card="items[0]"
    //       formValues={formValues}
    //       changed={value => this.setState({ fieldTouched: value })}
    //     />
    //   );
    // }

    if (feed_type === ALL_OFFERS) {
      return <AllOffersCard>Your Offers</AllOffersCard>;
    }
    return (
      <FieldArray
        light
        name="items"
        component={CardsList}
        changed={(value) => this.setState({ fieldTouched: value })}
      />
    );
  };

  renderCardPreview = () => {
    const { formValues } = this.props;
    const { feed_style_type } = formValues;
    if (feed_style_type === 'single_card') {
      return (
        <div style={{ height: '266px', width: '246px' }}>
          <SingleItemCard preview row={this.props.formValues} />
        </div>
      );
    } else if (feed_style_type === 'single_compact_card') {
      return (
        <div style={{ height: '94px', width: '246px' }}>
          <SingleItemCard preview row={this.props.formValues} compact />
        </div>
      );
    } else if (feed_style_type === 'horizontal_cards') {
      return (
        <div style={{ height: '198px', width: '246px' }}>
          <HorizontalList row={this.props.formValues} />
        </div>
      );
    }
  };

  render() {
    const {
      requiredFeedRow,
      cancel,
      allSegments,
      allTags,
      formValues,
      handleSubmit,
      appIdentifier,
      isSuperAdmin,
      feedRowIsEditing,
    } = this.props;

    const { feed_style_type } = formValues;

    let feedStyleTypeOptions = [
      // {
      //   value: 'horizontal_cards',
      //   label: 'Horizontal List of Cards'
      // },
      // {value: "list_card", label: 'Vertical List Card'},
      { value: 'single_card', label: 'Single Card' },
      // {
      //   value: 'single_compact_card',
      //   label: 'Compact Card'
      // }
    ];

    if (
      appIdentifier === 'cf' ||
      appIdentifier === 'cpl' ||
      appIdentifier === 'ticats' ||
      appIdentifier === 'bombers'
    ) {
      feedStyleTypeOptions = [
        ...feedStyleTypeOptions,
        {
          value: 'single_compact_card',
          label: 'Compact Card',
        },
      ];
    }

    return (
      <Modal>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 280px',
            backgroundColor: 'white',
            margin: 'auto',
            padding: '20px',
          }}
        >
          <ModalBody small gridGap="5px" padding="10px 0px 10px 0px">
            <form
              name="feedRowForm"
              onSubmit={handleSubmit}
              onChange={() => this.setState({ fieldTouched: true })}
            >
              <div style={{ display: 'grid', gridGap: '1px 1px' }}>
                {!requiredFeedRow && (
                  <div
                    style={{
                      marginBottom: '10px',
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gridGap: '5px',
                    }}
                  >
                    <ToggleSwitch name="is_visible" label="Visible" />
                    {isSuperAdmin && (
                      <ToggleSwitch name="is_pinned" label="Is Pinned" />
                    )}
                  </div>
                )}
                {!requiredFeedRow && (
                  <Field
                    label="Internal Label"
                    name="internal_label"
                    component={renderTextField}
                    type="text"
                  />
                )}

                {/* <div>
                      <Label>Feed Type</Label>
                      <div
                        style={{
                          fontWeight: '800',
                          paddingLeft: '6px',
                          textTransform: 'capitalize'
                        }}
                      >
                        {feedType.replace(/_/g, ' ')}
                      </div>
                    </div> */}
                {requiredFeedRow && (
                  <div style={{ marginTop: '25px' }}>
                    <Label>Section Row Style Type</Label>
                    <div
                      style={{
                        fontWeight: '800',
                        paddingLeft: '6px',
                        textTransform: 'capitalize',
                      }}
                    >
                      {feed_style_type.replace(/_/g, ' ')}
                    </div>
                  </div>
                )}
                {!requiredFeedRow && (
                  <Fragment>
                    {/*<SelectWrapper label="Feed Row Type">
                            <Field name="feed_type" component="select">
                              <option value="custom">Custom</option>
                            </Field>
                      </SelectWrapper>*/}
                    {formValues.feed_type !== ALL_OFFERS && (
                      <MultiSelectWrapper>
                        <Field
                          inLine
                          zIndex={9}
                          name="feed_style_type"
                          label="Section Row Style Type"
                          component={renderSelect}
                          options={feedStyleTypeOptions}
                          onChange={() => this.setState({ fieldTouched: true })}
                          simpleValue
                          topMod="-12px"
                          light
                          whiteBackground
                        />
                      </MultiSelectWrapper>
                    )}
                    {formValues.feed_type !== ALL_OFFERS && (
                      <MultiSelectWrapper>
                        <Field
                          zIndex={8}
                          name="segments"
                          topMod="-12px"
                          label="Segments (leave blank for all users)"
                          component={renderSelect}
                          isMulti
                          whiteBackground
                          light
                          inLine
                          onChange={() => this.setState({ fieldTouched: true })}
                          options={allSegments}
                          getOptionValue={(segment) => segment.segment_id}
                          getOptionLabel={(segment) => segment.name}
                        />
                      </MultiSelectWrapper>
                    )}
                    <MultiSelectWrapper>
                      <Field
                        zIndex={7}
                        name="tags"
                        label="Tags"
                        whiteBackground
                        component={renderSelect}
                        topMod="-12px"
                        isMulti
                        onChange={() => this.setState({ fieldTouched: true })}
                        inLine
                        light
                        options={allTags}
                        getOptionValue={(tag) => tag.tag_id}
                        getOptionLabel={(tag) =>
                          tag ? `${tag.name}: ${tag.value}` : null
                        }
                      />
                    </MultiSelectWrapper>
                  </Fragment>
                )}
                {!requiredFeedRow ? (
                  <Fragment>
                    {this.showOrHideListFields()}
                    {this.renderTextFieldArrayOrCardForm()}
                  </Fragment>
                ) : (
                  <Field
                    label={
                      feed_style_type === 'horizontal_cards'
                        ? 'List Title'
                        : 'Title'
                    }
                    name="title"
                    component={renderTextField}
                    type="text"

                    // validate={maxLength50}
                  />
                )}
                <ButtonRow
                  style={{ marginTop: '10px' }}
                  justifyContent="space-between"
                >
                  <Button type="button" secondary onClick={cancel}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      feedRowIsEditing ? !this.state.fieldTouched : false
                    }
                  >
                    Done
                  </Button>
                </ButtonRow>
              </div>
            </form>
          </ModalBody>
          <div style={{ marginLeft: '30px', width: '95%' }}>
            <div>Card Preview</div>
            {this.renderCardPreview()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default FeedRowModal;
