import React from 'react';
import styled from 'styled-components';
import BusinessSearchManagerContainer from '../../../BusinessSearchManager/BusinessSearchManagerContainer';
import {
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TdFlex,
} from 'merchant-portal-components';
import { StyledP, ComponentCard } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';

const ContainerDiv = styled.div`
  padding: ${(props) => props.padding || '0 0 0 0'};
  margin: ${(props) => props.margin || '0 0 0 0'};
  width: ${(props) => props.width || 'inherit'};

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const SearchAndButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 0px 30px 30px 30px;
`;
const StyledTd = styled(Td)`
  padding: 10px 30px 10px 20px;
  text-align: ${(props) => props.align || 'left'};
`;

const BusinessLocationsList = (props) => {
  const {
    businessLocationsOfMerchant,
    handleBusinessLocationAction,
    requestingGetBusinessLocationsOfMerchant,
  } = props;
  const { requestParams, updateRequestParams } = props;
  return (
    <ComponentCard
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.09)',
      }}
    >
      <ContainerDiv padding="32px 32px 0 32px">
        <StyledP semiBold>Find, Manage, and Edit Locations</StyledP>
        <StyledP>
          Search by Location Name or browse the list to find a Location. Click
          the Edit icon to go to that Location’s Detail Page, where you can edit
          Location info, set Regular and Special Hours, and manage Delivery
          Zones.
        </StyledP>
      </ContainerDiv>
      <ContainerDiv padding="24px 32px 5px  32px">
        <StyledP semiBold>Search by Location name</StyledP>
      </ContainerDiv>
      <SearchAndButtonWrapper>
        <BusinessSearchManagerContainer isClearable={true} />
        <Button
          style={{ height: '60px' }}
          onClick={() => props.initiateCreateLocation()}
        >
          Add Location
        </Button>
      </SearchAndButtonWrapper>
      <ContainerDiv>
        <Table noBorder>
          <TBody striped>
            {renderHeaders()}
            {requestingGetBusinessLocationsOfMerchant
              ? null
              : renderRows(
                  businessLocationsOfMerchant,
                  handleBusinessLocationAction
                )}
          </TBody>
        </Table>
        {requestingGetBusinessLocationsOfMerchant ? <LoadingBlueLarge /> : null}
      </ContainerDiv>
    </ComponentCard>
  );
};

const renderHeaders = () => {
  return (
    <TrHead>
      <Th align="left">Internal Location Name</Th>
      <Th align="left">Location Address</Th>
      <Th align="left">Status</Th>
    </TrHead>
  );
};

const renderRows = (
  businessLocationsOfMerchant,
  handleBusinessLocationAction
) => {
  return businessLocationsOfMerchant.map((businessLocation, index) => {
    return (
      <Tr
        onClick={() => handleBusinessLocationAction(businessLocation.id)}
        style={{ cursor: 'pointer' }}
        key={index}
        key={businessLocation.id}
      >
        <StyledTd align="left">
          <StyledP>{businessLocation.display_name}</StyledP>
        </StyledTd>

        <StyledTd align="left">
          <StyledP>{businessLocation.address}</StyledP>
        </StyledTd>

        <StyledTd align="left">
          <StyledP>
            {businessLocation.is_active ? 'ACTIVE' : 'INACTIVE'}
          </StyledP>
        </StyledTd>
      </Tr>
    );
  });
};

export default BusinessLocationsList;
