import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonRow } from 'merchant-portal-components';
import * as actions from './actions';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import CreateCouponContainer from './CreateCoupon/CreateCouponContainer';
import { CouponPromoIndex } from '../CouponPromo/components/CouponPromoIndex';
import { Icon } from 'merchant-portal-components';
import { SmallToggleSwitch } from '../../../components/ToggleSwitch/ToggleSwitch';
import { sort } from '../../../utils/sort/sort';

class Coupon extends Component {
  state = {
    couponItemToDelete: null,
    searchString: '',
  };

  componentDidMount() {
    track.page('engagement-coupons-list');
    window.scrollTo(0, 0);
    this.props.fetchCoupons();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.token && this.props.token) {
      this.props.fetchCoupons();
    }
    if (this.state.couponToDelete) {
      if (
        prevProps.coupons.isDeleting.includes(this.state.couponToDelete) &&
        !this.props.coupons.isDeleting.includes(this.state.couponToDelete)
      ) {
        this.setState({ couponToDelete: null });
      }
    }
    if (
      prevProps.coupons.requestingCreateCoupon &&
      !this.props.requestingCreateCoupon &&
      !this.props.coupons.createCoupon
    ) {
      this.props.fetchCoupons();
    }
    if (
      prevProps.coupons.requestingEditCoupon &&
      !this.props.requestingEditCoupon &&
      !this.props.coupons.editCoupon
    ) {
      this.props.fetchCoupons();
    }
  }

  initiateEditCoupon = (coupon) => {
    if (!this.props.coupons.isFetching) {
      this.props.initiateEditCoupon(coupon);
    }
  };

  toggleIsActiveCoupon = (coupon_id) => {
    if (!this.props.coupons.isFetching) {
      this.props.toggleIsActiveCoupon(coupon_id);
    }
  };

  initiateCreateCoupon = () => {
    this.props.initiateCreateCoupon();
  };

  handleCouponsSearch = (searchString) => {
    this.props.updateCouponsRequestParams({
      page_number: 1,
      page_size: this.props.coupons.requestParams.page_size,
    });
    this.setState({ searchString: searchString });
  };

  handleSort = (sortArgs) => {
    let sortOrder = '';
    let order = '';
    const currentOptionAsArray = this.props.coupons.requestParams.sorting_option.split(
      '-'
    );
    if (currentOptionAsArray[0] === sortArgs.value) {
      order = currentOptionAsArray[1] === 'asc' ? 'desc' : 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    } else {
      order = 'asc';
      sortOrder = `${sortArgs.value}-${order}`;
    }
    if (this.props.coupons.response.data) {
      const result = sort(this.props.coupons.response.data, {
        ...sortArgs,
        sortOrder: `${order}`,
      });
      this.props.updateCouponsRequestParams({
        page_number: 1,
        page_size: this.props.coupons.requestParams.page_size,
      });
      this.props.updateSortedCouponsList(result, sortOrder);
    }
  };

  render() {
    const { requestParams } = this.props.coupons;

    const { coupons } = this.props;
    if (this.props.coupons.editCoupon) {
      return (
        <CreateCouponContainer edit allSegments={this.props.allSegments} />
      );
    }
    if (this.props.coupons.createCoupon) {
      return <CreateCouponContainer allSegments={this.props.allSegments} />;
    }

    return (
      <div>
        <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
          <BreadCrumb pageName="Coupons" />
        </div>
        <CouponPromoIndex
          {...this.props}
          requestParams={requestParams}
          coupons={coupons}
          initiateCreateCoupon={this.initiateCreateCoupon}
          searchString={this.state.searchString}
          handleCouponsSearch={this.handleCouponsSearch}
          toggleIsActiveCoupon={this.toggleIsActiveCoupon}
          handleSort={this.handleSort}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    coupons: state.coupons,
    token: state.accountReducer.token,
    allSegments: state.segments.availableSegments,
  };
}

export default connect(mapStateToProps, actions)(Coupon);
