import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ALL_ORDER_TYPE_ID } from '../../../productsTypes';
import * as actions from '../../../productsActions';
import ProductInformationForm from '../components/ProductInformationForm';
import moment from 'moment';
import { productAvailableAllTime } from '../../../../utils/helper.js';
import { isProductAvailableAllTimeFunction } from '../../../../utils/helper.js';
import { hoursFormatter } from '../../../../utils/helper.js';
const TIME_FORMAT = 'HH:mm:ss';

const ProductInformationFormContainer = ({
  productsReducer,
  createProduct,
  patchProduct,
  resetProductReducerState,
  productPreviewIsVisible,
  setProductPreviewIsVisible,
  programType,
  getProductInformation,
  getTaxCategoryList,
  orderTypesList,
  taxCategoryList,
  accountReducer,
  getProductAvailabilities,
  productAvailabilities,
}) => {
  const {
    productInformationInitialValues,
    productId,
    createProductIsRequesting,
    patchProductIsRequesting,
    productInformation,
  } = productsReducer;

  const productInformationEdit = productInformation;
  const [mediaSelectionModalIsVisible, setMediaSelectionModalIsVisible] =
    useState(false);

  const [isAvailableAllDay, setIsAvailableAllDay] = useState(true);

  useEffect(() => {
    if (productId) {
      getProductInformation(productId);
      getProductAvailabilities(productId);
      setIsAvailableAllDay(
        productInformationInitialValues.start_time == '00:00:00' &&
          productInformationInitialValues.end_time == '23:59:59'
      );
    }
  }, []); //empty array means the callback will only be fired once, similar to componentDidMount
  const handleSubmit = ({ productInformation, productAvailabilities }) => {
    const productInformationWithFormattedTimes = {
      ...productInformation,
      start_time: isAvailableAllDay
        ? '00:00:00'
        : moment(productInformation.start_time).format(TIME_FORMAT),
      end_time: isAvailableAllDay
        ? '23:59:59'
        : moment(productInformation.end_time).format(TIME_FORMAT),
      is_active:
        productInformationEdit.is_pos_mapping_required &&
        !productInformationEdit.is_pos_mapped
          ? false
          : productInformationEdit.is_active,
    };
    if (productId) {
      patchProduct(
        productInformationWithFormattedTimes,
        productAvailabilities,
        productId
      );
    } else {
      createProduct(productInformationWithFormattedTimes);
    }
  };

  const showTaxCategory =
    !accountReducer.appMetaData.configuration.pos_integration_enabled;

  return (
    <ProductInformationForm
      initialValues={{
        productInformation: {
          ...productInformationInitialValues,
          start_time: moment(
            productInformationInitialValues.start_time,
            TIME_FORMAT
          ),
          end_time: moment(
            productInformationInitialValues.end_time,
            TIME_FORMAT
          ),
          id: productInformation.id,
          is_points_enabled: productInformationInitialValues.is_points_enabled,
        },
        productIsProductAvailableAllTime: isProductAvailableAllTimeFunction(
          productAvailabilities
        ),
        productAvailabilities: hoursFormatter(
          productAvailabilities,
          productInformation
        ),
      }}
      productAvailabilities={productAvailabilities}
      showTaxCategory={showTaxCategory}
      isAvailableAllDay={isAvailableAllDay}
      setIsAvailableAllDay={(boolean) => setIsAvailableAllDay(boolean)}
      mediaSelectionModalIsVisible={mediaSelectionModalIsVisible}
      setMediaSelectionModalIsVisible={setMediaSelectionModalIsVisible}
      isRequesting={patchProductIsRequesting || createProductIsRequesting}
      handleCancelClick={resetProductReducerState}
      handleSubmit={handleSubmit}
      productPreviewIsVisible={productPreviewIsVisible}
      setProductPreviewIsVisible={setProductPreviewIsVisible}
      productPreviewIsDisabled={
        !productsReducer.productVariantsInitialValues.length
      }
      programType={programType}
      orderTypesList={[
        { value: ALL_ORDER_TYPE_ID, label: 'All Order Types' },
        ...orderTypesList.map((orderType) => ({
          value: orderType.id,
          label: orderType.type,
        })),
      ]}
      taxCategoryList={taxCategoryList.map((taxCategory) => ({
        value: taxCategory.tax_category_id,
        label: taxCategory.description,
      }))}
    />
  );
};

const mapStateToProps = (state) => ({
  productsReducer: state.productsReducer,
  programType: state.accountReducer.appMetaData.configuration.program_type,
  taxCategoryList: state.fullReducer.taxCategoryList,
  orderTypesList: state.fullReducer.orderTypesList,
  accountReducer: state.accountReducer,
  productAvailabilities: state.productsReducer.productAvailabilities,
});

export default connect(
  mapStateToProps,
  actions
)(ProductInformationFormContainer);
