import React, { Fragment } from 'react';
import styled from 'styled-components';
import PermissionsTable from './PermissionsTable';
import { Wrapper } from '../../../components/FormElements/FormElements';

const NewUserDiv = styled.div`
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin: auto;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const TitleDiv = styled.div`
  padding: 30px 30px 20px 30px;
`;

const Roles = (props) => {
  const { accounts } = props;
  return (
    <div>
      <TitleDiv>
        <Title>Roles and Permissions</Title>
        <div>View the different permission levels for each page</div>
      </TitleDiv>
      {/* <NewUserDiv>
          <div onClick={() => toggleCreateUserModal(null)}>
            <i className="far fa-user-plus" style={{ margin: '8px' }} />
            Create a new role
          </div>
        </NewUserDiv> */}
      <PermissionsTable accounts={accounts} />
    </div>
  );
};
export default Roles;
