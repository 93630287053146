import api from '../../../api';
import {
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
  GET_BUSINESS_LOCATIONS_OF_MERCHANT_FAILURE,
  FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
} from './types';

function getBusinessLocationsOfMerchantRequest() {
  return {
    type: GET_BUSINESS_LOCATIONS_OF_MERCHANT_REQUEST,
  };
}

export function getBusinessLocationsOfMerchant() {
  return function (dispatch) {
    dispatch(getBusinessLocationsOfMerchantRequest());
    return api
      .get(`/businesses`)
      .then((response) => {
        dispatch({
          type: GET_BUSINESS_LOCATIONS_OF_MERCHANT_SUCCESS,
          payload: segregateParentChildLocations(response.data.data),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

function segregateParentChildLocations(locationsArray) {
  const parentLocations = {};
  const childLocations = {};
  locationsArray.forEach((locationData) => {
    const parentBusinessId = locationData.id;
    parentLocations[parentBusinessId] = locationData;
    locationData.sub_businesses.forEach((subBusinessData) => {
      const childBusinessId = subBusinessData.id;
      childLocations[childBusinessId] = {
        ...subBusinessData,
        parentLocationId: parentBusinessId,
      };
    });
  });
  return {
    data: locationsArray,
    parentLocations,
    childLocations,
  };
}

export function filterBusinessLocationsOfMerchantWithQuery(selectedBusiness) {
  return function (dispatch) {
    dispatch({
      type: FILTER_BUSINESS_LOCATIONS_OF_MERCHANT_WITH_SELECTED_BUSINESS,
      payload: selectedBusiness,
    });
  };
}
