import { FieldArray, Formik } from 'formik';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button } from 'merchant-portal-components';
import { Modal } from 'merchant-portal-components';
import RenderSortableComboList from './components/RenderSortableComboList';
import { LoadingBlueLarge } from 'merchant-portal-components';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: auto 20px;
  padding: 24px 24px 10px;
  font-size: 20px;
`;

const ModelContentWrapper = styled.div`
  width: 800px;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px;
  box-shadow: 0 -2px 9px 0 rgba(0, 0, 0, 0.12);
`;

const ModalBody = styled.div`
  height: 60vh;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 1px #ebebeb;
`;

const CombosSortModal = ({
  combosList,
  setIsSortModalOpen,
  isSavingComboPositions,
  handleSortingComboSubmit,
}) => {
  const closeComboSortModal = () => {
    setIsSortModalOpen(false);
  };

  return (
    <Modal borderRadius>
      <ModelContentWrapper>
        <Formik
          initialValues={{ combos: combosList }}
          enableReinitialize={true}
        >
          {({ values, handleReset, dirty, errors, setFieldValue }) => (
            <Fragment>
              <ModalTitle>
                <div>Sort Combos</div>
                <div
                  onClick={closeComboSortModal}
                  style={{ cursor: 'pointer', justifySelf: 'center' }}
                >
                  <i class="fa fa-times" />
                </div>
              </ModalTitle>
              <LineDivider />
              <ModalBody>
                {isSavingComboPositions && <LoadingBlueLarge />}
                {!isSavingComboPositions && (
                  <FieldArray
                    name="combos"
                    render={(arrayHelpers) => (
                      <RenderSortableComboList arrayHelpers={arrayHelpers} />
                    )}
                  />
                )}
              </ModalBody>
              <ButtonsWrapper>
                <Button
                  disabled={!dirty || isSavingComboPositions}
                  onClick={() => {
                    handleSortingComboSubmit(
                      values['combos'],
                      closeComboSortModal
                    );
                  }}
                >
                  Save
                </Button>
              </ButtonsWrapper>
            </Fragment>
          )}
        </Formik>
      </ModelContentWrapper>
    </Modal>
  );
};

export default CombosSortModal;
