import React, { Fragment, useState } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import styled from 'styled-components';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import {
  RenderSelect,
  RenderTextField,
  required,
} from '../../../../components/FormElements/FormikElements';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Divider } from '@material-ui/core';

const ModalInvite = styled(Modal)`
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14);
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100%;
  overflow-y: scroll;
`;

const FormWrapper = styled.div`
  padding: 40px 40px 0 40px;
  height: 84vh;
  overflow-y: scroll;
`;

const ButtonsWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const ModalTitle = styled.div`
  font-family: 'Montserrat';
  font-size: 20px;
  color: #191818;
  letter-spacing: 0.03px;
`;

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
  padding: 20px;
  align-items: center;
`;

const UnitsHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 15px;
  font-weight: 600;
  color: #191818;
  margin-bottom: 20px;
`;

const ServingSizeRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 11px;
  align-items: center;
`;

const ModalBody = styled.div`
  height: 45vh;
  overflow-y: scroll;
  position: relative;
  padding: 0 15px;
`;

const NutritionInfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const NutritionInfoModal = ({
  toggleNutritionInfoModal,
  putProductNutritionValues,
  nutritionTemplatePage,
  productId,
  productVariantId,
  createAlert,
}) => {
  const nutritionUnitsListOptions = nutritionTemplatePage.nutritionUnitsList;

  const {
    nutritionUnitsList,
    nutritionFactsList,
    nutritionPivotData,
    productServingSize,
    nutritionPivotDataFiltered,
  } = nutritionTemplatePage;

  const saveNutritionInfoModal = (values) => {
    let finalArray = [];
    values.nutritionPivotDataFiltered.forEach((nutPivData, index) => {
      finalArray.push({
        nutrition_fact_id: nutPivData.nutrition_fact_id,
        product_variant_id: nutPivData.product_variant_id
          ? nutPivData.product_variant_id
          : productVariantId,
        serving_sizes_id: values.productServingSize.id,
        nutrition_fact_unit_id: nutPivData.nutrition_fact_unit_id,
        nutrition_fact_min_value: parseInt(nutPivData.nutrition_fact_max_value),
        nutrition_fact_max_value: parseInt(nutPivData.nutrition_fact_max_value),
        nutrition_fact_daily: parseInt(nutPivData.nutrition_fact_daily),
        is_featured: true,
      });
    });

    // // validation
    let valid = true;
    finalArray.forEach((obj) => {
      if (
        !obj.nutrition_fact_max_value ||
        !obj.nutrition_fact_daily ||
        !obj.nutrition_fact_unit_id ||
        !values.productServingSize.name
      )
        valid = false;
    });

    if (!valid) {
      createAlert({
        type: 'error',
        message: 'All the fields must be completed',
      });
      return;
    }

    putProductNutritionValues(
      productId,
      finalArray,
      values.productServingSize.id,
      values.productServingSize.name
    );
  };

  return (
    <ModalInvite>
      <div style={{ width: '800px' }}>
        <ModalHeader>
          <ModalTitle>Variant - Small</ModalTitle>
          <div
            onClick={() => toggleNutritionInfoModal()}
            style={{ cursor: 'pointer' }}
          >
            x
          </div>
        </ModalHeader>
        <Divider />
        {nutritionTemplatePage.isRequestingGetNutritionUnits &&
        nutritionTemplatePage.isRequestingGetProductServingSize &&
        nutritionTemplatePage.isRequestingGetNutritionFacts &&
        nutritionTemplatePage.isRequestingGetNutritionPivotDataFiltered ? (
          <LoadingBlueLarge />
        ) : (
          <FormWrapper>
            <Formik
              initialValues={{
                nutritionUnitsList,
                productServingSize,
                nutritionFactsList,
                nutritionPivotDataFiltered,
              }}
              enableReinitialize={true}
            >
              {({ values, dirty }) => (
                <Fragment>
                  <ServingSizeRow>
                    <p>Serving Size</p>

                    <Field
                      type="text"
                      name="productServingSize.name"
                      label="Serving Size"
                      component={RenderTextField}
                      validate={required}
                    ></Field>
                  </ServingSizeRow>
                  <Divider style={{ marginBottom: '10px' }} />

                  <UnitsHeader>
                    <div>Name</div>
                    <div>Value</div>
                    <div>Unit</div>
                    <div>Daily Value %</div>
                  </UnitsHeader>

                  <ModalBody>
                    <FieldArray
                      name="nutritionFactsList"
                      render={(arrayHelpers) => (
                        <Fragment>
                          {nutritionFactsList.map((nutritionFact, index) => (
                            <div key={`nutritionFact-${index}`}>
                              <NutritionInfoRow>
                                <p style={{ alignSelf: 'center' }}>
                                  {nutritionFact.name}
                                </p>
                                <div>
                                  <Field
                                    type="text"
                                    name={`nutritionPivotDataFiltered[${index}].nutrition_fact_max_value`}
                                    label="Value"
                                    component={RenderTextField}
                                    required
                                    error={
                                      arrayHelpers.form.touched
                                        .nutritionPivotDataFiltered &&
                                      arrayHelpers.form.touched
                                        .nutritionPivotDataFiltered[index] &&
                                      arrayHelpers.form.touched
                                        .nutritionPivotDataFiltered[index]
                                        .nutrition_fact_max_value &&
                                      !arrayHelpers.form.values
                                        .nutritionPivotDataFiltered[index]
                                        .nutrition_fact_max_value
                                        ? 'Required'
                                        : null
                                    }
                                  ></Field>
                                </div>

                                <div style={{ marginTop: '14px' }}>
                                  <Field
                                    options={nutritionUnitsListOptions}
                                    name={`nutritionPivotDataFiltered[${index}].nutrition_fact_unit_id`}
                                    component={RenderSelect}
                                    placeholder="Unit*"
                                    getOptionLabel={(nutrition) =>
                                      nutrition.name
                                    }
                                    getOptionValue={(nutrition) => nutrition.id}
                                    valueKey="id"
                                  />
                                </div>
                                <div>
                                  <Field
                                    type="text"
                                    name={`nutritionPivotDataFiltered[${index}].nutrition_fact_daily`}
                                    label="Daily Value"
                                    component={RenderTextField}
                                    required
                                    error={
                                      arrayHelpers.form.touched
                                        .nutritionPivotDataFiltered &&
                                      arrayHelpers.form.touched
                                        .nutritionPivotDataFiltered[index] &&
                                      arrayHelpers.form.touched
                                        .nutritionPivotDataFiltered[index]
                                        .nutrition_fact_daily &&
                                      !arrayHelpers.form.values
                                        .nutritionPivotDataFiltered[index]
                                        .nutrition_fact_daily
                                        ? 'Required'
                                        : null
                                    }
                                  ></Field>
                                </div>
                              </NutritionInfoRow>
                              <Divider />
                            </div>
                          ))}
                        </Fragment>
                      )}
                    />
                  </ModalBody>
                  <ButtonsWrapper>
                    <Button
                      style={{
                        background: 'none',
                        color: '#191818',
                        border: 'none',
                      }}
                      onClick={() => toggleNutritionInfoModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      //style={{ marginLeft: '100px' }}
                      type="submit"
                      onClick={() => saveNutritionInfoModal(values)}
                    >
                      Save
                    </Button>
                  </ButtonsWrapper>
                </Fragment>
              )}
            </Formik>
          </FormWrapper>
        )}
      </div>
    </ModalInvite>
  );
};

export default NutritionInfoModal;
