import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select } from 'merchant-portal-components';
import AssociatedProductsModal from './AssociatedProductsModal';

const Title = styled.div`
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

const Subtitle = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #191818;
`;

const SelectWrapper = styled.div`
  width: 500px;
`;
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 25px 1fr;
  margin-top: 15px;
`;
const Price = ({
  selectOption,
  options,
  isOptionDisabled,
  accociatedProductList,
  selectedOptionId,
  getAssociatedProductForOption,
  initiateEditProductWizard,
  updateSelectedLocations,
}) => {
  const [isAssociatedProductsOpen, setIsAssociatedProductsOpen] = useState(false);
  const [optionTitle, setOptionTitle] = useState('');
  const openAssociatedProductsModalHandler = () => {
    if (optionTitle) {
      setIsAssociatedProductsOpen(true);
    }
  };
  const closeAssociatedProductsModalHandler = () => {
    setIsAssociatedProductsOpen(false);
  };
  useEffect(() => {
    if (selectedOptionId) {
      getAssociatedProductForOption(selectedOptionId);
    }
  }, [selectedOptionId]);
  return (
    <>
      <div>
        <Title>Select an Option</Title>
        <SelectWrapper>
          <Select
            options={options}
            getOptionLabel={option => `${option.name} - ${option.id}`}
            getOptionValue={option => option.id}
            onChange={option => {
              selectOption(option);
              setOptionTitle(option.name);
              updateSelectedLocations([]);
            }}
            isDisabled={isOptionDisabled}
            label="Options"
          />
        </SelectWrapper>
        <ProductWrapper>
          <div>
            <i
              className="fal fa-eye "
              onClick={openAssociatedProductsModalHandler}
              style={{
                cursor: optionTitle ? 'pointer' : 'default',
                color: optionTitle ? '#191818' : '#6f6f76',
              }}
            ></i>
          </div>
          <div>
            <Subtitle
              onClick={openAssociatedProductsModalHandler}
              style={{
                cursor: optionTitle ? 'pointer' : 'default',
                color: optionTitle ? '#191818' : '#6f6f76',
              }}
            >
              {'View Associated Products'}
            </Subtitle>
          </div>
        </ProductWrapper>
      </div>
      {isAssociatedProductsOpen && (
        <AssociatedProductsModal
          optionTitle={optionTitle}
          onClose={closeAssociatedProductsModalHandler}
          accociatedProductList={accociatedProductList}
          initiateEditProductWizard={initiateEditProductWizard}
        />
      )}
    </>
  );
};

export default Price;
