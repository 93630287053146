import React, { useEffect } from 'react';
import ReportTable from '../ReportTable/ReportTable';
import { BatchDetailPage } from 'merchant-portal-components';
import { connect } from 'react-redux';

function CustomerReport(props) {
  useEffect(() => {
    track.page('customer-report');
  }, []);

  const columnNames = [
    'Customer Name',
    'Customer Phone',
    'Customer Email',
    'Registration Date',
    'Loyalty Tier #',
    'Points Balance',
    '# of Transactions',
    'Gross Spend $',
    'Net Spend $',
    '# of Offers Redeemed',
    'Global Credits Received',
    '# of Coupons Redeemed',
    '# of Completed Referrals',
    'AVG Basket Size',
  ];

  const initialParams = {
    page_number: 1,
    page_size: 10,
    sort_direction: 'asc',
  };

  const columnSort = [
    'customer_name',
    'customer_phone',
    'customer_email',
    'registration_date',
    'loyalty_tier',
    'points_balance',
    'num_transactions',
    'gross_spend',
    'net_spend',
    'num_offers_redeemed',
    'global_credits_received',
    'num_coupons_redeemed',
    'num_completed_referrals',
    'avg_basket_size',
  ];

  return (
    <BatchDetailPage>
      <ReportTable
        url="/report/customer"
        columnNames={columnNames}
        title="Customer Report"
        csvRequestPath="report/customer"
        filters={[]}
        initialParams={initialParams}
        emptyMessage={'There Are No Customer Reports To Show'}
        columnSort={columnSort}
      />
    </BatchDetailPage>
  );
}

const mapStateToProps = (state) => ({
  appSettings: state.accountReducer.appMetaData.configuration,
});

export default connect(mapStateToProps, null)(CustomerReport);
