import React from 'react';
import styled from 'styled-components';
import { Table, Tr, TrHead, Th, TBody, Td } from 'merchant-portal-components';
import Switch from '@material-ui/core/Switch';
import PaginationControl from '../../../../../components/Pagination/PaginationControl';
import { TooltipIcon } from '../../../../../components/Icon';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  width: 100%;
`;

const ComboImage = styled.img`
  width: 100px;
  max-height: 64px;
  min-height: 64px;
  object-fit: contain;
  background-color: transparent;
  display: block;
`;

const PaginationWrapper = styled.div`
  padding: 15px 45px;
  border-top: 1px solid lightgray;
`;

const SortDiv = styled.div`
  padding: 0 40px 25px;
  text-align: right;
`;

const SortWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
`;

const ComboList = ({
  combos,
  initiateEditCombo,
  toggleCombo,
  requestParams,
  businesses,
  setIsSortModalOpen,
  getCombos,
}) => {
  const renderLocatiosNumberString = (combo) => {
    if (combo.businesses?.length === 0) return 'No Locations';
    if (combo.businesses?.length === 1) return '1 Location';
    if (combo.businesses?.length == businesses?.length) return 'All Locations';
    else {
      return combo.businesses.length + ' ' + 'Locations';
    }
  };

  return (
    <Wrapper>
      {combos.length > 1 && (
        <SortDiv>
          <SortWrapper onClick={() => setIsSortModalOpen(true)}>
            <i className="far fa-sort-amount-down" /> Reorder Combos
            <Tooltip
              style={{ marginRight: 'auto' }}
              title="Edit the position of combos to change the order that they are presented in to the user"
            >
              <span>
                <TooltipIcon marginLeft />
              </span>
            </Tooltip>
          </SortWrapper>
        </SortDiv>
      )}
      <Table noBorder>
        <TBody>
          <TrHead>
            <Th align="left" style={{ paddingLeft: '37px' }}></Th>
            <Th
              align="left"
              // isSortable={false}
              value="name"
              sortingOption={requestParams.sorting_option}
            >
              Name
            </Th>
            <Th
              align="left"
              // isSortable={false}
              value="locationsNumber"
              sortingOption={requestParams.sorting_option}
            >
              Locations
            </Th>
            <Th
              align="right"
              style={{ paddingRight: '45px' }}
              // isSortable={false}
              value="is_active"
              sortingOption={requestParams.sorting_option}
            >
              Active
            </Th>
          </TrHead>

          {combos.map((combo) => {
            return (
              <Tr
                key={combo.id}
                onClick={() => initiateEditCombo(combo)}
                style={{ cursor: 'pointer', height: '78px' }}
              >
                <Td padding={'0px 0px 0px 37px'} width="70px">
                  {combo.image_url ? (
                    <ComboImage src={combo.image_url} alt={combo.name} />
                  ) : (
                    <div />
                  )}
                </Td>
                <Td>{combo.name}</Td>

                <Td>{renderLocatiosNumberString(combo)}</Td>
                <Td padding={'0px 37px 0px 0px'} style={{ textAlign: 'right' }}>
                  <Switch
                    checked={combo.is_active}
                    onChange={() => toggleCombo(combo, !combo.is_active)}
                    onClick={(e) => e.stopPropagation()}
                    value="checkedB"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
      <PaginationWrapper>
        <PaginationControl
          response={{
            ...combos,
            page: requestParams.page_number,
            page_size: requestParams.page_size,
            count: combos.length,
          }}
          requestParams={requestParams}
          updateAction={getCombos}
          type="combos"
        />
      </PaginationWrapper>
    </Wrapper>
  );
};

export default ComboList;
