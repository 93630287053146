import React, { Fragment, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field, FieldArray } from 'formik';
import moment from 'moment';
import CategoryForm from './CategoryForm';
import SubCategoryForm from './SubCategoryForm';
import StickyButtons from '../../../../../components/StickyButtons';
import ProductCategoryAssignmentModal from './ProductCategoryAssignmentModal';

const Wrapper = styled.div`
  width: 1000px;
  display: grid;
  grid-gap: 24px;
  margin: 0 auto;
`;

const CategoryDetail = ({
  initialValues,
  handleCategorySave,
  isRequesting,
  subCategoryHoursError,
  productsAssociatedToCategory,
  productCategoryAssignmentModalIsVisible,
  setProductCategoryAssignmentModalIsVisible,
  sortCategories,
  sortProducts,
  categoryId,
  formHeadName,
  // handleProductCategorySave
}) => {
  const [isSubCategories, setisSubCategories] = useState(
    initialValues?.sub_categories?.length > 0 ? false : true
  );
  useEffect(() => {
    return () => {
      if (initialValues?.sub_categories?.length > 0) {
        setisSubCategories(false);
      } else {
        setisSubCategories(true);
      }
    };
  }, [isSubCategories]);

  const wrapperEnd = useRef(null);

  const scrollToBottom = (behavior) => {
    wrapperEnd.current.scrollIntoView({ behavior });
  };

  const initialValuesModified = {
    categoryName: initialValues.name,
    categoryImageUrl: initialValues?.image_url ? initialValues.image_url : '',
    isCategoryActive: initialValues?.is_active,
    categoryStartTime: moment(initialValues?.start_time, 'HH:mm:ss'),
    categoryEndTime: moment(initialValues?.end_time, "'HH:mm:ss'"),
    categorySubCategories: initialValues?.sub_categories
      ? initialValues?.sub_categories.map((subCat) => ({
          ...subCat,
          start_time: moment(subCat.start_time, 'HH:mm:ss'),
          end_time: moment(subCat.end_time, 'HH:mm:ss'),
          image_url: subCat.image_url ? subCat.image_url : '',
          position: subCat.position,
        }))
      : [],
  };

  return (
    <Formik initialValues={initialValuesModified} enableReinitialize={true}>
      {({
        values,
        handleReset,
        dirty,
        errors,
        setFieldValue,
        handleSave,
        touched,
      }) => (
        <Fragment>
          <Wrapper>
            <FieldArray
              name="categorySubCategories"
              render={(arrayHelpers) => {
                return (
                  <Fragment>
                    <CategoryForm
                      editingCategory={values}
                      arrayHelpers={arrayHelpers}
                      setFieldValue={setFieldValue}
                      scrollToBottom={scrollToBottom}
                      touched={touched}
                      productsAssociatedToCategory={
                        productsAssociatedToCategory
                      }
                      sortCategories={sortCategories}
                      sortProducts={sortProducts}
                      categoryId={initialValues.id}
                      formHeadName={formHeadName}
                      categorydata={initialValues}
                    />
                    <div>
                      {arrayHelpers.form.values.categorySubCategories &&
                        arrayHelpers.form.values.categorySubCategories.map(
                          (subCategory, index) => (
                            <SubCategoryForm
                              key={index}
                              subCategory={subCategory}
                              categoryIndex={index}
                              setFieldValue={arrayHelpers.form.setFieldValue}
                              arrayHelpers={arrayHelpers}
                              touched={touched}
                              editingCategory={values}
                              index={index}
                              sortProducts={sortProducts}
                              subCategoryHoursError={subCategoryHoursError}
                              sortCategories={sortCategories}
                              parentCategory={initialValues}
                              categoryId={initialValues.id}
                              formHeadName={formHeadName}
                              productsAssociatedToCategory={
                                productsAssociatedToCategory
                              }
                              categorydata={initialValues}
                            />
                          )
                        )}
                    </div>
                    <StickyButtons
                      isVisible={dirty}
                      handleDiscard={() => handleReset()}
                      saveIsDisabled={
                        Object.keys(errors)?.length || isRequesting
                      }
                      handleSave={() =>
                        productsAssociatedToCategory &&
                        productsAssociatedToCategory?.length &&
                        values.categorySubCategories &&
                        values.categorySubCategories?.length &&
                        isSubCategories
                          ? setProductCategoryAssignmentModalIsVisible(
                              values,
                              true
                            )
                          : handleCategorySave(values, initialValues)
                      }
                      title="Category Management"
                    />
                    {productCategoryAssignmentModalIsVisible && (
                      <ProductCategoryAssignmentModal
                        productsAssociatedToCategory={
                          productsAssociatedToCategory
                        }
                        categoryValues={values}
                        setProductCategoryAssignmentModalIsVisible={
                          setProductCategoryAssignmentModalIsVisible
                        }
                        categoryInitialValues={initialValues}
                        handleCategorySave={handleCategorySave}
                      />
                    )}
                  </Fragment>
                );
              }}
            />
            <div style={{ float: 'left', clear: 'both' }} ref={wrapperEnd} />
          </Wrapper>
        </Fragment>
      )}
    </Formik>
  );
};

export default CategoryDetail;
