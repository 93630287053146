import React, { Component } from 'react';
import styled from 'styled-components';
import {
  TableWithButtonRow,
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
} from 'merchant-portal-components';
import { ButtonRow, Button } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import Pagination from '../../../components/Pagination/Pagination';
import { Icon } from 'merchant-portal-components';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import copyToClipboard from '../utils/copyToClipboard';
import ConfirmDeleteModal from '../../../components/Modal/ConfirmDeleteModal';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PaginationControl from '../../../components/Pagination/PaginationControl';
import { STRING, sortByTypes } from '../../../utils/sort/sort';
import ParentLocationSelector from '../../../components/LocationSelectors/ParentLocationSelector';
import ChildLocationMultiSelector from '../../../components/LocationSelectors/ChildLocationMultiSelector';
const TopRowTableCard = styled.div`
  padding: 0 28px;
  margin: 0 15px;
`;

const StyledP = styled.p`
  margin: 0;
  font-weight: ${(props) => (props.bold ? 'bold' : 'initial')};
  padding: ${(props) => (props.message ? '15px 15px 15px 15px' : '0')};
  line-height: ${(props) => (props.message ? '30vh' : 'initial')};
  font-size: ${(props) => (props.message ? '38px' : 'initial')};
  text-align: ${(props) => (props.message ? 'center' : 'initial')};
  @media print {
    font-size: 14px;
  }
`;

const StyledSubP = styled.p`
  margin: 10px 30% 0 0;
  font-weight: ${(props) => (props.bold ? 'bold' : 'initial')};
  padding: ${(props) => (props.message ? '15px 15px 15px 15px' : '0')};
  line-height: ${(props) => (props.message ? '30vh' : 'initial')};
  font-size: ${(props) => (props.message ? '38px' : 'initial')};
  text-align: ${(props) => (props.message ? 'center' : 'initial')};
  @media print {
    font-size: 14px;
  }
`;

const TopDiv = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 20px;
  justify-content: space-between;
  margin: 37px;
`;

const TextSearch = styled.div`
  // padding: 20px;
`;

const PaginationWrapper = styled.div`
  // padding-top: 135px;
  // padding-bottom: 33px;
`;

const DisplayMessage = styled.div`
  font-size: 38px;
  color: #d8d8d8;
  height: 30vh;
`;

const ProductImage = styled.img`
  width: 90px;
  object-fit: contain;
  vertical-align: middle;
  margin: 5px;
  background-color: #d3d3d3;
`;

const AnimatedRow = styled.div`
  display: flex;
  /* justify-content: space-around; */
  justify-content: center;
`;

const SelectLocationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 73%;
  margin: 0 37px;
`;

class MediaIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModalOpen: false,
      imageToDelete: null,
      subMenuOpen: null,
      selectedParentLocation: {},
      selectedChildLocations: [],
    };
  }
  componentDidMount() {
    const { parentBusinessLocations } = this.props;
    if (Object.keys(parentBusinessLocations).length === 1) {
      let onlyParentBusiness =
        parentBusinessLocations[Object.keys(parentBusinessLocations)[0]];
      this.setState({ selectedParentLocation: onlyParentBusiness });
      if (onlyParentBusiness.sub_businesses?.length === 1) {
        this.setState({
          selectedChildLocations: onlyParentBusiness.sub_businesses,
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.mediaReducer.requestingDeleteImage &&
      !this.props.mediaReducer.requestingDeleteImage
    ) {
      this.setState({ deleteModalOpen: false, imageToDelete: null });
    }
  }

  getLocationNames = (imageData) => {
    const { parentBusinessLocations, childBusinessLocations } = this.props;
    const childLocationNames = [];
    const parentLocationNames = imageData.businesses.map((businessId, i) => {
      const childBusinessDetails = childBusinessLocations[businessId];
      const parentLocationId = childBusinessDetails?.parentLocationId;
      const parentBusinessDetails = parentBusinessLocations[parentLocationId];
      childBusinessDetails?.parentLocationId;
      childLocationNames.push(
        <div key={businessId}>{childBusinessDetails?.name}</div>
      );
      return <div key={i}>{parentBusinessDetails?.name}</div>;
    });
    return {
      parentLocationNames,
      childLocationNames,
    };
  };

  renderRows = () => {
    const { showEditImageForm, createAlert } = this.props;
    return this.props.imagesActiveList.length > 0 ? (
      this.props.imagesActiveList.map((image) => {
        const locationNames = this.getLocationNames(image);
        return (
          <Tr
            key={image.media_id}
            onClick={() => showEditImageForm(image)}
            style={{ cursor: 'pointer' }}
          >
            <Td style={{ padding: 0 }} align="center" bold>
              <ProductImage src={image.url} alt={image.alt_text} />
            </Td>

            <Td align="left" bold>
              {image.name}
            </Td>
            {/* <Td align="left" bold>
              {'DATE CREATED'}
            </Td> */}
            <Td align="left" bold>
              {locationNames.parentLocationNames}
            </Td>
            <Td align="left" bold>
              {locationNames.childLocationNames}
            </Td>
            <Td
              align="center"
              style={{ pointerEvents: 'none' }}
              onClick={(e) => e.stopPropagation()}
            >
              {image.media_id && (
                <AnimatedRow id={image.media_id}>
                  <Icon
                    style={{ marginRight: '10px', fontSize: '20px' }}
                    className="fal fa-pencil"
                    onClick={() => showEditImageForm(image)}
                  />
                  <Tooltip title="Copy URL">
                    <Icon
                      className="fal fa-copy"
                      style={{
                        marginRight: '10px',
                        fontSize: '20px',
                      }}
                      onClick={() => copyToClipboard(image, createAlert)}
                      value={image.url}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Icon
                      className="fal fa-trash-alt"
                      style={{ fontSize: '20px' }}
                      onClick={() =>
                        this.setState({
                          imageToDelete: image,
                          deleteModalOpen: true,
                        })
                      }
                      value={image.url}
                    />
                  </Tooltip>
                </AnimatedRow>
              )}
            </Td>
          </Tr>
        );
      })
    ) : (
      <Tr>
        <Td colSpan="6" padding="50px 0" align="center">
          {' '}
          No matching data{' '}
        </Td>
      </Tr>
    );
  };

  renderHeaders = (requestParams) => {
    return (
      <TrHead>
        <Th align="left" />
        <Th
          align="left"
          onClick={() => {
            this.props.handleSort({
              value: 'name',
              sortMethod: sortByTypes,
              sortType: STRING,
            });
          }}
          value="name"
          sortingOption={requestParams.sorting_option}
          isSortable={false}
        >
          Image Name
        </Th>
        {/* <Th
          align="left"
          onClick={() => {
            // this.props.handleSort({
            //   value: "name",
            //   sortMethod: sortByTypes,
            //   sortType: STRING,
            // });
          }}
          value="name"
          sortingOption={requestParams.sorting_option}
          isSortable={false}
        >
          Date Created
        </Th> */}
        <Th align="left">Parent Location</Th>
        <Th align="left">Child Location</Th>
        <Th align="center">Actions</Th>
      </TrHead>
    );
  };
  render() {
    const {
      mediaReducer,
      updateRequestParams,
      showCreateImageForm,
      requestParams,
      handleImagesSearch,
      getImages,
      searchString,
      handleClearSearch,
    } = this.props;
    const { images, requestingGetImages, meta } = mediaReducer;
    const { selectedParentLocation } = this.state;
    return (
      <>
        <ButtonRow justifyContent="space-between" style={{ margin: 'auto' }}>
          <BreadCrumb pageName="Media" />
        </ButtonRow>
        <TableWithButtonRow>
          <TableWrapper>
            <TopDiv>
              <TextSearch>
                <TextField
                  type="text"
                  style={{ width: '100%' }}
                  variant="outlined"
                  value={searchString}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className="fal fa-search" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="start">
                        <Icon
                          className="fas fa-times-circle"
                          style={{ color: '#193053' }}
                          onClick={handleClearSearch}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => handleImagesSearch(e.target.value)}
                  placeholder="Search Images"
                />
              </TextSearch>
              <Button
                onClick={showCreateImageForm}
                style={{ fontSize: 'initial' }}
              >
                Upload Image
              </Button>
            </TopDiv>
            <TopRowTableCard>
              <StyledP bold>Filter by</StyledP>
            </TopRowTableCard>
            <SelectLocationWrapper>
              <ParentLocationSelector
                customWidth="390px"
                selectedParentLocation={selectedParentLocation}
                handleParentLocationSelection={(selectedParentLocation) => {
                  this.setState({
                    selectedParentLocation,
                    selectedChildLocations:
                      selectedParentLocation.sub_businesses.length === 1
                        ? selectedParentLocation.sub_businesses
                        : [],
                  });
                  getImages({
                    page_number: 1,
                    page_size: 1000,
                    business_ids: selectedParentLocation.sub_businesses.map(
                      (childBusiness) => childBusiness.id
                    ),
                  });
                }}
              />
              <ChildLocationMultiSelector
                customWidth="390px"
                selectedParentLocation={this.state.selectedParentLocation}
                selectedChildLocations={this.state.selectedChildLocations}
                handleChildLocationSelection={(selectedChildLocations) => {
                  const selectedLocations =
                    selectedChildLocations.length > 0
                      ? selectedChildLocations
                      : selectedParentLocation.sub_businesses;
                  this.setState({ selectedChildLocations });
                  getImages({
                    page_number: 1,
                    page_size: 1000,
                    business_ids: selectedLocations.map(
                      (business) => business.id
                    ),
                  });
                }}
              />
            </SelectLocationWrapper>
            <Table noBorder>
              <thead>{this.renderHeaders(requestParams)}</thead>
              {!requestingGetImages && images && (
                <TBody striped>{this.renderRows()}</TBody>
              )}
            </Table>
            {requestingGetImages ? (
              <LoadingBlueLarge />
            ) : (
              images &&
              !images.length && (
                <DisplayMessage>
                  <StyledP message>No Images Found</StyledP>
                </DisplayMessage>
              )
            )}
            {!requestingGetImages && (
              <PaginationWrapper>
                <PaginationControl
                  response={{
                    ...this.props.imagesActiveList,
                    page: requestParams.page_number,
                    page_size: requestParams.page_size,
                    count: this.props.filterImagesBySearch(images).length,
                  }}
                  requestParams={requestParams}
                  type="media"
                />
              </PaginationWrapper>
            )}
          </TableWrapper>
          {this.state.deleteModalOpen && (
            <ConfirmDeleteModal
              requestingDelete={this.props.mediaReducer.requestingDeleteImage}
              deleteAction={() =>
                this.props.deleteImageRequest(this.state.imageToDelete)
              }
              cancel={() =>
                this.setState({ imageToDelete: null, deleteModalOpen: false })
              }
              deleteSuccess={this.props.mediaReducer.imageDeleteSuccess}
            />
          )}
        </TableWithButtonRow>
      </>
    );
  }
}

export default MediaIndex;
