import React from "react";

const OptionsSummary = ({ productOptionsInitialValues, allOptions }) => {
  let displayOptions = productOptionsInitialValues.map((productOption) => {
    let foundOption = allOptions.find(
      (globalOption) => globalOption.id === productOption.product_option_id
    );
    return foundOption ? foundOption.name : "Option Not Found";
  });

  return <div>{displayOptions.join(", ")}</div>;
};

export default OptionsSummary;
