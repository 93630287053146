import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../productsActions';
import ProductBusinessesOverridesForm from '../components/ProductBusinessesOverridesForm';
import { finalStructureSummaryData } from '../utils/helper';
import { selectFormattedVariants } from '../../../productsSelectors';
import { LoadingBlueLarge } from 'merchant-portal-components';

const ProductBusinessesOverridesContainer = ({
  getProductOverrides,
  getProductAssignedBusinesses,
  productId,
  productVariantsInitialValues,
  productOverrides,
  formattedBusinesses,
  allVariants,
  isSubmiting,
  isRequestingProductVariants,
}) => {
  const [isCreate, setIsCreate] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [summaryData, setSummaryData] = useState(null);

  const [isOverrideModal, setIsOverrideModal] = useState({
    isShowing: false,
    prices: null,
    assignedBusinesses: [],
  });

  const [productVariantsModified, setProductVariantsModified] = useState([]);

  useEffect(() => {
    if (productId) {
      getProductOverrides(productId);
      getProductAssignedBusinesses(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (productOverrides && productId && allVariants) {
      setSummaryData(
        finalStructureSummaryData(
          productOverrides,
          productId,
          productVariantsInitialValues,
          formattedBusinesses,
          allVariants
        )
      );
    }
  }, [productOverrides, productId, allVariants, productVariantsInitialValues]);

  useEffect(() => {
    allVariants.map((variant) => {
      productVariantsInitialValues.map((prVariant) => {
        if (variant.id === prVariant.product_variant_id) {
          productVariantsModified.push({
            ...prVariant,
            name: variant.name,
            label: variant.label,
          });
        }
      });
    });
  }, [productVariantsInitialValues]);

  return (
    <ProductBusinessesOverridesForm
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      disabled={!productId}
      summaryData={summaryData}
      isOverrideModal={isOverrideModal}
      setIsOverrideModal={setIsOverrideModal}
      formattedBusinesses={formattedBusinesses}
      productId={productId}
      productVariantsModified={productVariantsModified}
      productVariantsInitialValues={productVariantsInitialValues}
      isCreate={isCreate}
      setIsCreate={setIsCreate}
      isSubmiting={isSubmiting}
      isRequestingProductVariants={isRequestingProductVariants}
    />
  );
};

const mapStateToProps = (state) => ({
  productId: state.productsReducer.productId,
  productVariantsInitialValues:
    state.productsReducer.productVariantsInitialValues,
  isRequestingProductVariants:
    state.productsReducer.isRequestingProductVariants,
  productOverrides: state.productsReducer.productOverrides,
  formattedBusinesses: state.productsReducer.formattedBusinesses,
  isSubmiting: state.productsReducer.isSubmiting,
  allVariants: selectFormattedVariants(state),
});

export default connect(
  mapStateToProps,
  actions
)(ProductBusinessesOverridesContainer);
