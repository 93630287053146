import React, { useEffect } from 'react';
import ReportTable from '../ReportTable/ReportTable';
import moment from 'moment';
import { BatchDetailPage } from 'merchant-portal-components';

function ComboPerformanceReport(props) {
  useEffect(() => {
    track.page('combo-performance-report');
  }, []);

  const columnNames = [
    'Combo Name',
    'Number of Times Ordered',
    'Combo Sales',
    'Average Price Paid',
  ];

  const initialParams = {
    page_number: 1,
    page_size: 10,
    start_date: `${moment().subtract(1, 'days').format('YYYY-MM-DD')}`,
    end_date: `${moment().format('YYYY-MM-DD')}`,
  };

  const columnSort = [
    'combo_name',
    'num_times_ordered',
    'combo_price',
    'avg_price',
  ];

  return (
    <BatchDetailPage>
      <ReportTable
        url="/report/combo"
        columnNames={columnNames}
        title="Combo Performance Report"
        csvRequestPath="report/combo"
        filters={['date', 'location']}
        initialParams={initialParams}
        emptyMessage={
          'There Are No Combo Performance Reports To Show Between The Currently Selected Dates'
        }
        columnSort={columnSort}
      />
    </BatchDetailPage>
  );
}

export default ComboPerformanceReport;
