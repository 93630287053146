import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from 'merchant-portal-components';
import { Button } from 'merchant-portal-components';
import { LoadingBlueLarge } from 'merchant-portal-components';
import { Select } from 'merchant-portal-components';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const ModalTitle = styled.div`
  display: grid;
  grid-template-columns: 10fr 1fr;
`;

const ButtonsWrapper = styled.div`
  text-align: right;
  padding: 20px 0;
`;

const ModalBody = styled.div``;

const ProductDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const LineDivider = styled.hr`
  margin-top: 11px;
  margin-bottom: 11px;
  border: solid 0.5px #ebebeb;
`;

const ProductImg = styled.img`
  width: 35px;
  height: 35px;
  background-color: #d3d3d3;
`;

const CategorySelect = styled.div`
  margin-bottom: 20px;
  width: 250px;
`;

const ProductImageRow = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr;
  margin-bottom: 10px;
  margin-top: 30px;
`;

const TextSearch = styled.div`
  padding: 10px 0;
`;

const RemoveCatFilter = styled.button`
  font-style: italic;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  display: block;
`;

const AddProductsModal = ({
  productsModalState,
  getProductsCategories,
  productsCategories,
  getProductsVariants,
  isRequestingGetProductsVariants,
  productsVariants,
  toggleProductModal,
  comboItem,
  getAllProducts,
  allProducts,
  index,
  productVariantIndex,
  setFieldValue,
  arrayHelpers,
  isRequestingGetAllProducts,
}) => {
  const [isCategorySelected, setIsCategorySelected] = useState(false);

  let productsVariantsModified = [];

  const [filterState, setFilterState] = useState({
    search: '',
    filteredProducts: isCategorySelected ? productsVariants : allProducts,
  });

  const initialFormState = { mySelectKey: null };
  const [myForm, setMyForm] = useState(initialFormState);

  useEffect(() => {
    getProductsCategories();
    getAllProducts();
  }, []);

  useEffect(() => {
    setFilterState({
      search: filterState.search,
      filteredProducts: isCategorySelected ? productsVariants : allProducts,
    });
  }, [isRequestingGetAllProducts, productsVariants]);

  const handleSearch = (e) => {
    setFilterState({
      search: e.target.value,
      filteredProducts: isCategorySelected
        ? productsVariants.filter((product) =>
            product.productName
              .toUpperCase()
              .includes(e.target.value.toUpperCase())
          )
        : allProducts.filter((product) =>
            product.productName
              .toUpperCase()
              .includes(e.target.value.toUpperCase())
          ),
    });
  };

  const [selectedProductVariants, setSelectedProductVariants] = useState({});

  useEffect(() => {
    const existingProducts = comboItem.productVariants;
    let newSelectedProductVariants = {};
    existingProducts.forEach((item) => {
      if (newSelectedProductVariants[item.productId])
        newSelectedProductVariants[item.productId].push(item.variantId);
      else newSelectedProductVariants[item.productId] = [item.variantId];
    });
    setSelectedProductVariants(newSelectedProductVariants);
  }, [productsVariants]);
  //existingProducts

  const getFinalState = (selectedVariants) => {
    const allVariants = allProducts;
    const finalState = [];

    Object.keys(selectedVariants).forEach((productId) => {
      selectedVariants[productId].forEach((variantId) => {
        allVariants.forEach((product) => {
          product.productVariants.map((variant) => {
            if (
              product.productId == productId &&
              variant.variantId == variantId
            ) {
              const newObj = {
                productId: product.productId,
                productIsActive: product.productIsActive,
                productName: product.productName,
                productImage: product.productImageUrl,
                productExtraPrice: null,
                variantId: variant.variantId,
                variantName: variant.variantName,
                variantPrice: variant.variantPrice,
              };

              arrayHelpers.form.values.comboItems[
                index
              ].productVariants.forEach((item) => {
                if (
                  item.productId === newObj.productId &&
                  item.variantId === newObj.variantId
                ) {
                  newObj.productExtraPrice = item.productExtraPrice;
                }
              });
              finalState.push(newObj);
            }
          });
        });
      });
    });
    return finalState;
  };

  //dropdown categories name
  const renderCategories = () => {
    return productsCategories
      .map((category) => {
        if (category.sub_categories.length != 0) {
          return category.sub_categories.map((subcategory) => ({
            name: category.name + ' - ' + subcategory.name,
            id: subcategory.id,
          }));
        } else {
          return {
            name: category.name,
            id: category.id,
          };
        }
      })
      .flat();
  };

  const categoriesList = renderCategories();

  const onToggleSelect = (productId, variantId) => {
    let newState;

    if (
      selectedProductVariants[productId] &&
      selectedProductVariants[productId].includes(variantId)
    ) {
      const newArray = selectedProductVariants[productId].filter(
        (vId) => vId !== variantId
      );
      // if (!newArray.length) {
      //   const newObj = {};

      //   Object.keys(key => {
      //     if (key !== productId)
      //       newObj[productId] = selectedProductVariants[productId];
      //   });

      //   setSelectedProductVariants(newObj);
      // } else {
      newState = {
        ...selectedProductVariants,
        [productId]: newArray,
      };
      // }
    } else if (selectedProductVariants[productId]) {
      newState = {
        ...selectedProductVariants,
        [productId]: [...selectedProductVariants[productId], variantId],
      };
    } else {
      newState = {
        ...selectedProductVariants,
        [productId]: [variantId],
      };
    }
    setSelectedProductVariants(newState);
    setFieldValue(
      `comboItems[${index}].productVariants`,
      getFinalState(newState)
    );
  };

  return (
    <Modal maxWidth="sm" fullWidth>
      <DialogTitle>
        <ModalTitle>
          <div>{productsModalState.itemLabel}</div>
          <div
            onClick={() => toggleProductModal()}
            style={{ cursor: 'pointer', justifySelf: 'right' }}
          >
            <i className="fas fa-times" />
          </div>
        </ModalTitle>
        <LineDivider />
        <TextSearch>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className="fal fa-search" />
                </InputAdornment>
              ),
            }}
            style={{ width: '100%' }}
            variant="outlined"
            type="text"
            onChange={(e) => handleSearch(e)}
            placeholder="Search by Product Name"
            value={filterState.search}
          />
        </TextSearch>
      </DialogTitle>
      <DialogContent>
        <ModalBody>
          <CategorySelect>
            <Select
              options={renderCategories()}
              // value={categoriesList.filter(
              //   value => value === myForm.mySelectKey
              // )}
              value={myForm.mySelectKey}
              getOptionLabel={(category) => category.name}
              getOptionValue={(category) => category}
              onChange={(category) => {
                setIsCategorySelected(true);
                getProductsVariants(category);
                setMyForm({ ...myForm, mySelectKey: category });
              }}
              valueKey="id"
              placeholder="Filter By Category"
              required
            />
          </CategorySelect>
          <RemoveCatFilter
            onClick={(e) => {
              setIsCategorySelected(false);
              setFilterState({
                search: e.target.value,
                filteredProducts: allProducts.filter((product) =>
                  product.productName.includes(e.target.value)
                ),
              });
              setMyForm(initialFormState);
            }}
          >
            Remove Category filter
          </RemoveCatFilter>
          {!isCategorySelected &&
          allProducts &&
          filterState.filteredProducts ? (
            filterState.filteredProducts.map((productVariants, index) => {
              {
                /* {!isCategorySelected && allProducts ? ( */
              }
              //allProducts.map((productVariants, index) => {
              return (
                <ProductDiv key={index}>
                  <ProductImageRow>
                    <ProductImg src={productVariants.productImageUrl} />
                    <div
                      style={
                        !productVariants.productIsActive
                          ? {
                              color: '#979797',
                              display: 'inline-flex',
                            }
                          : { color: 'inherit' }
                      }
                    >
                      {productVariants.productName}
                      {!productVariants.productIsActive && (
                        <Tooltip title="The product is inactive">
                          <div>
                            <Icon
                              className="fal fa-info-circle"
                              style={{
                                cursor: 'inherit',
                                fontSize: '16px',
                                marginLeft: '10px',
                                marginTop: '5px',
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </ProductImageRow>

                  {productVariants.productVariants.map((productVariant, i) => {
                    return (
                      <div
                        style={{ marginLeft: '30px' }}
                        key={productVariant.variantId}
                      >
                        <p>
                          <Checkbox
                            // name={`comboItems[${index}].productVariants[${productVariantIndex}].variantPrice`}
                            checked={
                              !!(
                                selectedProductVariants[
                                  productVariants.productId
                                ] &&
                                selectedProductVariants[
                                  productVariants.productId
                                ].includes(productVariant.variantId)
                              )
                            }
                            onChange={
                              () =>
                                onToggleSelect(
                                  productVariants.productId,
                                  productVariant.variantId
                                )
                              // setFieldValue(
                              //   `comboItems[${index}].productVariants`,
                              //   {
                              //     productId: productVariants.id,
                              //     variantId: productVariant.variantId
                              //   }
                              // )
                            }
                            color="primary"
                          />

                          {productVariant.variantName}
                        </p>
                        <LineDivider />
                      </div>
                    );
                  })}
                </ProductDiv>
              );
            })
          ) : isRequestingGetProductsVariants ? (
            <LoadingBlueLarge />
          ) : !productsVariants.length ? (
            <span style={{ fontStyle: 'italic' }}>
              There are no products in selected category
            </span>
          ) : (
            (filterState.search === ''
              ? (productsVariantsModified = productsVariants)
              : (productsVariantsModified = filterState.filteredProducts),
            productsVariantsModified.map((productVariants, index) => {
              // productsVariants.map((productVariants, index) => {
              return (
                <ProductDiv key={index}>
                  <ProductImageRow>
                    <ProductImg src={productVariants.productImageUrl} />
                    <div
                      style={
                        !productVariants.productIsActive
                          ? {
                              color: '#979797',
                              display: 'inline-flex',
                            }
                          : { color: 'inherit' }
                      }
                    >
                      {productVariants.productName}
                      {!productVariants.productIsActive && (
                        <Tooltip title="The product is inactive">
                          <div>
                            <Icon
                              className="fal fa-info-circle"
                              style={{
                                cursor: 'inherit',
                                fontSize: '16px',
                                marginLeft: '10px',
                                marginTop: '5px',
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </ProductImageRow>

                  {productVariants.productVariants.map((productVariant, i) => {
                    return (
                      <div
                        style={{ marginLeft: '30px' }}
                        key={productVariant.variantId}
                      >
                        <p>
                          <Checkbox
                            // name={`comboItems[${index}].productVariants[${productVariantIndex}].variantPrice`}
                            checked={
                              !!(
                                selectedProductVariants[
                                  productVariants.productId
                                ] &&
                                selectedProductVariants[
                                  productVariants.productId
                                ].includes(productVariant.variantId)
                              )
                            }
                            onChange={
                              () =>
                                onToggleSelect(
                                  productVariants.productId,
                                  productVariant.variantId
                                )
                              // setFieldValue(
                              //   `comboItems[${index}].productVariants`,
                              //   {
                              //     productId: productVariants.id,
                              //     variantId: productVariant.variantId
                              //   }
                              // )
                            }
                            color="primary"
                          />
                          {productVariant.variantName}
                        </p>
                        <LineDivider />
                      </div>
                    );
                  })}
                </ProductDiv>
              );
            }))
          )}
        </ModalBody>
      </DialogContent>
      <DialogActions>
        <ButtonsWrapper>
          {/* <Button secondary onClick={() => toggleProductModal()}>
            Cancel
          </Button> */}
          <Button
            onClick={() => {
              toggleProductModal();
              setFieldValue(
                `comboItems[${index}].productVariants`,
                getFinalState(selectedProductVariants)
              );
            }}
          >
            Close
          </Button>
        </ButtonsWrapper>
      </DialogActions>
    </Modal>
  );
};

export default AddProductsModal;
