import React from 'react';
import styled from 'styled-components';

import { Tr, Td, StickyTd } from 'merchant-portal-components';
import Checkbox from '@material-ui/core/Checkbox';
import { inferTypeAndTransform } from '../ReportTableActions';

const TdNest = styled.td`
  border-left: 1px solid #ccc;
  width: 50%;
  padding: 10px 6px;
`;

const TableNest = styled.table`
  width: 100%;
`;

function ReportTableBody(props) {
  const {
    rows,
    checkBox,
    ignoreFields,
    nestedTable,
    nestedFields,
    boldFields,
    columnSort,
  } = props;

  // if (rows && rows.length > 0) {
  //   console.log(Object.keys(rows[0]));
  // }

  // console.log('AA ', columnSort);

  return rows.length > 0
    ? rows.map((row, index) => {
        return (
          <Tr key={index}>
            {checkBox ? (
              <Td align="left" key={`${row}`}>
                <Checkbox color="primary" />
              </Td>
            ) : null}
            {columnSort.map((key, index) =>
              index === 0 ? (
                !ignoreFields.includes(key) ? (
                  <StickyTd
                    key={`${row}-${index}`}
                    align="left"
                    style={{ minWidth: '138px', borderLeft: 'none' }}
                  >
                    {inferTypeAndTransform(key, row[key])}
                  </StickyTd>
                ) : null
              ) : !ignoreFields.includes(key) ? (
                nestedTable ? (
                  nestedFields.filter((field) => field.leftField === key)
                    .length > 0 ? (
                    <Td
                      align="center"
                      style={{ padding: '0px' }}
                      bold={boldFields.includes(key)}
                    >
                      <TableNest>
                        <tbody>
                          <tr>
                            <TdNest>
                              {inferTypeAndTransform(
                                nestedFields.filter(
                                  (field) => field.leftField === key
                                )[0].leftField,
                                row[
                                  nestedFields.filter(
                                    (field) => field.leftField === key
                                  )[0].leftField
                                ]
                              )}
                            </TdNest>
                            <td>
                              {inferTypeAndTransform(
                                nestedFields.filter(
                                  (field) => field.leftField === key
                                )[0].rightField,
                                row[
                                  nestedFields.filter(
                                    (field) => field.leftField === key
                                  )[0].rightField
                                ]
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </TableNest>
                    </Td>
                  ) : nestedFields.filter((field) => field.rightField === key)
                      .length > 0 ? (
                    <Td
                      align="center"
                      style={{ padding: '0px' }}
                      bold={boldFields.includes(key)}
                    >
                      <TableNest>
                        <tbody>
                          <tr>
                            <TdNest>
                              {inferTypeAndTransform(
                                nestedFields.filter(
                                  (field) => field.rightField === key
                                )[0].leftField,
                                row[
                                  nestedFields.filter(
                                    (field) => field.rightField === key
                                  )[0].leftField
                                ]
                              )}
                            </TdNest>
                            <td>
                              {inferTypeAndTransform(
                                nestedFields.filter(
                                  (field) => field.rightField === key
                                )[0].rightField,
                                row[
                                  nestedFields.filter(
                                    (field) => field.rightField === key
                                  )[0].rightField
                                ]
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </TableNest>
                    </Td>
                  ) : (
                    <Td
                      key={`${index}-${key}`}
                      align="center"
                      bold={boldFields.includes(key)}
                    >
                      {inferTypeAndTransform(key, row[key])}
                    </Td>
                  )
                ) : (
                  <Td
                    key={`${index}-${key}`}
                    align="center"
                    bold={boldFields.includes(key)}
                  >
                    {inferTypeAndTransform(key, row[key])}
                  </Td>
                )
              ) : null
            )}
          </Tr>
        );
      })
    : null;
}

export default ReportTableBody;
