import React from 'react';
import { Button } from 'merchant-portal-components';
import styled from 'styled-components';
import {
  ContainerDiv,
  StyledP,
  GridWrapper,
} from '../utils/commonStyledComponents';
import Tooltip from '@material-ui/core/Tooltip';
import { TooltipIcon } from '../../../../components/Icon';

const StackedButtonRow = styled(ContainerDiv)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 8px;
  margin: ${(props) =>
    props.right ? '0px 20px 0px 180px' : '0px 40px 0px 60px'};
  width: 25%;
`;

export const MerchantActions = (props) => {
  const {
    history,
    handleVisibilityCancelOrderModal,
    handleVisibilitySendLoyaltyModal,
    refundedIsHidden,
    refundIsDisabled,
  } = props;
  return (
    <ContainerDiv
      data-test="component-MerchantActions"
      padding="15px 0px 0px 0px"
    >
      <ContainerDiv margin="30px 72px 0 72px">
        <StyledP>
          If you want to reward this customer due to a bad experience, click on
          Send Rewards to credit their loyalty account. If you want to delete
          this transaction due to a refund or error, click Refund Order
        </StyledP>
      </ContainerDiv>
      <GridWrapper
        templateColumns="2fr 1fr 1fr"
        margin="50px 40px 10px 10px"
        gap="20px"
      >
        <div>
          <Button
            data-test="button-back"
            secondary
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
        <div>
          {!refundedIsHidden && (
            <Tooltip title={refundIsDisabled.reason || ''}>
              <div>
                <Button
                  data-test="button-refundOrder"
                  onClick={() => {
                    refundIsDisabled.disabled
                      ? null
                      : handleVisibilityCancelOrderModal();
                  }}
                  softDisable={refundIsDisabled.disabled}
                >
                  Refund Order
                </Button>
              </div>
            </Tooltip>
          )}
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            data-test="button-sendRewards"
            onClick={() => handleVisibilitySendLoyaltyModal()}
          >
            Send Rewards
          </Button>
        </div>
      </GridWrapper>
    </ContainerDiv>
  );
};

export default MerchantActions;
