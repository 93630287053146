import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';
import moment from 'moment';
import {
  TableWrapper,
  Table,
  Tr,
  TrHead,
  Th,
  TBody,
  Td,
  TdFlex,
  SortIcon,
  EmptyStateMessage,
} from 'merchant-portal-components';
import { Button, ButtonRow, LoadingButton } from 'merchant-portal-components';
import Pagination from '../../../components/Pagination/Pagination';
import Moment from 'moment';
import { LoadingBlue, LoadingBlueLarge } from 'merchant-portal-components';
import { Icon } from 'merchant-portal-components';
import Tooltip from '@material-ui/core/Tooltip';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import toPriceFormat from '../../../utils/toPriceFormat';
import Checkbox from '@material-ui/core/Checkbox';

const CheckboxWrapper = styled.div`
  & > * {
    z-index: 0;
  }
`;

const DateRangeContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

const DateRangePickerWrapper = styled.div`
  .PresetDateRangePicker_button {
    border: 2px solid #296bde;
    color: #296bde;
  }
  .PresetDateRangePicker_button__selected {
    background: #296bde;
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid #296bde;
  }
  .daypickerkeyboardshortcuts_show__bottomright:hover {
    border-right: 33px solid #296bde;
  }
  .CalendarDay__selected_span {
    background: #628fdc;
    border: 1px double #4c7ed7;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: #4c7ed7;
    border: 1px double #4c7ed7;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #296bde;
    border: 1px double #296bde;
    color: ${(props) => props.theme.white};
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #799fe1;
    border: 1px double #628fdc;
    color: ${(props) => props.theme.white};
  }
  .CalendarDay__hovered_span:active {
    background: #296bde;
    border: 1px double #296bde;
    color: ${(props) => props.theme.white};
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #cacccd;
    border: 1px solid #cacccd;
    color: #82888a;
  }
`;

const handleRowClick = (settlement, props, event) => {
  if (event.target.type !== 'checkbox') {
    props.history.push(`/settlements/${settlement}`);
  }
};

const SettlementsIndex = (props) => {
  const { response, isFetchingSettlements } = props.settlements;
  const {
    requestParams,
    requestingCSV,
    typeOfReportChecked,
    filteredSettlements,
    setFilterSettlements,
    clearSelectedDates,

    startDate,
    endDate,
    focusedInput,
    leftInvalid,
    rightInvalid,

    setStartDate,
    setEndDate,
    setFocusedInput,
    setLeftInvalid,
    setRightInvalid,
  } = props;

  const renderRows = () => {
    return filteredSettlements.map((settlement, index) => {
      return (
        <Tr
          onClick={(event) => handleRowClick(settlement.batch_id, props, event)}
          style={{ cursor: 'pointer' }}
          key={index}
        >
          <Td align="left">
            <CheckboxWrapper>
              <Checkbox
                color="primary"
                checked={props.checkedIds.includes(settlement.batch_id)}
                onChange={() => props.handleToggleCheckbox(settlement)}
                disabled={
                  typeOfReportChecked
                    ? settlement.settlement_report_version !==
                      typeOfReportChecked
                    : false
                }
                inputProps={{ style: { zIndex: 'unset' } }}
              />
            </CheckboxWrapper>
          </Td>
          <Td align="left">{settlement.batch_id}</Td>
          <Td align="left">{settlement.business_name}</Td>
          <Td align="left">
            {Moment(settlement.period_begin).format('MMM Do YYYY')}
            &nbsp;-&nbsp;
            <div>{Moment(settlement.period_end).format('MMM Do YYYY')}</div>
          </Td>
          <Td align="left">{toPriceFormat(settlement.batch_total)}</Td>
          <Td align="left">
            <a
              href={`/settlements/${settlement.batch_id}`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              <Icon className="fas fa-external-link-alt" />
            </a>
          </Td>
        </Tr>
      );
    });
  };

  const renderGenerateReportButton = () => {
    if (props.noCheckboxesAreSelected) {
      return (
        <Tooltip className="disabled-button" title="Select a Location">
          <div>
            <Button disabled={true}>Generate Report</Button>
          </div>
        </Tooltip>
      );
    }
    return (
      <Link to={`/settlements/${props.checkedIds}`}>
        <Button onClick={() => props.viewBatchDetail(props.checkedIds)}>
          Generate Report
        </Button>
      </Link>
    );
  };

  const renderSortIcon = (option) => {
    const sortingOptionAsArray = requestParams.sorting_option.split('-');
    const iconNames = { desc: 'down', asc: 'up' };
    if (sortingOptionAsArray[0] === option) {
      return (
        <Tooltip className={option} title="Sort">
          <SortIcon
            className={`fa fa-sort-${iconNames[sortingOptionAsArray[1]]}`}
            aria-hidden="true"
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip className={option} title="Sort">
        <SortIcon className="fa fa-sort" aria-hidden="true" />
      </Tooltip>
    );
  };

  const renderHeaders = () => {
    return (
      <TrHead style={{ background: 'none' }}>
        <Th align="left">
          <Tooltip title="Select All">
            <CheckboxWrapper>
              <Checkbox
                color="primary"
                checked={props.allCheckboxesAreSelected}
                onChange={() => props.toggleAllCheckboxes()}
              />
            </CheckboxWrapper>
          </Tooltip>
        </Th>
        <Th
          align="left"
          onClick={() => props.sortBy('batch_id')}
          style={{ minWidth: '140px' }}
        >
          Batch ID
          {renderSortIcon('batch_id')}
        </Th>
        <Th align="left">Business Name</Th>
        <Th align="left" onClick={() => props.sortBy('period_begin')}>
          Transaction Period
          {renderSortIcon('period_begin')}
        </Th>
        <Th align="left" onClick={() => props.sortBy('batch_total')}>
          Batch Total($)
          {renderSortIcon('batch_total')}
        </Th>
        <Th />
      </TrHead>
    );
  };

  // useEffect(() => {
  //   startDate && endDate && filterByDate(startDate, endDate);
  // }, [startDate, endDate]);

  // const filterByDate = (startDate, endDate) => {
  //   setFilterSettlements(
  //     props.settlements.response.data.filter(settlement => {
  //       return (
  //         moment(settlement.period_begin, 'YYYY-MM-DD').isSameOrAfter(
  //           startDate,
  //           'day'
  //         ) &&
  //         moment(settlement.period_end, 'YYYY-MM-DD').isSameOrBefore(
  //           endDate,
  //           'day'
  //         )
  //       );
  //     })
  //   );
  // };

  return (
    <div style={{ display: 'grid', gridGap: '16px' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '3fr 1fr' }}>
        <BreadCrumb pageName="Settlements" />
        <ButtonRow justifyContent="flex-end">
          {requestingCSV ? (
            <LoadingButton disabled tertiary marginRight>
              Export
            </LoadingButton>
          ) : (
            <Tooltip title="Download as CSV">
              <Button
                tertiary
                marginRight
                onClick={() =>
                  props.exportCSV({
                    requestPath: 'settlements',
                    fileName: 'settlements',
                    requestParams,
                  })
                }
              >
                Export
              </Button>
            </Tooltip>
          )}
          {renderGenerateReportButton()}
        </ButtonRow>
      </div>
      <DateRangeContainer>
        <div>
          <div>Filter By Date</div>
          <DateRangePickerWrapper>
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              minimumNights={0}
              startDate={startDate}
              endDate={endDate}
              hideKeyboardShortcutsPanel={true}
              onDatesChange={({ startDate, endDate }) => {
                if (startDate) {
                  setStartDate(startDate);
                }
                if (endDate) {
                  setEndDate(endDate);
                }
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => {
                if (focusedInput == null) {
                  setLeftInvalid(null);
                  setRightInvalid(null);
                }
                if (focusedInput == 'startDate') {
                  setLeftInvalid(null);
                  setRightInvalid(null);
                  setEndDate(null);
                } else if (focusedInput == 'endDate') {
                  setEndDate(null);
                }
                setFocusedInput(focusedInput);
              }}
              isOutsideRange={() => false}
            />
          </DateRangePickerWrapper>
        </div>
        <div style={{ marginTop: '23px' }}>
          <Button onClick={clearSelectedDates}>Clear</Button>
        </div>
      </DateRangeContainer>
      <TableWrapper style={{ paddingTop: '0' }}>
        <Table noBorder>
          <thead>{renderHeaders()}</thead>
          <TBody striped>
            {!isFetchingSettlements && filteredSettlements && renderRows()}
          </TBody>
        </Table>
        {isFetchingSettlements && <LoadingBlueLarge height="100vh" />}

        {response && response.meta.count > 0 && (
          <Pagination
            enableCustomResultSelection={true}
            response={response.meta}
            requestParams={requestParams}
            updateRequestParams={props.updateRequestParams}
          />
        )}
      </TableWrapper>

      {!isFetchingSettlements &&
      ((response && response.meta.count == 0) ||
        (filteredSettlements && !filteredSettlements.length)) ? (
        <EmptyStateMessage>
          There are no settlements to display.
        </EmptyStateMessage>
      ) : null}
    </div>
  );
};

export default SettlementsIndex;
