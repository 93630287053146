import React, { Fragment } from 'react';
import styled from 'styled-components';
import FeedbackHistoryTable from './FeedbackHistoryTable';
import Pagination from '../../../../components/Pagination/Pagination';
import { Button } from 'merchant-portal-components';
import BreadCrumb from '../../../../components/BreadCrumb/BreadCrumb';
import { ComponentCard } from 'merchant-portal-components';

const TitleContainer = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 4fr 1fr;
`;

const StyledCard = styled(ComponentCard)`
  padding: 0 0 8px 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

const pageName = 'Customer Feedback';

export const FeedbackHistory = (props) => {
  const {
    allBusinesses,
    orders,
    requestParams,
    updateRequestParams,
    getCustomerReviewsIsRequesting,
    handleActionButtonClick,
    meta,
    handleExportClick,
    requestingCSV,
    reviews,
  } = props;

  return (
    <Fragment>
      <TitleContainer>
        <BreadCrumb data-test="component-BreadCrumb" pageName={pageName} />
        {/* <ExportWrapper>
          <Button
            secondary
            onClick={handleExportClick}
            disabled={requestingCSV || getCustomerReviewsIsRequesting}
          >
            {requestingCSV ? 'Export...' : 'Export'}
          </Button>
        </ExportWrapper> */}
      </TitleContainer>

      <StyledCard data-test="component-FeedbackHistory">
        <FeedbackHistoryTable
          data-test="component-FeedbackHistoryTable"
          allBusinesses={allBusinesses}
          reviews={reviews}
          orders={orders}
          getCustomerReviewsIsRequesting={getCustomerReviewsIsRequesting}
          handleActionButtonClick={handleActionButtonClick}
        />
        <div style={{ marginTop: '15px', marginBottom: '-10px' }}>
          <Pagination
            data-test="component-Pagination"
            response={{
              page_number: meta.page_number,
              page_size: meta.page_size,
              count: meta.count,
            }}
            requestParams={requestParams}
            updateRequestParams={updateRequestParams}
          />
        </div>
      </StyledCard>
    </Fragment>
  );
};

export default FeedbackHistory;
